import type * as FieldCodes from './fieldCodes'
export * as FieldCodes from './fieldCodes'

export interface TransmissionSendHeader {
  bin: string
  version: string
  transactionCode: string
  processorControlNumber: string
  transactionCount: number
  serviceProviderIdQualifier: string
  serviceProviderId: string
  dateOfService: Date
  softwareVendorId: string
}

export interface TransmissionReceiveHeader {
  version: string
  transactionCode: string
  transactionCount: number
  serviceProviderIdQualifier: string
  serviceProviderId: string
  dateOfService: Date
  responseStatus?: string
}

export interface ClaimPricingOtherAmountsClaim {
  otherAmountClaimSubmitted?: number
  otherAmountClaimSubmittedQualifier?: string
  otherAmountClaimSubmittedQualifierTranslated?: string
}

export interface ClaimPricing {
  ingredientCostSubmitted?: number
  dispensingFeeSubmitted?: number
  patientPayAmountSubmitted?: number
  usualAndCustomaryCharge?: number
  grossAmountDue?: number
  basisOfCostDetermination?: string
  flatSalesTaxSubmitted?: number
  percentageSalesTaxAmountSubmitted?: number
  percentageSalesTaxRateSubmitted?: number
  percentageSalesTaxBasisSubmitted?: number
  incentiveAmountSubmitted?: number
  otherAmountClaims?: ClaimPricingOtherAmountsClaim[]
}

export interface OtherPayer {
  otherPayerCoverageType: FieldCodes.OtherPayer.CoverageType
  otherPayerDate: Date
}

export interface ClaimEdiSent {
  edi?: string
  header?: TransmissionSendHeader
  metadata?: Partial<{
    insurance: Partial<{
      cardholderId: string
      eligibilityClarificationCode: string
      groupId: string
      personCode: string
      patientRelationshipCode: string
    }>
    patient: Partial<{
      dateOfBirth: Date | string
      genderCode: string
      firstName: string
      lastName: string
      streetAddress: string
      city: string
      state: string
      zip: string
      placeOfService: string
      pregnancyIndicator: string
      residence: string
    }>
  }>
  groups?: Partial<
    {
      // claim: any
      claim: Partial<{
        prescriptionRefNumberQualifier: string
        prescriptionRefNumber: string
        productIdQualifier: string
        productId: string
        quantityDispensed: number
        fillNumber: number
        daysSupply: number
        compoundCode: number
        dawCode: number
        datePrescriptionWritten: Date
        numberOfRefillsAuthorized: number
        prescriptionOriginCode: string
        otherCoverageCode: FieldCodes.OtherCoverage
        levelOfService: FieldCodes.LevelOfService
        intermediaryAuthorizationTypeId: FieldCodes.IntermediaryAuthorization
        scheduledPrescriptionIdNumber?: string
        specialPackagingIndicator: number
        pharmacyServiceType: string
        priorAuthorizationNumberSubmitted: string
      }>
      pricing: ClaimPricing
      prescriber: Partial<{
        prescriberIdQualifier: string
        prescriberId: string
        lastName: string
        state: string
      }>
      coordinationOfBenefits?: {
        otherPayers: Partial<OtherPayer>[]
      }
    }[]
  >
}

export interface ClaimEdiReceivedRejectCode {
  rejectCode: string
  rejectCodeTranslated?: string
}

export interface ClaimPricingResponseOtherAmountsPaid {
  otherAmountPaid?: number
  otherAmountPaidQualifier?: string
}

export interface ClaimPricingResponse {
  accumulatedDeductibleAmount: number
  amountAppliedToPeriodicDeductible?: number
  amountAttributedToCoverageGap?: number
  amountAttributedToProcessorFee?: number
  amountAttributedToProductSelectionBrandDrug?: number
  amountAttributedToProductSelectionBrandNonPreferredForumarlySelection?: number
  amountAttributedToProductSelectionNonPreferredFormularySelection?: number
  amountAttributedToProviderNetworkSelection?: number
  amountAttributedToSalesTax?: number
  amountExceedingPeriodicBenefitMaximum?: number
  amountOfCoinsurance?: number
  amountOfCopay?: number
  basisOfReimbursementDetermination: string
  dispensingFeePaid: number
  flatSalesTaxAmountPaid?: number
  healthPlanFundedAssistanceAmount?: number
  incentiveAmountPaid?: number
  ingredientCostPaid: number
  otherAmountsPaid?: ClaimPricingResponseOtherAmountsPaid[]
  patientPayAmount?: number
  percentageSalesTaxAmountPaid?: number
  planSalesTaxAmount?: number
  totalAmountPaid: number
}

export interface ClaimEdiReceivedCoordinationOfBenefitsResponse {
  otherPayerIdCount?: number
  otherPayerCoverageType?: string
  otherPayerCoverageTypeTranslated?: string
  otherPayerIdQualifier?: string
  otherPayerIdQualifierTranslated?: string
  otherPayerId: string
  otherPayerProcessorControlNumber?: string
  otherPayerCardholderId: string
  otherPayerGroupId?: string
  otherPayerPersonCode?: string
  otherPayerHelpDeskPhoneNumber?: string
  otherPayerPatientRelationshipCode?: string
  otherPayerBenefitEffectiveDate?: string
}

export interface ClaimEdiRecieved {
  edi?: string
  header?: TransmissionReceiveHeader
  metadata?: Partial<{
    responseMessage: Partial<{
      message: string
    }>
    patientResponse: Partial<{
      dateOfBirth: Date | string
    }>
    insuranceResponse: Partial<{
      groupId: string
      networkReimburstmentId: string
    }>
  }>
  groups?: Partial<
    {
      response: {
        transactionStatus: string
        transactionStatusTranslated: string
        authorizationNumber: string
        rejectCodes: ClaimEdiReceivedRejectCode[]
        additionalMessageInformation?: string
        nextAvailableFillDate?: string
        url?: string
        approvedMessageCodes?: {
          approvedMessageCode: string
        }[]
      }
      claimResponse?: {
        prescriptionRefNumberQualifier: string
        prescriptionRefNumber: string
      }
      pricingResponse?: ClaimPricingResponse
      responseStatus?: {
        transactionStatus: string
        authorizationNumber: string
        statusText: string
      }
      coordinationOfBenefitsResponse?: ClaimEdiReceivedCoordinationOfBenefitsResponse
    }[]
  >
}

export interface EligibilityCheckCommercialEdiSent {
  edi?: string
  header?: TransmissionSendHeader
  metadata?: {
    patient: EligibilityCheckCommercialSentPatient
  }
}

export interface EligibilityCheckGovernmentEdiSent {
  edi?: string
  header?: TransmissionSendHeader
  metadata?: {
    insurance: EligibilityCheckSentInsurance
    patient: EligibilityCheckBaseSentPatient
  }
}

export interface EligibilityCheckBaseEdiReceived {
  edi?: string
  header?: TransmissionReceiveHeader
  metadata?: EligibilityCheckMetadata
}

export interface EligibilityCheckCommercialEdiReceived extends EligibilityCheckBaseEdiReceived {
  groups?: EligibilityCheckResponse[]
}

export interface EligibilityCheckGovernmentEdiReceived extends EligibilityCheckBaseEdiReceived {
  first?: EligibilityCheckResponse
  second?: EligibilityCheckResponse
  third?: EligibilityCheckResponse
  fourth?: EligibilityCheckResponse
}

export interface EligibilityCheckMetadataResponseMessage {
  message?: string
}

export interface EligibilityCheckMetadataPatientResponse {
  firstName?: string
  lastName?: string
  dateOfBirth?: Date | string
}

export interface EligibilityCheckMetadata {
  responseMessage: EligibilityCheckMetadataResponseMessage
  patientResponse: EligibilityCheckMetadataPatientResponse
}

export interface EligibilityCheckTransactionResponse {
  transactionStatus: string
  transactionStatusTranslated: string
  rejectCodes: ClaimEdiReceivedRejectCode[]
}

export interface EligibilityCheckResponse {
  response: EligibilityCheckTransactionResponse
  coordinationOfBenefitsResponse?: EligibilityCheckCoordinationOfBenefitsResponse
}

export interface EligibilityCheckCoordinationOfBenefitsResponse {
  otherPayerIdCount?: number
  otherPayerCoverageType?: string
  otherPayerCoverageTypeTranslated?: string
  otherPayerIdQualifier?: string
  otherPayerIdQualifierTranslated?: string
  otherPayerId: string
  otherPayerProcessorControlNumber?: string
  otherPayerCardholderId: string
  otherPayerGroupId?: string
  otherPayerPersonCode?: string
  otherPayerHelpDeskPhoneNumber?: string
  otherPayerPatientRelationshipCode?: string
  otherPayerBenefitEffectiveDate?: string
}

export interface EligibilityCheckBaseSentPatient {
  dateOfBirth: Date | string
  genderCode: string
  firstName: string
  lastName: string
  zip: string
}

export interface EligibilityCheckCommercialSentPatient extends EligibilityCheckBaseSentPatient {
  city: string
  state: string
  placeOfService: string
}

export interface EligibilityCheckSentInsurance {
  cardholderId: string
}
