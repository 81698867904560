import { ReactComponent as CoverageIcon } from 'assets/icons/umbrella.svg'
import { RXCenterCell, ListRowLabel, ListRowValue } from 'components/RXTable'
import { Box } from 'grommet'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor, confirmLight, contrastBackgroundColor } from 'styles/styleVariables'
import type { RXFillRequest } from 'types'
import { PaymentType, type Fill, type Order, type Prescription } from 'types'
import { formatCurrency, isInsuranceFill } from 'utils'
import { isTimestampDate, formatDate } from 'utils/dates'
import { getPatientMedications } from 'utils/Patient'
import Warning from './Warning'

type FillRequestDetailsProps = {
  fill?: Fill
  height?: string
  hideConsent?: boolean
  order: Order
  prescription?: Prescription
  rxFillRequest?: RXFillRequest
  showCopayCollected?: boolean
}

type MedicationObject = {
  prescription_token: string
}

const FillRequestDetails = ({
  fill,
  height = 'auto',
  hideConsent = false,
  order,
  prescription,
  rxFillRequest,
  showCopayCollected = false,
}: FillRequestDetailsProps): JSX.Element => {
  const apiRequest = order?.apiRequest && Object.freeze(JSON.parse(order?.apiRequest as unknown as string))
  const { coreOrder, inTriage } = order ?? {}

  const patientConsent = fill?.patientConsent ?? fill?.patientConsents?.find(i => i.requestType === 'Order')
  const {
    consentMethod,
    consentStatement,
    consentType,
    consentDateStr,
    patientName,
    item: consentItem,
    consentProvidedBy,
  } = patientConsent ?? {}

  if (!order) {
    return <></>
  }

  const patientConsentDateApiRequest = apiRequest?.medications?.find(
    (med: MedicationObject) => med.prescription_token === prescription?.corePrescriptionToken,
  )?.patient_consent?.consent_date

  const patientConsentDate = isTimestampDate(patientConsentDateApiRequest)
    ? formatDate(patientConsentDateApiRequest)
    : patientConsentDateApiRequest
  const isTriagedOrder = inTriage && Object.keys(inTriage).length > 0

  // Show patient consent on triaged orders, or if its an adjudication order or if its a cash order with consent and not hidden on pv1
  const showPatientConsent =
    isTriagedOrder || ((rxFillRequest?.paymentType !== PaymentType.cash || patientConsent) && !hideConsent)

  // Display only medications corresponding to the patient's rxFillRequest
  const { patientRxFillRequests, otherRxFillRequests } = getPatientMedications(
    order.rxFillRequests,
    rxFillRequest?.patient._id,
  )

  const medRxFillRequests = patientRxFillRequests || order.rxFillRequests

  const medicationNames = medRxFillRequests.map(fr => {
    if (fr.requestedMedication) {
      const { name, quantityRequested, quantityDispensed, dawCode, daysSupply, requestedDaw } = fr.requestedMedication
      const daw = requestedDaw ?? dawCode

      return (
        <p key={fr._id}>
          {name}{' '}
          {quantityRequested && (
            <>
              <strong>RQ:</strong> {quantityRequested}
            </>
          )}{' '}
          {quantityDispensed && (
            <>
              <strong>Q:</strong> {quantityDispensed}
            </>
          )}{' '}
          {daysSupply && (
            <>
              <strong>DS:</strong> {daysSupply}
            </>
          )}{' '}
          {daw != null && (
            <>
              <strong>DAW</strong>
              {': '}
              <strong>{daw}</strong>
            </>
          )}
        </p>
      )
    } else {
      return (
        <p key={fr._id}>
          {fr.requestedMedicationName} <strong>Q:</strong> {fr.quantity}
        </p>
      )
    }
  })

  const isInsurance = isInsuranceFill(rxFillRequest)
  const showCoverageIcon = (fill?.claims?.length ?? 0) > 0
  const paymentAmount = fill?.claimSummary?.copay
  const formattedCopayString = !fill?.claimSummary?.runSuccessful
    ? ' N/A'
    : ` (Copay: ${formatCurrency(paymentAmount)})`

  const isControlled = rxFillRequest?.fill?.handlingTags?.deaScheduleNum

  return (
    <Box height={height} overflow="auto" id="FillRequestDetails">
      <StyledRXCenterCell data-test-row="fill-name">
        <ListRowLabel>Name:</ListRowLabel>
        <ListRowValue>
          {!apiRequest?.patient_token ? (
            `${apiRequest?.patient_first_name} ${apiRequest?.patient_last_name}`
          ) : (
            <PatientToken />
          )}
        </ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="fill-dob">
        <ListRowLabel>DOB:</ListRowLabel>
        <ListRowValue>
          {apiRequest?.patient_dob ? moment(apiRequest?.patient_dob).format('MM/DD/YYYY') : <PatientToken />}
        </ListRowValue>
      </StyledRXCenterCell>
      <StyledInsuranceRXCenterCell data-test-row="fill-payment" isInsurance={isInsurance}>
        <ListRowLabel>Payment:</ListRowLabel>
        <ListRowValue>
          {rxFillRequest?.paymentType ||
            coreOrder?.patient_payment_type ||
            apiRequest?.patient_payment_type ||
            'None provided'}
          {isInsurance && formattedCopayString}
        </ListRowValue>
        {showCoverageIcon && (
          <StyledCoverageIcon>
            <CoverageIcon fill={bodyPrimaryColor} />
          </StyledCoverageIcon>
        )}
      </StyledInsuranceRXCenterCell>
      <StyledRXCenterCell data-test-row="fill-controlled">
        <ListRowLabel>Controlled:</ListRowLabel>
        <ListRowValue>{isControlled ? 'Yes' : 'No'}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="fill-medication">
        <ListRowLabel>{medicationNames.length > 1 ? 'Medications:' : 'Medication:'}</ListRowLabel>
        <VerticalListRowValue>
          {medicationNames}{' '}
          {otherRxFillRequests && otherRxFillRequests?.length > 0 && (
            <Warning
              text={
                otherRxFillRequests.length === 1
                  ? 'There is another medication in the order'
                  : `There are ${otherRxFillRequests.length} medications in the order`
              }
            ></Warning>
          )}
        </VerticalListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="fill-notes">
        <ListRowLabel positionTop>Customer notes:</ListRowLabel>
        <ListRowValue>{apiRequest?.notes || 'None provided'}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="fill-patient-consent">
        {showPatientConsent && (
          <>
            <ListRowLabel positionTop>Patient consent:</ListRowLabel>
            <ListRowValue>
              {consentStatement || isInsurance ? (
                <ConsentStatement>
                  <ConsentItem>{consentStatement}</ConsentItem>
                  {patientName && (
                    <ConsentItem>
                      <BoldText>Patient Name:</BoldText> {patientName || 'Missing'}
                    </ConsentItem>
                  )}
                  {(consentProvidedBy || isInsurance) && (
                    <ConsentItem>
                      <BoldText>Consent Provided By:</BoldText> {consentProvidedBy || 'Missing'}
                    </ConsentItem>
                  )}
                  {(consentItem || isInsurance) && (
                    <ConsentItem>
                      <BoldText>Item:</BoldText> {consentItem || 'Missing'}
                    </ConsentItem>
                  )}
                  <ConsentItem>
                    <BoldText>Method:</BoldText> {consentMethod || 'Missing'}
                  </ConsentItem>
                  <ConsentItem>
                    <BoldText>Date:</BoldText> {patientConsentDate ?? consentDateStr ?? 'Missing'}
                  </ConsentItem>
                  <ConsentItem>
                    <BoldText>Type:</BoldText> {consentType || 'Missing'}
                  </ConsentItem>
                </ConsentStatement>
              ) : (
                'Missing'
              )}
            </ListRowValue>
          </>
        )}
      </StyledRXCenterCell>
      {!hideConsent && showCopayCollected && (
        <StyledRXCenterCell data-test-row="fill-copay">
          <ListRowLabel>Copay collected:</ListRowLabel>
          <ListRowValue>{formatCurrency(rxFillRequest?.copayPayment?.transactionAmount ?? 0)}</ListRowValue>
        </StyledRXCenterCell>
      )}
    </Box>
  )
}

const StyledCoverageIcon = styled.li`
  grid-column: right;
  fill: ${bodyPrimaryColor};
  margin-right: 1rem;
`

const BoldText = styled.span`
  font-weight: bold;
`

const ConsentStatement = styled.div``

const ConsentItem = styled.div``

interface IsInsuranceProps {
  isInsurance: boolean
}

const HighlightInsurance = css`
  background-color: ${confirmLight};
  margin-left: 1rem;
  padding-left: 1rem;
`

export const StyledInsuranceRXCenterCell = styled(RXCenterCell)<IsInsuranceProps>`
  ${({ isInsurance }) => isInsurance && HighlightInsurance}
  margin-left: 1rem;
`
StyledInsuranceRXCenterCell.displayName = 'StyledInsuranceRXCenterCell'

const StyledRXCenterCell = styled(RXCenterCell)`
  margin-left: 1rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`

const VerticalListRowValue = styled(ListRowValue)`
  flex-direction: column;
`

const PatientTokenUnstyled = (): JSX.Element => {
  return <>patient token</>
}

// TODO Find out Styling
const PatientToken = styled(PatientTokenUnstyled)``

export default FillRequestDetails
