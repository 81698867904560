import { useRef, useEffect, useState, useMemo } from 'react'
import { LaunchDarkly, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/inventory.svg'
import { ReactComponent as NoteIcon } from 'assets/icons/notes.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import ErrorBoundary from 'components/ErrorBoundary'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import { Pane, PaneContents } from 'components/PageStructure'
import { ToolButton } from 'components/SideToolBar'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import usePane from 'hooks/usePane'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { reverse } from 'ramda'
import styled from 'styled-components'
import { primaryColor, primaryBackgroundColor, bodyPrimaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type {
  Fill,
  Order,
  CopayRequest,
  Patient,
  Prescription,
  PriorAuthorization,
  Log,
  Prescriber,
  InsuranceProvider,
  Escript,
} from 'types'
import { isOrder, isCopayRequest } from 'utils'
import NotesCreateOrUpdate from './NotesCreateOrUpdate'
import NotesList from './NotesList'
import type { NotesFilters } from './NotesList/filters'
import type { NoteTab } from './NotesList/types'
import NotesSearch from './NotesSearch'

const DefaultTabOverridenByRole: Partial<Record<UserRoles, { tab: NoteTab; priority?: number }>> = {
  [UserRoles.CustomerSupport]: { tab: 'All', priority: 1 },
}

const getDefaultTabOverridenByRole = (roles: Record<string, boolean>): NoteTab | null => {
  let tab = null,
    lastPriority = Infinity

  Object.entries(roles).forEach(([role, value]) => {
    // is value always true?
    const rule = DefaultTabOverridenByRole[role as UserRoles]
    if (rule && ((rule.priority && lastPriority > rule.priority) || !rule.priority)) {
      tab = rule.tab
      lastPriority = rule.priority ?? Infinity
    }
  })

  return tab
}

const NotesPane = ({
  logs,
  fill,
  patient,
  patients,
  prescription,
  prescriber,
  priorAuthorization,
  escript,
  item,
  payer,
  tabs,
  excludeAllTab,
  filterOutEncounterRecordsFromOtherThanAllTabs,
  excludeCriticalTab,
  excludeExternalTab,
  defaultTab,
  tabsOrder,
  overrideTabNames,
  filters,
  isLoadingLogs = false,
  // if fill is present, it will filter out notes by fill id when set to true
  filterOutByFillId = true,
  showPrescriberNotes = false,
}: {
  logs?: Log[]
  fill?: Fill
  item?: Order | CopayRequest
  patient?: Patient
  patients?: Patient[]
  prescriber?: Prescriber
  prescription?: Prescription
  priorAuthorization?: PriorAuthorization
  payer?: InsuranceProvider
  tabs?: NoteTab[]
  filterOutEncounterRecordsFromOtherThanAllTabs?: boolean
  tabsOrder?: NoteTab[]
  excludeAllTab?: boolean
  excludeCriticalTab?: boolean
  excludeExternalTab?: boolean
  overrideTabNames?: Partial<Record<NoteTab, string>>
  filters?: NotesFilters
  defaultTab?: NoteTab
  escript?: Escript
  isLoadingLogs?: boolean
  filterOutByFillId?: boolean
  showPrescriberNotes?: boolean
}): JSX.Element => {
  const [searching, setSearching] = useState(false)
  const [search, setSearch] = useState('')
  const [hideArchivedNotes, setHideArchivedNotes] = useState(true)

  const {
    tokenContext: { roles },
  } = usePlusClient()

  const overridenDefaultTab = getDefaultTabOverridenByRole(roles)
  const decoupleCriticalNotes = useFlag(LaunchDarkly.FeatureFlags.DECOUPLE_CRITICAL_NOTES)

  const filteredNotes = useMemo(() => {
    const notes = (logs ?? []).filter(note => {
      if (note?.event !== 'note') return false
      if (!decoupleCriticalNotes && filterOutByFillId && !!fill && note.fillId !== fill?._id) return false
      if (!showPrescriberNotes && note.prescriberId)
        return note.fillId || note.prescriptionId || note.orderId || note.copayRequestId

      return true
    })

    const reversedNotes = reverse(notes)
    const filteredNotes = reversedNotes.filter(note => {
      if (hideArchivedNotes && note.isArchived) {
        return false
      }
      if (search.length === 0) {
        return true
      }
      return (
        note.message.toLowerCase().includes(search) ||
        note.user.firstName.toLowerCase().includes(search) ||
        note.user.lastName.toLowerCase().includes(search) ||
        note.tags.join(', ').toLowerCase().includes(search)
      )
    })
    return filteredNotes
  }, [fill, hideArchivedNotes, logs, search])

  const { paneVisible, firstLoad, hidePane } = usePane('Note')

  const hidePaneRef = useRef(hidePane)

  useEffect(() => {
    return hidePaneRef.current
  }, [])

  const tooltipPositioning = {
    position: 'top' as ToolTipFacing,
    offsetTop: -2.25,
    offsetLeft: 0.65,
  }

  const emptyNote = {
    ...(item && isOrder(item) && { orderId: item._id }),
    ...(item && isCopayRequest(item) && { copayRequestId: item._id }),
    fillId: fill?._id,
    patientId: patient?._id,
    prescriberId: prescriber?._id,
    prescriptionId: prescription?._id,
    escriptId: escript?._id,
    payerId: payer?._id,
    priorAuthorizationId: priorAuthorization?._id,
    message: '',
    tags: [],
  } as unknown as Log

  return (
    <ErrorBoundary>
      <Pane id="NotesPane" firstLoad={firstLoad} visible={paneVisible}>
        <PaneContents>
          <SearchButton
            data-testid="search"
            label="Search"
            icon={SearchIcon}
            searching={searching}
            clickCallback={() => setSearching(!searching)}
          />
          <ArchiveButton
            data-testid="show-archived"
            archiveHidden={hideArchivedNotes}
            label={hideArchivedNotes ? 'Display archive' : 'Hide archive'}
            tooltipText={hideArchivedNotes ? 'Display archive' : 'Hide archive'}
            icon={ArchiveIcon}
            tooltipPositioning={tooltipPositioning}
            clickCallback={() => setHideArchivedNotes(!hideArchivedNotes)}
          />
          <CloseButton data-testid="close" label="Close" icon={CloseIcon} clickCallback={hidePane} />
          <NotesTitle>
            <NoteIcon fill={bodyPrimaryColor} />
            Notes
          </NotesTitle>
          <StyledList>
            <HideHotKey />
            <NotesSearch searching={searching} setSearch={setSearch} />
            <NotesCreateOrUpdate
              note={emptyNote}
              isCopayRequest={isCopayRequest(item)}
              excludeCritical={excludeCriticalTab}
              excludeExternal={excludeExternalTab}
              orderType={isOrder(item) ? item?.orderType : undefined}
              patientList={patients}
            />
            {paneVisible && (
              <NotesList
                filters={filters}
                isLoadingLogs={isLoadingLogs}
                notes={filteredNotes}
                tabs={tabs}
                excludeAllTab={excludeAllTab}
                defaultTab={overridenDefaultTab ? overridenDefaultTab : defaultTab}
                tabsOrder={tabsOrder}
                excludeCriticalTab={excludeCriticalTab}
                excludeExternalTab={excludeExternalTab}
                filterOutEncounterRecordsFromOtherThanAllTabs={filterOutEncounterRecordsFromOtherThanAllTabs}
                overrideTabNames={overrideTabNames}
              />
            )}
          </StyledList>
        </PaneContents>
      </Pane>
    </ErrorBoundary>
  )
}

const HideHotKey = () => {
  const { hidePane } = usePane('Note')
  useHotKey('Escape', HotKeyLevel.normal, hidePane)
  return null
}

const NotesTitle = styled.h3`
  display: flex;
  font-size: 1.125rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
`

const CloseButton = styled(ToolButton)`
  border: none;
  position: absolute;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const SearchButton = styled(ToolButton)<{ searching: boolean }>`
  border: none;
  position: absolute;
  right: 5.5rem;
  height: 2rem;
  width: 2rem;
  background-color: ${({ searching }) => (searching ? primaryColor : contrastBackgroundColor)};
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const ArchiveButton = styled(ToolButton)<{ archiveHidden: boolean }>`
  background-color: ${({ archiveHidden }) => (archiveHidden ? contrastBackgroundColor : primaryColor)};
  border: none;
  position: absolute;
  right: 3.25rem;
  height: 2rem;
  width: 2rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
  svg {
    fill: ${({ archiveHidden }) => !archiveHidden && primaryBackgroundColor};
  }
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.125rem;
`

export default NotesPane
