import { useQuery } from '@truepill/tpos-react-router'
import AutoCompleteOption from 'components/MedicationAutocompleteOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_COPAY_MEDICATIONS } from 'gql'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { usePlusClient } from 'providers/VisionRouter'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { MedicationSuggestionItem, CopayMedicationSearchResult } from 'types'

type CopayMedicationSearchOptionsProps = { isVisible?: boolean; hotKey?: string; isModal?: boolean }

const CopayMedicationSearchOptions = ({
  isModal,
  hotKey = 'm',
  isVisible,
}: CopayMedicationSearchOptionsProps): JSX.Element => {
  const queue = useFulfillmentQueue() as FulfillmentQueue
  const { currentLocation, routeToMergedQuery } = usePlusClient()
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')

  const { data, loading, error } = useQuery<{ searchCopayMedications: CopayMedicationSearchResult[] }>(
    SEARCH_COPAY_MEDICATIONS,
    {
      skip: debouncedSearchTerm.length < 3,
      variables: {
        name: debouncedSearchTerm.length >= 3 ? debouncedSearchTerm : undefined,
        copayStages: [],
        fillStages: queue?.fillStages || [],
        locationId: currentLocation.queryMap?.locationId,
        ...(queue?.filters?.inTriage && { inTriage: queue.filters?.inTriage }),
      },
    },
  )

  // select the correct query returns based on the search param sent
  const options: MedicationSuggestionItem[] | undefined =
    debouncedSearchTerm.length >= 3 && data?.searchCopayMedications && data.searchCopayMedications.length > 0
      ? data.searchCopayMedications.map(searchResult => ({
          name: searchResult._id,
          count: searchResult.count,
        }))
      : undefined

  const medications = currentLocation?.queryMap?.medications || []
  const ndcs = currentLocation?.queryMap?.ndcs || []

  return (
    <>
      <SearchAutoComplete
        withCapsule
        label="Medications"
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={bodySecondaryColor}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Medications: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : options ?? [{ noResults: true }]
        }
        value={searchTerm}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as MedicationSuggestionItem)} />}
        onChange={newValue => setSearchTerm(newValue)}
        placeholder={'Search by name or NDC...'}
        onSelect={chosen => {
          setSearchTerm('')
          if (!chosen) {
            return
          }
          // If the chosen name is all numbers, it's an NDC
          if (!!chosen.name.match(/^\d+$/)) {
            if (!ndcs.includes(chosen.name)) routeToMergedQuery({ ndcs: [...ndcs, chosen.name], page: 0 })
          } else {
            if (!medications.includes(chosen.name))
              routeToMergedQuery({ medications: [...medications, chosen.name], page: 0 })
          }
        }}
      />
      <SearchResultLozengeList>
        {medications.map((name: string, idx: number) => {
          return (
            <SearchResultLozenge
              key={idx}
              closeCallback={() => {
                if (medications.includes(name)) {
                  const newMeds = [...medications]
                  newMeds.splice(medications.indexOf(name), 1)

                  routeToMergedQuery({ medications: newMeds, page: 0 })
                }
              }}
            >
              <p>{name}</p>
            </SearchResultLozenge>
          )
        })}
        {ndcs.map((name: string, idx: number) => {
          return (
            <SearchResultLozenge
              key={idx}
              closeCallback={() => {
                if (ndcs.includes(name)) {
                  const newNDCs = [...ndcs]
                  newNDCs.splice(ndcs.indexOf(name), 1)

                  routeToMergedQuery({ ndcs: newNDCs, page: 0 })
                }
              }}
            >
              <p>{name}</p>
            </SearchResultLozenge>
          )
        })}
      </SearchResultLozengeList>
    </>
  )
}

export default CopayMedicationSearchOptions
