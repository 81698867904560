import { useEffect, useMemo } from 'react'
import { useParams, useQuery } from '@truepill/tpos-react-router'
import { GET_BASIC_ORDER } from 'gql'
import { useFormData } from 'hooks/useFormData'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { Fill, Patient, Prescription, RXFillRequest, Claim, Order } from 'types'
import { updateOrder, updatePrescriptionOnOrder } from 'utils/subscribeToolkit'
import type { LoadingError } from './types'
import usePatient from './usePatient'
import usePrescription from './usePrescription'

export interface Fulfillment extends LoadingError {
  order: Readonly<Order>
  fill: Readonly<Fill>
  prescription: Readonly<Prescription>
  rxFillRequest: Readonly<RXFillRequest>
  patient: Readonly<Patient>
  claims: Claim[]
  lastClaim: Partial<Claim>
  loading: boolean
}

export default function useFill({
  fillId: fillIdProp,
  orderId: orderIdProp,
}: { fillId?: string; orderId?: string } = {}): Fulfillment {
  const { getLocationById, getCustomerById } = useTPCacheContext()
  const {
    actions: { updateFormData },
  } = useFormData()
  const pathParms = useParams<{
    fillId?: string
    orderId?: string
  }>()
  const fillId = fillIdProp || pathParms.fillId
  const orderId = orderIdProp || pathParms.orderId

  if (!orderId && !fillId) {
    throw new Error('Order ID and fill ID required for useFill')
  }

  const { loading, error, data, subscribeToMore } = useQuery(GET_BASIC_ORDER, {
    variables: { orderId },
  })

  useEffect(() => {
    if (orderId) {
      return updateOrder(subscribeToMore, { orderId })
    }
  }, [subscribeToMore, orderId])

  useEffect(() => {
    if (data?.getOrder?.rxFillRequests?.length && subscribeToMore) {
      const prescriptionIds = data.getOrder.rxFillRequests.map((fr: RXFillRequest) => fr.prescriptionId)

      return updatePrescriptionOnOrder(subscribeToMore, { prescriptionIds }, false)
    }
  }, [subscribeToMore, data?.getOrder])

  const rxFillRequest = Object.freeze(
    data?.getOrder?.rxFillRequests?.find((rxfr: RXFillRequest) => rxfr.fillId === fillId),
  )

  const patientHookProps = usePatient({ loading, error, patient: rxFillRequest?.patient })
  const patient = patientHookProps.patient

  const prescriptionHookProps = usePrescription({ loading, error, prescription: rxFillRequest?.prescription })
  const prescription = prescriptionHookProps.prescription as Prescription

  const { fill: f } = rxFillRequest || {}
  const fill = useMemo(() => {
    return { ...f, location: getLocationById(f?.locationId as string) } as Fill
  }, [f, getLocationById])

  const order = useMemo(() => {
    const o = data?.getOrder
    return { ...o, location: getLocationById(o?.locationId), customer: getCustomerById(o?.customerId) }
  }, [data, getCustomerById, getLocationById])

  // Init editable versions of the fill data
  useEffect(() => {
    updateFormData({ fill: { $set: fill } })
  }, [fill, updateFormData])

  useEffect(() => {
    if (data?.getOrder?._id) {
      updateFormData({ order: { $set: data.getOrder } })
    }
  }, [data, updateFormData])

  if (
    loading ||
    error ||
    patientHookProps.loading ||
    patientHookProps.error ||
    prescriptionHookProps.loading ||
    prescriptionHookProps.error
  ) {
    return {
      loading: loading || patientHookProps.loading || prescriptionHookProps.loading,
      error: error || patientHookProps.error || prescriptionHookProps.error,
    } as Fulfillment
  }

  return {
    order,
    patient,
    fill,
    claims: fill?.claims || [],
    lastClaim: fill?.claims ? fill?.claims[fill?.claims?.length - 1] : {},
    prescription,
    rxFillRequest,
    loading: !!loading || !!patientHookProps.loading || !!prescriptionHookProps.loading,
  }
}
