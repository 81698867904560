import { useLayoutEffect, useRef, useState } from 'react'
import AddressEntry from 'components/AddressEntry'
import HotKeyToolTip from 'components/HotKeyToolTip'
import { FilledHeader } from 'components/PageStructure'
import {
  ListRowLabel,
  ListRowValue,
  NoColorBackground,
  ContrastBackgroundColor,
  PaddingBlock,
  RaisedAnchor,
  StyledRXImageCell,
  CloseOffTopBorder,
  CloseOffBottomBorder,
  RXCellPaddingV,
  RXBorderStyle,
} from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'
import type { Fill, QueueItem, EscriptPatient, Prescription, Patient, RXFillRequest } from 'types'
import {
  capitalize,
  checkIfProvided,
  formatPhoneNumber,
  getRxImagePatient,
  isOrder,
  isCopayRequest,
  getItemFill,
  formatCurrency,
} from 'utils'
import { isTimestampDate, formatDate } from 'utils/dates'
import { isHuman } from 'utils/Patient'
import RejectionMessages from '../../RejectionMessages'

interface RXReviewColumnProps {
  maxHeight?: number
}

type TopSectionProps = {
  claimJumperErrors: string[]
  fill: Fill
  item: QueueItem
  patient?: Patient
  prescription: Prescription
  rxFillRequest?: RXFillRequest
  showCopayCollected?: boolean
}

type MedicationObject = {
  prescription_token: string
}

const rejectionMessagesStyle = css`
  align-content: start;
  position: relative;
  ${RXCellPaddingV}
  border: ${RXBorderStyle};
  margin: 0;
`

const TopSection = ({
  claimJumperErrors,
  fill,
  item,
  patient,
  prescription,
  rxFillRequest,
  showCopayCollected = false,
}: TopSectionProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()
  const escriptPatient = prescription?.escript?.patient as EscriptPatient
  const directTransferPatient = prescription?.directTransfer?.patient
  const rxImagePatient = getRxImagePatient(escriptPatient, directTransferPatient)
  const { routeToHash } = usePlusClient()

  // section header hotkeys
  useHotKey('1', HotKeyLevel.normal, () => routeToHash(`RejectionMessage`))
  useHotKey('2', HotKeyLevel.normal, () => routeToHash(`Payer`))

  const apiRequest = item?.apiRequest && JSON.parse(item?.apiRequest as unknown as string)
  const itemFills = isOrder(item) ? item.rxFillRequests : item.copayRequestFills
  const isControlled = fill?.handlingTags?.deaScheduleNum !== ''
  const hasPrescriptionToken = apiRequest?.medications?.some((med: MedicationObject) => !!med.prescription_token)
  const patientConsentDateApiRequest = apiRequest?.medications?.find(
    (med: MedicationObject) => med.prescription_token === prescription.corePrescriptionToken,
  )?.patient_consent?.consent_date

  const patientConsentDate = isTimestampDate(patientConsentDateApiRequest)
    ? formatDate(patientConsentDateApiRequest)
    : patientConsentDateApiRequest

  const medicationNames = isOrder(item) ? (
    itemFills.map(fr => (
      <p key={fr._id}>
        {fr.requestedMedicationName}, Qty: {fr.quantity}
      </p>
    ))
  ) : (
    <p key={fill._id}>
      {prescription.name}, Qty: {prescription.quantity}
    </p>
  )

  // To make the fill request column scrollable, leave off the patient consent cell, measure the column height, then set
  // fillRequestColumnMaxHeight to the column height we got (and use as max-height), and add patient consent
  const fillRequestColumn = useRef<HTMLDivElement>(null)
  const [fillRequestColumnMaxHeight, setFillRequestColumnMaxHeight] = useState<number | undefined>(undefined)
  useLayoutEffect(() => {
    if (fillRequestColumnMaxHeight) return
    const height = fillRequestColumn.current?.getBoundingClientRect().height
    setFillRequestColumnMaxHeight(height)
  }, [fillRequestColumn, fillRequestColumnMaxHeight, setFillRequestColumnMaxHeight])

  // Show patient consent if it exists for the copay
  const consentRequestType = isCopayRequest(item) ? 'Copay' : 'Order'
  const showPatientConsent = fillRequestColumnMaxHeight
    ? fill?.patientConsents?.find(i => i.requestType === consentRequestType)
    : undefined

  const patientConsent = showPatientConsent ?? {}
  const {
    consentMethod,
    consentStatement,
    consentType,
    consentDateStr,
    patientName,
    item: consentItem,
    consentProvidedBy,
  } = patientConsent

  if (!prescription?.escript && !prescription?.directTransfer) {
    setRxImageIsPic(true)
  }
  // TEMP HACKY DEV STUFF FOR TESTING
  useHotKey('x', HotKeyLevel.normal, () => {
    setRxImageIsPic(!rxImageIsPic)
  })

  return (
    <>
      <RXReview>
        <RXReviewColumn>
          <RXReviewHeader>Rx Image</RXReviewHeader>
        </RXReviewColumn>
        <RXReviewColumn>
          {/* <RaisedAnchor id={'RejectionMessage'} /> */}
          <RXReviewHeader>
            Claim submission errors
            {/* <HotKeyToolTip label={'1'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
          </RXReviewHeader>
        </RXReviewColumn>
        <RXReviewColumn>
          <RaisedAnchor id={'RejectionMessage'} />
          <RXReviewHeader>
            {isOrder(item) ? 'Fill Request' : 'Copay Request'}
            <HotKeyToolTip label={'1'} position={'right'} offsetLeft={1} offsetTop={0} />
          </RXReviewHeader>
        </RXReviewColumn>
      </RXReview>

      <RXReview>
        <RXReviewColumn>
          {rxImageIsPic && (
            <RXReviewCell>
              {!prescription?.escript && !prescription?.directTransfer ? (
                <NoEscriptInfoBox>No escript or direct transfer - view Rx image below</NoEscriptInfoBox>
              ) : (
                <NoEscriptInfoBox />
              )}
            </RXReviewCell>
          )}
          <RXReviewCell>
            <ListRowLabel>Name:</ListRowLabel>
            <ListRowValue data-private>
              {checkIfProvided(rxImagePatient?.firstName)} {rxImagePatient?.lastName}
            </ListRowValue>
          </RXReviewCell>
          {!isHuman(patient) && (
            <>
              <RXReviewCell>
                <ListRowLabel>Species:</ListRowLabel>
                <ListRowValue data-private>{patient?.species}</ListRowValue>
              </RXReviewCell>
              <RXReviewCell>
                <ListRowLabel>Guardian:</ListRowLabel>
                <ListRowValue data-private>{patient?.guardian}</ListRowValue>
              </RXReviewCell>
            </>
          )}
          <RXReviewCell>
            <ListRowLabel>DOB:</ListRowLabel>
            <ListRowValue data-private>
              {rxImagePatient?.dob ? moment(rxImagePatient.dob).format('MM/DD/YYYY') : 'None provided'}
            </ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Gender:</ListRowLabel>
            <ListRowValue data-private>
              {rxImagePatient?.gender ? capitalize(rxImagePatient?.gender) : 'None provided'}
            </ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Address:</ListRowLabel>
            <AddressEntry data-private address={rxImagePatient?.address} />
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Phone:</ListRowLabel>
            <ListRowValue data-private>
              {checkIfProvided(formatPhoneNumber(rxImagePatient?.communicationNumbers?.phone?.number))}
            </ListRowValue>
          </RXReviewCell>
        </RXReviewColumn>

        <RXReviewColumn>
          <RejectionMessages
            containerStyles={rejectionMessagesStyle}
            rxImageIsPic={rxImageIsPic}
            claimJumperErrors={claimJumperErrors}
            claims={fill.claims}
            prescription={prescription}
            item={item}
            fill={fill}
          />
        </RXReviewColumn>
        <RXReviewColumn maxHeight={fillRequestColumnMaxHeight} ref={fillRequestColumn} style={{ overflowY: 'auto' }}>
          <RXReviewCell>
            <ListRowLabel>Name:</ListRowLabel>
            <ListRowValue>
              {isOrder(item) &&
                (!apiRequest?.patient_token ? `${patient?.firstName} ${patient?.lastName}` : <PatientToken />)}
              {isCopayRequest(item) &&
                (!apiRequest?.patient_token ? (
                  `${apiRequest?.patient_first_name || item.patient?.firstName} ${
                    apiRequest?.patient_last_name || item.patient?.lastName
                  }`
                ) : (
                  <PatientToken />
                ))}
            </ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>DOB:</ListRowLabel>
            <ListRowValue data-private>
              {isOrder(item) &&
                (apiRequest?.patient_dob ? moment(apiRequest?.patient_dob).format('MM/DD/YYYY') : <PatientToken />)}
              {isCopayRequest(item) && moment(item.patient?.dob).format('MM/DD/YYYY')}
            </ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Payment:</ListRowLabel>
            <ListRowValue data-private>
              {isCopayRequest(item) ? 'Insurance' : getItemFill(item, fill._id)?.paymentType || 'Insurance'}
            </ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Controlled:</ListRowLabel>
            <ListRowValue data-private>{isControlled ? 'Yes' : 'No'}</ListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Medication:</ListRowLabel>
            <VerticalListRowValue>{hasPrescriptionToken ? 'prescription token' : medicationNames}</VerticalListRowValue>
          </RXReviewCell>
          <RXReviewCell>
            <ListRowLabel>Customer notes:</ListRowLabel>
            <ListRowValue>{apiRequest?.notes || 'None provided'}</ListRowValue>
          </RXReviewCell>
          {isCopayRequest(item) && (
            <RXReviewCell>
              <ListRowLabel>Ship To State:</ListRowLabel>
              <ListRowValue>{getItemFill(item, fill._id)?.shipToState || 'Unknown'}</ListRowValue>
            </RXReviewCell>
          )}
          {showPatientConsent && (
            <RXReviewCell>
              <ListRowLabel positionTop>Patient consent:</ListRowLabel>
              <ListRowValue>
                {consentStatement ? (
                  <ConsentStatement>
                    <ConsentItem>{consentStatement}</ConsentItem>
                    {patientName && (
                      <ConsentItem>
                        <BoldText>Patient Name:</BoldText> {patientName || 'Missing'}
                      </ConsentItem>
                    )}
                    {consentProvidedBy && (
                      <ConsentItem>
                        <BoldText>Consent Provided By:</BoldText> {consentProvidedBy || 'Missing'}
                      </ConsentItem>
                    )}
                    {consentItem && (
                      <ConsentItem>
                        <BoldText>Item:</BoldText> {consentItem || 'Missing'}
                      </ConsentItem>
                    )}
                    <ConsentItem>
                      <BoldText>Method:</BoldText> {consentMethod || 'Missing'}
                    </ConsentItem>
                    <ConsentItem>
                      <BoldText>Date:</BoldText> {patientConsentDate ?? consentDateStr ?? 'Missing'}
                    </ConsentItem>
                    <ConsentItem>
                      <BoldText>Type:</BoldText> {consentType || 'Missing'}
                    </ConsentItem>
                  </ConsentStatement>
                ) : (
                  'Missing'
                )}
              </ListRowValue>
            </RXReviewCell>
          )}
          {showCopayCollected && (
            <RXReviewCell>
              <ListRowLabel>Copay Collected:</ListRowLabel>
              <ListRowValue>{formatCurrency(rxFillRequest?.copayPayment?.transactionAmount ?? 0)}</ListRowValue>
            </RXReviewCell>
          )}
        </RXReviewColumn>
      </RXReview>
      {rxImageIsPic ? <RXImagePaddingBottom /> : <BottomPadding />}
    </>
  )
}

const BottomPadding = styled(PaddingBlock)`
  min-height: 2rem;
`

const RXImagePaddingBottom = styled(PaddingBlock)`
  min-height: 4rem;
`

const RXReviewRow = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] 33.333% [edit] 33.333% [test] 33.333%;
  ${({ transparent }) => transparent && NoColorBackground}
`
RXReviewRow.displayName = 'RXReviewRow'

const RXReview = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] 1fr [edit] 1fr [test] 1fr;
  ${({ transparent }) => transparent && NoColorBackground}
  column-gap: 1.25rem;
`
RXReview.displayName = 'RXReview'

const RXReviewHeader = styled(FilledHeader)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 1rem;

  :empty {
    ${NoColorBackground}
  }
`
RXReviewHeader.displayName = 'RXReviewHeader'

const RXReviewColumn = styled.div<RXReviewColumnProps>`
  align-content: start;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight + 10 + 'px' : 'none')};
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  > ul:nth-child(even) {
    ${ContrastBackgroundColor}
  }

  > ul:first-of-type {
    ${CloseOffTopBorder}
  }

  > ul:last-of-type {
    ${CloseOffBottomBorder}
  }
`
RXReviewColumn.displayName = 'RXReviewColumn'

const RXReviewCell = styled.ul.attrs({
  'data-test-column': 'left',
})`
  align-content: start;
  position: relative;
  ${RXCellPaddingV}
  border-left: ${RXBorderStyle};
  border-right: ${RXBorderStyle};
  font-family: monospace;
  > h4 {
    font-family: roboto, sans-serif;
  }
  > label {
    align-self: start;
    padding-left: 1.25rem;
    font-weight: 550;
  }
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 11rem [value] 1fr;
  align-items: stretch;
  :empty {
    ${NoColorBackground}
  }
`
RXReviewCell.displayName = 'RXReviewCell'

const RXPatientRightEditCell = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  height: 0;
  background-color: white;
  margin-top: 0.625rem;
  top: -0.98rem;
  z-index: 1;
`
RXPatientRightEditCell.displayName = 'RXPatientRightEditCell'

const RXPatientRightEditRow = styled.ul`
  grid-template-columns: [label] 10rem [value] 1fr;
  display: grid;
  grid-template-rows: auto 0.625rem;
  margin-right: 1rem;
`
RXPatientRightEditRow.displayName = 'RXPatientRightEditRow'

const StyledPatientLeftCell = styled(StyledRXImageCell)`
  grid-template-columns: [label] 1fr [value] 1fr;
  min-height: 2rem;
`
StyledPatientLeftCell.displayName = 'StyledPatientLeftCell'

const RXRejectionCell = styled.ul<{ backgroundColor?: string }>`
  display: grid;
  grid-template-rows: auto;
  margin-left: 1.25rem;
  grid-template-columns: [image] 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
RXRejectionCell.displayName = 'RXRejectionCell'

const NoEscriptInfoBox = styled.div`
  background-color: ${primaryBackgroundColor};
  height: 0;
  overflow-y: visible;
  position: relative;
  width: 30vw;
  padding-bottom: 3rem;
  z-index: 1;
  top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PatientTokenUnstyled = () => {
  return <>patient token</>
}

const BoldText = styled.span`
  font-weight: bold;
`

const ConsentStatement = styled.div``

const ConsentItem = styled.div``

// TODO Find out Styling
const PatientToken = styled(PatientTokenUnstyled)``

const VerticalListRowValue = styled(ListRowValue)`
  flex-direction: column;
`

export default TopSection
