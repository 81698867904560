import { useCallback, useEffect, useState } from 'react'

type FecthMore = (offset: number, limit: number) => void

const usePaginationManager = <T>(
  fetchMore?: FecthMore,
  itemsInput?: { totalRecords: number; items: T[] },
): {
  items: T[]
  currentPage: number
  totalRecords: number
  totalPages: number
  pageSize: number
  setPage: (page: number) => void
  setLimit: (limit: number) => void
} => {
  const { totalRecords = 0, items = [] } = itemsInput ?? {}
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    if (fetchMore) {
      fetchMore(page, limit)
    }
  }, [fetchMore, page, limit])

  const changePage = useCallback((page: number) => {
    setPage(page)
  }, [])

  const totalPages = Math.ceil(totalRecords / limit)

  return {
    items,
    currentPage: page,
    totalRecords,
    totalPages,
    pageSize: limit,
    setPage: changePage,
    setLimit,
  }
}

export default usePaginationManager
