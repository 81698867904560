import { useEffect } from 'react'
import { CopayStatus } from '@truepill/tpos-types'
import Paging from 'components/Paging'
import useFulfillmentCopays from 'hooks/navigation/useFulfillmentCopays'
import type { GetFulfillmentOrdersParams } from 'hooks/navigation/useFulfillmentOrders'
import useErrorToast from 'hooks/toast/useErrorToast'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import type { CopayRequest, OrderSearchVariables } from 'types'
import PrescriptionQueue from './PrescriptionQueue'

type ExpandedQueueSectionProps = { orderSearchVariables: OrderSearchVariables }

const ExpandedQueueSection = ({ orderSearchVariables }: ExpandedQueueSectionProps): JSX.Element => {
  const {
    routeToMergedQuery,
    currentLocation,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()

  const showErrorToast = useErrorToast()
  const fulfillmentQueue = useFulfillmentQueue()

  const pageSize = currentLocation.queryMap.pageSize ? parseInt(currentLocation.queryMap.pageSize, 10) : 10

  const pagingVariables = {
    pageNumber: Number(currentLocation.queryMap?.page) || 0,
    pageSize: pageSize,
  }

  const queryMap = currentLocation?.queryMap || {}
  const currentCopayStatus: CopayStatus = queryMap.copayStatus || CopayStatus.Pending

  const getCopaysVariables = {
    ...(fulfillmentQueue?.filters ?? {}),
    ...(queryMap.locationIds && { selectedLocationIds: queryMap.locationIds }),
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(queryMap.ndcs && { ndcs: queryMap.ndcs }),
    ...(queryMap.medications && { medicationNames: queryMap.medications }),
    ...(queryMap.batchOrders && { isBatch: StringBoolean(queryMap.batchOrders) }),
    ...(queryMap.triageReasons && { triageReasons: queryMap.triageReasons }),
    ...{ status: currentCopayStatus },
    ...(orderSearchVariables.customerRef && { customerRef: orderSearchVariables.customerRef }),
    ...(orderSearchVariables.drugName && { drugName: orderSearchVariables.drugName }),
    ...(orderSearchVariables.coreCopayToken && {
      coreCopayToken: orderSearchVariables.coreCopayToken,
    }),
    ...(orderSearchVariables.coreCopayRequestFillToken && {
      coreCopayRequestFillToken: orderSearchVariables.coreCopayRequestFillToken,
    }),
    // If the handling tags select was cleared then we send an empty array for the query
    ...(queryMap.noHandlingTags && { specialHandlingTags: [] }),
    ...(queryMap.states?.length && { states: queryMap.states }),
    ...(queryMap.noStates && { states: [] }),
  }

  const copayQueryVariables: GetFulfillmentOrdersParams = { ...getCopaysVariables, ...pagingVariables, sort: 1 }

  const { data, loading, error } = useFulfillmentCopays(copayQueryVariables)

  // TODO - copay subscriptions??

  useEffect(() => {
    if (error) {
      showErrorToast(error?.message)
    }
  }, [error, showErrorToast])

  const copays: CopayRequest[] = data?.getFulfillmentCopays?.copayRequests || []

  const totalCopays = data?.getFulfillmentCopays?.totalRecords ?? 0
  const currentPage = data?.getFulfillmentCopays?.currentPage ?? 0
  const totalPages = Math.ceil(totalCopays / pageSize)

  return (
    <SingleQueue>
      <PrescriptionQueue
        items={copays}
        totalItems={totalCopays}
        loading={loading}
        queue={fulfillmentQueue as FulfillmentQueue}
        selected
        locationId={queryMap.locationId}
      />

      {!loading && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
          }}
          totalRecords={totalCopays}
          pageSize={pageSize}
        />
      )}
    </SingleQueue>
  )
}

const QueueBaseStyle = css`
  background-color: white;
  grid-row: content;
  grid-column: fills;
  margin-left: 1.25rem;
`

export default ExpandedQueueSection

const SingleQueue = styled.div`
  ${QueueBaseStyle}
  height: calc(100vh - 13rem);
  margin-right: 1.875rem;
  position: relative;
`
