import { useState } from 'react'
import { useHistory } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { NoResultsEntry } from 'components/PageStructure'
import { useLocation } from 'react-use'
import { goToAddPrescriberPractice } from 'routes'
import { borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import PracticeAddressEntry from './components/PracticeAddressEntry'
import TitleRow from './components/TitleRow'
import {
  PaddingContainer,
  PracticeAddressContainer,
  NewPracticeLocationControls,
  SearchLabel,
  SearchControls,
  StyledActionBar,
  StyledSearchInput,
  StyledActionButton,
} from './StyledComponents'

type PracticeAddressTableProps = { prescriber: Prescriber; isVet: boolean }

const PracticeAddressTable = ({ prescriber, isVet }: PracticeAddressTableProps): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const addresses = prescriber.addresses
  const [searchTerm, setSearchTerm] = useState('')
  const [displayArchive, setDisplayArchive] = useState<boolean>(false)

  return (
    <PaddingContainer>
      <PracticeAddressContainer>
        <StyledActionBar>
          <SearchControls>
            <SearchLabel>Practice addresses</SearchLabel>
            <StyledSearchInput
              value={searchTerm}
              iconColor={borderColor}
              placeholder="Search addresses..."
              onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
              width="100%"
            />
          </SearchControls>
          <NewPracticeLocationControls>
            <AuthLimited roles={[UserRoles.Admin]}>
              <StyledActionButton
                icon={
                  <IconWrapper>
                    <InventoryIcon fill={displayArchive ? primaryBackgroundColor : 'black'} />
                  </IconWrapper>
                }
                showArchive={displayArchive}
                hotKey="n"
                label="Display archive"
                onClick={() => setDisplayArchive(!displayArchive)}
              />
              <StyledActionButton
                icon={
                  <IconWrapper>
                    <PlusIcon fill="black" />
                  </IconWrapper>
                }
                hotKey="n"
                label="New practice"
                onClick={() => history.push(goToAddPrescriberPractice({ prescriberId: prescriber._id, search }))}
              />
            </AuthLimited>
          </NewPracticeLocationControls>
        </StyledActionBar>
        <TitleRow isVet={isVet} />
        {addresses
          .filter(address => {
            if (!displayArchive && address.isArchived === true) return false
            if (!searchTerm) return true
            return JSON.stringify(address).toLowerCase().includes(searchTerm.toLowerCase())
          })
          .map(address => (
            <PracticeAddressEntry isVet={isVet} key={address._id} prescriber={prescriber} address={address} />
          ))}
        {addresses.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      </PracticeAddressContainer>
    </PaddingContainer>
  )
}

export default PracticeAddressTable
