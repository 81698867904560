import { useEffect } from 'react'
import { Chip } from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import { capsuleDarkRedColor } from 'styles/styleVariables'
import type { DURSubject } from 'types'

export const isReal = <TValue extends { type?: string }>(
  value: TValue | null | undefined,
  // @ts-expect-error my TS knowlegde is failing here
): value is DeepRequired<TValue> => value !== null && value !== undefined

interface Props extends FuzzySearchProps {
  suggestions: DURSubject[]
  error?: ApolloError
}

const FuzzySearchAutoComplete = ({
  hotKey = 'c',
  onResultDismiss,
  idKey,
  onChange,
  onError,
  value,
  searchTerm = '',
  suggestions,
  error,
  label,
}: Props): JSX.Element => {
  const hasError = !!error

  useEffect(() => {
    if (hasError) {
      onError?.()
    }
  }, [hasError])

  return (
    <>
      <SearchAutoComplete
        maximumHeight="11rem"
        disabled
        allowCustomInput={false}
        withCapsule
        label={label}
        autoCompleteSuggestions={suggestions}
        hotKey={hotKey}
        placeholder=""
        retainFocusAfterSelection
        value={hasError ? '' : searchTerm}
        idKey={idKey}
        suggestionComponent={({
          name,
          highlighted,
          onClick,
          value,
        }: DURSubject & { highlighted: boolean; onClick: () => void }) => (
          <DropDownOption data-testid="suggestion-item" highlighted={highlighted} onClick={onClick}>
            <p>
              {name} {name?.length ? <>({value})</> : value}
            </p>
          </DropDownOption>
        )}
        onSelect={(chosen: DURSubject) => {
          onChange?.(chosen)
        }}
      ></SearchAutoComplete>
      {hasError && (
        <Chip
          css={{
            backgroundColor: capsuleDarkRedColor,
            color: 'white',
            position: 'absolute',
            top: '12px',
            left: '45px',
          }}
        >
          Error
        </Chip>
      )}
      {value && !hasError && (
        <Chip
          onDismiss={onResultDismiss ? onResultDismiss : undefined}
          css={{ position: 'absolute', top: '12px', left: '35px' }}
        >
          {value.name}
        </Chip>
      )}
    </>
  )
}
export default FuzzySearchAutoComplete
