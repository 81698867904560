import { useRef, useState, useEffect } from 'react'
import type { ChangeEventHandler, FormEventHandler } from 'react'
import { Box, Button, Card, Text, TextField } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { OutboundScanType } from '@truepill/tpos-types'
import { gql } from 'gql/generated'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import { formatDate } from 'utils/dates'
import sanitizeTrackingNumber from 'utils/sanitizeTrackingNumber'

export const SEND_OUTBOUND_SCAN = gql(`
mutation sendOutboundScan($trackingNumber: String!, $outboundScanType: OutboundScanType!) {
  sendOutboundScan(trackingNumber: $trackingNumber, outboundScanType: $outboundScanType) {
    coreOrderId
    patientName
    patientType
    trackingNumber
    scanAt
  }
}
`)

const PharmacyOutboundScanPage = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>()
  const [trackingNumber, setTrackingNumber] = useState('')
  const showErrorToast = useErrorToast(true)
  const showSuccessToast = useSuccessToast()
  const [sendOutboundScan, { loading }] = useMutation(SEND_OUTBOUND_SCAN, {
    onCompleted: data => {
      const outboundScan = data?.sendOutboundScan
      showSuccessToast(
        `${outboundScan?.patientType}: ${outboundScan?.patientName}. Scan at: ${formatDate(
          outboundScan?.scanAt,
          'MM/DD/YYYY hh:mmA',
        )}`,
      )
      setTrackingNumber('')
      if (inputRef.current) {
        inputRef.current.focus()
      }
    },
    onError: error => {
      showErrorToast(error?.message)
    },
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = e => {
    const trackingNumber = e.target.value
    setTrackingNumber(trackingNumber)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    const sanitizedTrackingNumber = sanitizeTrackingNumber(trackingNumber)
    sendOutboundScan({
      variables: { trackingNumber: sanitizedTrackingNumber, outboundScanType: OutboundScanType.tool },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box css={{ padding: '$lg' }}>
        <OutboundCard shadow="lg" padding="lg">
          <Text as="h1">Outbound Scan</Text>
          <TextField
            ref={inputRef}
            label="Tracking Number"
            required
            placeholder="Please enter your placeholder here"
            onChange={handleOnChange}
            value={trackingNumber}
          />
          <ScanButton type="submit" disabled={loading || !trackingNumber}>
            SCAN
          </ScanButton>
        </OutboundCard>
      </Box>
    </form>
  )
}

const OutboundCard = styled(Card)`
  margin: 0 auto;
  text-align: center;
  width: 50%;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`
const ScanButton = styled(Button)`
  margin: 1em;
`
export default PharmacyOutboundScanPage
