import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg'
import styled from 'styled-components'
import { darkInfo } from 'styles/styleVariables'

interface WarningProps {
  text: string
}

const WarningIcon = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  aspect-ratio: 1 / 1;
  background-color: ${darkInfo};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`
const WarningText = styled.div`
  color: ${darkInfo};
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0.5rem;
`

const Warning = ({ text }: WarningProps) => {
  return (
    <WarningContainer>
      <WarningIcon>
        <ExclamationIcon />
      </WarningIcon>
      <WarningText>{text}</WarningText>
    </WarningContainer>
  )
}

export default Warning
