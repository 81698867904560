import type { ReactNode } from 'react'
import type {
  ClaimData,
  ClaimEdiReceivedRejectCode,
  CopayPayments,
  CopayStatus,
  DirectTransfer,
  Dispensed,
  DURSubjectCategories,
  EscriptTriageReasons,
  FfsInventoryInfo,
  FillData,
  FillDUR,
  HandlingTags,
  InsuranceCardType,
  License,
  MedicationStrength,
  Medispan,
  NextFillInfo,
  OrderStatus,
  OrderType,
  OutboundTransfer as OutboundTransferBase,
  PatientStatus,
  PatientSurveyInput,
  Preferences,
  PrescriberAddress,
  PriorAuthorization as PriorAuthorizationV2,
  PriorAuthorizationInput,
  RequestedMedication,
  RxDiscontinueReason,
  RxFillRequestSpecialHandlingTag,
  RxFillRequestStatus,
  RxStatus,
  Species,
  TriageReasons,
  UserRoles,
} from '@truepill/tpos-types'

export type { Dispensed } from '@truepill/tpos-types'
export { PatientStatus } from '@truepill/tpos-types'

export interface SimpleKV {
  [key: string]: SimpleKV | number | string | boolean
}

export enum StageType {
  FillStage,
  OrderStage,
  Triage,
  None,
}

export interface SigCodeTranslation {
  en: string
  es?: string
}

export interface SigCode {
  _id: string
  code: string
  multiplier: number
  translations: SigCodeTranslation
  exceptionNDC?: [ExceptionNDC]
  active: boolean
}

export interface ExceptionNDC {
  ndc: string
  multiplier?: number
  medicationName?: string
}

export interface InventoryGroup {
  pioneerAlias: string
  customer: Customer
  customerId: string
  _id: string
  active?: boolean
}

export type SurescriptsNumber = number | '.'

export const gender = ['female', 'male', 'unknown']

export interface TPOSLocation {
  _id: string
  name: string
  legacyId: number
  address: Address
  dba?: string
  active?: boolean
  npi?: string
  ncpdpid?: string
  pharmacistInChargeId?: string
  pharmacistInCharge: User | null
  corporateName?: string
  users: User[]
  contacts?: {
    phone?: string
    fax?: string
  }
  isSnapLocation: boolean
  timezone?: string
  licensedStates?: string[]
}

export interface Customer {
  _id: string
  name: string
  legacyId: number
  active: boolean
}

export interface PrinterCalibration {
  zoom?: number
  label?: { width: number; height: number }
  padding?: { top: number; right: number; bottom: number; left: number }
}

export interface Printer {
  _id: string
  printerName: string
  printerType: string
  printerPurpose: string
  make: string
  GCPAddress: string
  printerIP: string
  location?: TPOSLocation
  locationId: string
  status: PrinterStatus
  calibration: PrinterCalibration
  createdAt: Date
}

export interface PrinterLevels {
  colors: string
  levels: number
}

export interface PrinterInfo {
  status: PrinterStatus
  toner: PrinterLevels
  model: string
  name: string
  queueLength: number
}

export interface Otc {
  _id: string
  sku: string
  customerId: string
  upc: string[]
  quantity: number
}

export enum OTCStatusType {
  ret = 'Ret',
  replacement = 'Rep',
  refund = 'Ref',
  outOfStock = 'OOS',
}

export enum OTCStatusState {
  pending = 'Pending',
  complete = 'Complete',
}

export interface OTCItemStatus {
  type: OTCStatusType
  state: OTCStatusState
}

export interface PackingMaterial {
  _id: string
  sku: string
  upc: string[]
  quantity: number
  itemName: string
}

export enum PrinterStatus {
  unknown = 'unknown',
  idle = 'idle',
  processing = 'processing',
  stopped = 'stopped',
  notAvailable = 'not available',
}

// All available Rejection Reasons values in core:
// https://github.com/truepill/unified-dashboard/blob/develop/backend/src/v1/enums/apiErrorCodes.js
const BaseOrderRejectionReasonObj = {
  'Bad address details': 'R2',
  'Bad insurance details': '12',
  'Clinical Outreach to MD': 'R022',
  'Closed network': 'R003',
  'Contraindication (drug, disease, allergy)': 'TEMPTEMPCONTRA',
  'Copay Amount Changed': 'R050',
  'Coverage not effective': 'R004',
  'Customer cancellation request': 'R0',
  'DOB mismatch': '09',
  'Duplicate Order': 'R1',
  'High copay': 'R009',
  'Invalid consent': 'INVALID_CONSENT',
  'Invalid Replacement Order': 'ORDER_INVALID_REPLACEMENT',
  'Issue collecting payment': 'R007',
  'Mail order fills exceeded': 'R010',
  'Maintenance fills exceeded': 'MAINTENANCE_FILLS_EXCEEDED',
  'Maximum benefits reached': 'MAXIMUM_BENEFITS_REACHED',
  'Name mismatch': '62',
  'No e-script': 'PC',
  'No refills/quantity remaining': 'NR',
  'Non-formulary': 'R023',
  Other: 'R019',
  'Weather Delay': 'WEATHER_DELAY',
  'Customer Clarification Needed': 'CUSTOMER_CLARIFICATION_NEEDED',
  'Out of stock': 'R3',
  'Primary Insurance Required': 'PRIMARY_INSURANCE_REQUIRED',
  'Prior Authorization Required': '75',
  'Quantity Limited By Insurance': 'R015',
  'Quantity discrepancy': 'QUANTITY_DISCREPANCY',
  'Refill too soon': '79',
  'Rx Expired': 'R035',
  'Test order': 'R017',
  'Transfer out of pharmacy': 'R5',
  'Order contains controlled substance': 'CONTAINS_CONTROLLED_SUBSTANCE',
} as const

export const OrderRejectionReason = {
  ...BaseOrderRejectionReasonObj,
  'Unavailable to order': 'UNAVAILABLE_TO_ORDER',
} as const

export type OrderRejectionReason = (typeof OrderRejectionReason)[keyof typeof OrderRejectionReason]

/**
 * @deprecated This object is deprecated. Use OrderRejectionReason instead. Should be removed once the feature flags
 * 'temp-update-r3-rejection-message' and 'temp-update-unavailable-to-order-rejection-code' are deleted.
 */
export const LegacyOrderRejectionReason = {
  ...BaseOrderRejectionReasonObj,
  'Unavailable to order': 'R3',
} as const

/**
 * @deprecated This type is deprecated. Use OrderRejectionReason instead. Should be removed once the feature flags
 * 'temp-update-r3-rejection-message' and 'temp-update-unavailable-to-order-rejection-code' are deleted.
 */
export type LegacyOrderRejectionReason = (typeof LegacyOrderRejectionReason)[keyof typeof LegacyOrderRejectionReason]

export enum OutboundTransferRejectionReason {
  'Invalid Transfer Info' = 'INVALID_TRANSFER_INFO',
  'Outreach Attempts Exhausted' = 'OUTREACH_EXHAUSTED',
  'Invalid Rx Status' = 'INVALID_RX_STATUS',
  'Other' = 'OTHER',
}

export enum CopayFillRejectionReason {
  'Copay Request Abandoned' = 'COPAY_REQUEST_ABANDONED',
  'Coverage Terminated' = 'INSURANCE_TERMINATED',
  'Maintenance Fills Exceeded' = 'MAIL_ORDER_FILLS',
  'Not covered by insurance' = 'RX_NOT_COVERED',
  'Out of Network' = 'PHARMACY_OUT_OF_NETWORK',
  'Prescription Has No More Refills' = 'RX_NO_REFILLS',
  'Prescription Transferred' = 'RX_TRANSFERRED',
  'Primary Insurance Required' = 'PRIMARY_INSURANCE_REQUIRED',
  'Prior Authorization Required' = 'RX_PA_REQUIRED',
  'Quantity Limited by Insurance' = 'INSURANCE_LTD_QTY',
  'Refill too soon' = 'RX_REFILL_TOO_SOON',
  'Duplicate Request' = 'DUPLICATE_REQUEST',
  'Other' = 'OTHER',
}

export enum AltCopayFillRejectionReason {
  'Copay Request Abandoned' = 'COPAY_REQUEST_ABANDONED',
  'Coverage Terminated' = 'INSURANCE_TERMINATED',
  'Maintenance Fills Exceeded' = 'MAIL_ORDER_FILLS',
  'Maximum benefits reached' = 'MAXIMUM_BENEFITS_REACHED',
  'Not covered by insurance' = 'RX_NOT_COVERED',
  'Out of Network' = 'PHARMACY_OUT_OF_NETWORK',
  'Prescription Has No More Refills' = 'RX_NO_REFILLS',
  'Prescription Transferred' = 'RX_TRANSFERRED',
  'Primary Insurance Required' = 'PRIMARY_INSURANCE_REQUIRED',
  'Quantity Limited by Insurance' = 'INSURANCE_LTD_QTY',
  'Refill too soon' = 'RX_REFILL_TOO_SOON',
  'Other' = 'OTHER',
  'High Copay' = 'HIGH_COPAY',
  'Insurance Issue' = 'INSURANCE_ISSUE',
  'Missing / Invalid Insurance Information' = 'BAD_INSURANCE_DETAILS',
  'Prescription Issue' = 'RX_ISSUE',
  'Prior Authorization Denied' = 'PA_DENIED',
  'Duplicate Request' = 'DUPLICATE_REQUEST',
  'Prior Authorization Required' = 'PA_REQUIRED',
}

export enum CopayRejectionReason {
  'Closed Network / Out of Network' = 'CLOSED_NETWORK',
  'Coverage Terminated' = 'INSURANCE_TERMINATED',
  'Duplicate Request' = 'DUPLICATE_REQUEST',
  'High Copay' = 'HIGH_COPAY',
  'Insurance Issue' = 'INSURANCE_ISSUE',
  'Missing / Invalid Insurance Information' = 'BAD_INSURANCE_DETAILS',
  'Out of Stock' = 'OUT_OF_STOCK',
  'Prescription Issue' = 'RX_ISSUE',
  'Prior Authorization Denied' = 'PA_DENIED',
  'Prior Authorization Required' = 'PA_REQUIRED',
  'Refill Too Soon' = 'REFILL_TOO_SOON',
  'Other' = 'OTHER',
}

export interface User {
  _id: string
  username: string
  firstName: string
  lastName: string
  preferredName: string
  roles: UserRoles[]
  email: string
  countryCode?: string
  mobileNumber?: string
  createdAt: Date
  disabled: boolean
  location: TPOSLocation
  locationId: string
  bin?: string
  addresses: Address[]
  orders: CustomerOrderObject[]
  healthProfile?: HealthProfile
  qr_url?: string
  licenses?: LicenseWithImage[]
}

export interface UserFirstLastName {
  _id: string
  firstName: string
  lastName: string
}

export interface HealthProfile {
  id: string
  dob: string
  sex: string
  type: string
  allergies: string[]
  healthConditions: string[]
  medications: string[]
}

export interface SessionUser {
  _id: string
  username: string
  firstName: string
  lastName: string
  roles: UserRoles[]
  locationId: string
}

export interface InsuranceProviderConfigurationOptions {
  b3?: string
  allowPartialFills?: boolean
  allowPartialFillsCII?: boolean
  allowPartialFillsCIII?: boolean
  allowPartialFillsCIV?: boolean
  allowPartialFillsCV?: boolean
  rxExpiration?: number
  cIIExpiration?: number
  otherCExpiration?: number
  copayMethod?: string
  refillsDue?: number
  taxMethod?: string
  maxClaimsPerTransmit?: number
  minRxPrice?: number
  maxRxPrice?: number
  maxMonthlyFills?: number
  maxRxRefills?: number
  maxCIIIRefills?: number
  maxCIVRefills?: number
  maxCVRefills?: number
  maxMonthlyAmount?: number
  maxDaySupply?: number
}

export interface InsuranceProviderConfigurationPricing {
  claimProcessingFee?: number
  brandDiscount?: number
  brandMaxDiscount?: number
  genericDiscount?: number
  genericMaxDiscount?: number
  compoundDiscount?: number
  compoundMaxDiscount?: number
}

export interface InsuranceProviderConfigurationReconciliation {
  transactionFee?: number
  rxFee?: number
  paidClaimFee?: number
  reversalClaimFee?: number
  automaticallyReconcileClaims?: boolean
  billNoCostSecondaryClaims?: boolean
}

export interface InsuranceProviderConfigurationMinimumProfitRejection {
  percentSubmitted?: number
  percentOverCost?: number
  amountDueOverCost?: number
}

export interface InsuranceProviderConfiguration {
  options?: InsuranceProviderConfigurationOptions
  pricing?: InsuranceProviderConfigurationPricing
  reconciliation?: InsuranceProviderConfigurationReconciliation
  minimumProfitRejection?: InsuranceProviderConfigurationMinimumProfitRejection
}

export interface InsuranceProvider {
  _id: string
  bin: string
  pcn?: string
  name?: string
  helpdeskNumber?: string
  importDate: string
  marketCoverage?: string
  sourceName?: string
  code?: string
  planType?: string
  submissionType?: string
  billingType?: string
  contractExpiration?: string
  status?: string
  stateCode?: string
  phoneNumber?: string
  faxNumber?: string
  priorAuthorizationNumber?: string
  address?: Address
  email?: string
  website?: string
  configurations?: InsuranceProviderConfiguration
}

export interface ManualInsuranceProviderInput {
  bin: string
  pcn?: string
  name?: string
}

export interface Lock {
  _id: string
  user: UserFirstLastName
  userId: string
  expiresAt: number
}

export enum RxChangeCode {
  GenericSubstitution = 'G',
  PriorAuthorizationRequired = 'P',
  TherapeuticInterchangeSubstitution = 'T',
  DrugUseEvaluation = 'D',
  ScriptClarification = 'S',
  PharmacyOutOfStockUntimely = 'OS',
  PrescriberAuthorization = 'U',
}

export enum RxChangeSubCode {
  PrescriberConfirmStateLicenseStatus = 'A',
  PrescriberConfirmDEALicenseStatusInPrescribingState = 'B',
  PrescriberConfirmDEARegistrationByDEAClass = 'C',
  PrescriberConfirmStateControlledSubstanceRegistrationLicenseStatus = 'D',
  PrescriberConfirmRegistrationByStateControlledSubstanceRegistrationClass = 'E',
  PrescriberConfirmNADEANLicenseStatus = 'F',
  PrescriberObtainValidateType1NPI = 'G',
  PrescriberEnrollReEnrollPrescriptionBenefitPlan = 'H',
  PrescriberConfirmPrescriptiveAuthority = 'I',
  PrescriberEnrollReEnrollInREMS = 'J',
  PrescriberConfirmTheirAssignmentAsPatientsLockInPrescriber = 'K',
  PrescriberObtainVALIDATETheirSupervisingPrescriber = 'L',
  PrescriberConfirmTheirCertificateToPrescribeNumberStatus = 'M',
}

export interface CustomerOrderObject {
  address: Address
  order: CustomerOrder
  user: User
}

export interface OrderInvoice {
  invoice: string
}

export interface CustomerOrder {
  products: [OTCProduct]
  cardNumber: string
  createdAt: Date
  discount: number
  shippingMethod: number
  shippingMethodName: number
  shippingPrice: number
  status: string
  taxesPrice: number
  totalPrice: number
  trackingUrl: string
  orderId: string
}

export interface PrintPickSlipHistory {
  userId: string
  userName: string
  createdAt: string
  updatedAt: string
}

export interface PackingMaterialItem {
  _id: string
  sku: string
  quantity: number
  overrideScan?: boolean
}

export interface Order {
  _id: string
  createdAt: Date
  logs: Log[]
  // customer is hydrated from cache which may not be fully loaded yet
  customer?: Customer
  customerId: string
  locationId: string
  notes?: string
  orderNumber: number
  totalNumPrescriptions: number
  patient?: Patient
  patientCount?: number
  guestPatient?: GuestPatient
  rxFillRequests: RXFillRequest[]
  survey: PatientSurveyInput
  coreOrderId: string
  coreOrderToken: string
  customerRef?: string // OTC Order Id
  durInputs: DURSubjectCategories
  inTriage?: OrderTriage
  // location is hydrated from cache which may not be fully loaded yet
  location?: TPOSLocation
  status: OrderStatus
  lock?: Lock
  triages: OrderTriage[]
  otcProducts: OTCProduct[]
  shippingAddress: Address
  shippingMethod: ShippingMethod
  packingMaterials?: PackingMaterialItem[]
  pricing?: {
    tax: number
    total: number
  }
  customerReturns: CustomerReturn[]
  shipments?: Shipment[]
  hasShippingLabel: boolean
  insurances: OrderInsurance[]
  insuranceOptions: Insurance[]
  coreOrder?: {
    patient_payment_type: string
  }
  apiRequest?: {
    medications: Array<{
      prescription_token: string
    }>
    patient_token: string
    patient_first_name: string
    patient_last_name: string
    patient_dob: string
    patient_payment_type: string
    notes: string
  }
  isReplacement?: boolean
  isReservedForInvestigation?: boolean
  paymentType?: PaymentType
  printPickSlipsHistory?: PrintPickSlipHistory[]
  priority?: number
  orderType?: OrderType
}

export interface Copay {
  _id: string
}

export interface PriorAuthorization extends PriorAuthorizationInput {
  _id: string
  copayRequest?: CopayRequest
  createdAt?: Date
  order?: Order
  patient?: Patient
  prescription?: Prescription
  updatedAt?: Date
  location?: TPOSLocation
}

export interface CopayRequest {
  _id: string
  createdAt?: Date
  coreCopayApiRequestToken?: string
  coreCopayId?: number
  coreCopayToken?: string
  initiatePriorAuthorization?: boolean
  customerId: string
  customerRef?: string
  apiRequest?: any
  customerMetaData?: string
  fulfillableByLocationIds: string[]
  shipToState?: string
  locationId: string
  patientId: string
  patient: Patient
  copayRequestFills: CopayRequestFill[]
  status: CopayStatus
  lock?: Lock
  insurances: OrderInsurance[]
  insuranceOptions?: Insurance[]
  error?: string
  rejectionDetails?: RejectionDetails
  notes?: string
  triages: CopayTriage[]
  customer?: Customer
}

export interface OutboundTransfer extends OutboundTransferBase {
  _id: string
  prescription: Prescription
  completedByUser?: User
  location: TPOSLocation
  customer: Customer
}

export type QueueItem = CopayRequest | Order

// TODO - rename fulfilment item?
export type Item = CopayRequest | Order
export type ItemFill = RXFillRequest | CopayRequestFill
export type ItemType = 'order' | 'copay'

export interface CopayTriage {
  _id?: string
  userPlacedInTriage: string
  userRemovedFromTriage?: string
  reason: string
  code: string
  message?: string
  startDate: string
  endDate?: string
  copayRequestFillId?: string
  fillId?: string
  prescriptionId?: string
}

interface ActivePriorAuthorization {
  _id: string
}

export interface CopayRequestFill {
  _id: string
  createdAt?: Date
  prescriptionId: string
  prescription: Prescription
  fillId: string
  fill: Fill
  status: CopayStatus
  copayAmount?: number
  daysSupply?: number
  quantity?: number
  requestedMedicationName?: string
  ndc?: string
  error?: string
  rejectionDetails?: RejectionDetails
  claimRejectCodes?: RejectCode[]
  durs: RxFillRequestDur[]
  overrideCodes: RxFillRequestOverrideCodes[]
  ediOverrides: EdiOverrides[]
  ediReversalOverrides: EdiReversalOverrides[]
  cobOverrides: RxFillRequestCOBOverride[]
  adjudication?: RxFillRequestAdjudication
  dispensed: Dispensed
  shipToState?: string
  activePriorAuthorization?: ActivePriorAuthorization
}

export interface RejectCode {
  rejectCode: string
  rejectMessage: string
}

export interface OrderQueryResult {
  order: Partial<Order>
}

export interface CopayQueryResult {
  copayRequest: Partial<CopayRequest>
}

export interface CustomerReturn {
  _id: string
  otcProducts: {
    _id: string
    quantity: number
    sku: string
    otcProductId: OTCProduct['_id']
  }[]
  reason: string
  action: string
  status: string
  shippingCharge: string
  carrierTrackingNumber?: string
  shippingPayment?: string
  shippingCostRefunded?: number
  returnedOrderId?: string
}

export interface GuestPatient {
  _id: string
  firstName: string
  lastName: string
  contacts?: {
    phone?: string
    email?: string
    sms?: string
  }
}

export interface Shipment {
  trackingNumber: string
}

export interface OTCProduct {
  _id: string
  name?: string
  sku: string
  quantity: number
  daysSupply?: number
  bundleId?: string
  barcode?: string
  image?: TPOSDocument
  quantityShipped?: number
  bundleItemPartialReturnAllowed?: boolean
  otcStatus: string
  pricing: {
    cost: number
    tax: number
    total: number
    discount: number
    shippingCost?: number
  }
  trackingNumber?: string
  trackingUrl?: string
  upc?: string
  overrideScan?: boolean
  rxFillRequestId?: string
}

export interface SkuEntry {
  _id: string
  sku: string
}

export interface RxChangeTriage {
  reason: string
  details?: string[]
  ndc?: string
  sendToSurescripts?: boolean
}

export interface TriageDetails {
  dateOkToFill?: Date
}

export interface OrderTriage {
  _id?: string
  details?: TriageDetails
  userPlacedInTriage?: string
  userRemovedFromTriage?: string
  userPlaced?: User
  userRemoved?: User
  reason: TriageReasons
  duplicateOrderId?: string
  inTriageUserMessage?: string
  removeFromTriageUserMessage?: string
  startDate: string
  endDate?: string
  rxFillRequestId: string
  fillId?: string
  prescriptionId?: string
  rxChange?: RxChangeTriage
}

export type Tag = string

export interface Message {
  _id: string
  createdAt: Date
  message: string
  user: User
  tags: Tag[]
}

export interface Note {
  _id: string
  createdAt: string
  message: string
  author: string
  user: UserFirstLastName
  tags: Tag[]
  userId: string
}

export enum NoteSpecialTags {
  EXTERNAL = 'External',
  CRITICAL = 'Critical',
  ENCOUNTER = 'Encounter',
}

export interface LogEntry {
  _id: string
  userName: string
  createdAt: Date
  message: string
  tag: string
  dataDump?: SimpleKV
}

export interface NdcPackage {
  _id: string
  ndcProduct: NdcProduct
  ndc: string
  description: string
  raw: {
    NDCPACKAGECODE: string
  }
}

export interface NdcProduct {
  _id: string
  ndc: string
  productType: string
  classes: string[]
  dosages: string[]
  alternativeNames: string[]
  substance: string[]
  labeler: string
  name: string
  applicationNumber: string
  strengthUnits: string[]
  deaSchedule: string
  certificationExpiration: string
  ndcPackages: NdcPackage[]
}

export interface NdcSubstitutionPackage {
  ndc: string
  acquisitionCost?: number
  preferred?: boolean
  drugName: string
  dosageForm: string
  strength: string
  strengthUnitOfMeasure: string
  drugDescriptorId: number
  teCode: string
  labeler: string
  gppc: string
  packageSize: number
  packageSizeUnitOfMeasure: string
  packageQuantity: number
  unitDose?: string
  packageDescriptionCode: string
  gpi: string
  transactionCode?: string
  balanceOnHand?: number
  ffsInventoryInfo?: FfsInventoryInfo
}

export interface NdcFullInfoPackage {
  ndc: string
  oldNdc?: string
  teCode: string
  drugName: string
  gppc: string
  drugDescriptorId: number
  packageSize: number
  packageSizeUnitOfMeasure: string
  packageQuantity: number
  unitDose?: string
  packageDescriptionCode: string
  packageDescription: string
  gpi: string
  deaCode?: string
  desiCode?: string
  rxOtcIndicatorCode?: string
  repackageCode?: string
  format: string
  kbDrugCode: number
  kdcFlag: string
  mediSpanLabelerId: number
  multiSourceCode?: string
  nameTypeCode?: string
  itemStatusFlag: string
  innerpackCode: string
  clinicPackCode?: string
  ppgIndicatorCode?: string
  hfpgIndicatorCode?: string
  dispensingUnitCode?: string
  dollarRankCode?: string
  rxRankCode?: string
  storageConditionCode: string
  limitedDistributionCode?: string
  oldEffectiveDate?: string
  newEffectiveDate?: string
  nextSmallerNdcSuffixNumber?: string
  nextLargerNdcSuffixNumber?: string
  balanceOnHand?: number
  inventories?: NdcInventory[]
  isUnbreakable?: boolean
  hasInnerPackSibling?: boolean
}

export interface NdcFullInfoPackageWithUnbrekableInfo extends NdcFullInfoPackage {
  isUnbreakable: boolean
}

export interface NdcPartialInfoPackage {
  drugName: string
  ndc: string
  packageSizeUnitOfMeasure: string
  packageQuantity: number
  unitDose?: string
  packageDescriptionCode: string
  packageDescription: string
  gpi: string
  deaCode?: string
}

export interface NdcSubstitutionPackagePreferred extends NdcSubstitutionPackage {
  consigned?: boolean
  preferred?: boolean
  availableBalance: number
  balanceOnHand: number
  inventoryGroup: InventoryGroup
  balanceInFlight: number
  ffsInventoryInfo?: FfsInventoryInfo
}

export interface FormularyResult {
  onFormulary?: boolean
  consigned?: boolean
  preferred?: boolean
  acquisitionCost?: number
}

export interface NdcSubstitutionPreferred {
  ndcs: NdcSubstitutionPackagePreferred[]
  gpi: string
  name: string
  formularyStatus: boolean
}

export interface MedispanNdcLabeler {
  labelerId: number
  name: string
  abbreviatedName: string
}

export interface NdcFullInfo {
  ndc: string
  drugName: string
  dosageForm: string
  dosageFormDescription: string
  genericFor: string
  route: string
  strength: string
  strengthUnitOfMeasure: string
  packages: [NdcFullInfoPackage]
  labeler: MedispanNdcLabeler
  patientInformation?: Record<string, string>
  isSpecialty?: boolean
  legendIndicatorCode: string
  source?: string
}

export interface NdcPartialInfo {
  ndc: string
  drugName: string
  dosageForm: string
  dosageFormDescription: string
  genericFor: string
  route: string
  strength: string
  strengthUnitOfMeasure: string
  packages: [NdcPartialInfoPackage]
  labeler: MedispanNdcLabeler
}

export interface GroupedNdcInfo {
  ndc: string
  drugName: string
  dosageForm: string
  strength: string
  strengthUnitOfMeasure: string
  routeOfAdministration: string
}

export interface NdcInventory {
  locationId: string
  balanceOnHand: number
  updatedAt: Date
}

export enum FillWorkStatus {
  normal = 'NORMAL',
  overdue = 'OVERDUE',
  nearOverdue = 'NEAR_OVERDUE',
}

export enum FillState {
  triage = 'triage',
  print = 'print',
  ship = 'ship',
  rejected = 'rejected',
}

export interface Prescriber extends PrescriberIdentification {
  _id: string
  firstName: string
  lastName: string
  suffix?: string
  addresses: PrescriberAddress[]
  selectedAddress?: PrescriberAddress
  contacts?: {
    phone: string
    fax?: string
    email?: string
  }
  customer?: string
  primaryPhoneSequenceNumber: string
  prescriberTypeID?: string
  prescriberTypeText?: string
  classificationID: string
  classificationText: string
  primarySpecializationID: string
  primarySpecializationText: string
  isSupervisor: string
  comments: Comments
  marketerEmployeePioneerRxID: string
  changedOnUTCDateTime: Date
  createdAt?: Date
  npiDoc?: {
    gender?: string
    credential?: string
    deactivationDate?: string
  }
}

export interface PracticeAddressPhone {
  address: Address
  phone: string
}

export interface Comments {
  informational: string
  critical: string
  pointOfSale: string
}

export interface PrescriberIdentification {
  prescriberPioneerRxID: string
  npi: string
  dea: string
  dps: string
  stateLicense: string
  serialNumberPerson: string
}

export interface Medication {
  date: string
  daysSupply: number
  directions: string
  gcn?: string
  ndc: string
  prn?: string
  quantity: number
  upc?: string
}

export interface PrescribedMedication extends Medication {
  description: string
  daw: number
  numberOfRefills: number
  writtenDate: string
  strength: MedicationStrength
  dosageForm: string
  deaSchedule: string
  effectiveDate: string
}

declare type RxFillRequestPaymentType = 'cash' | 'insurance'

export type RejectionDetails = {
  reason: string
  reasonCode: OrderRejectionReason | LegacyOrderRejectionReason | CopayFillRejectionReason | AltCopayFillRejectionReason
  message?: string
}

export interface RxFillRequestDur {
  orderInsuranceOptionId: string
  levelOfEffort?: string
  professionalService?: string
  reasonForService?: string
  resultOfService?: string
  coAgentQualifier?: string
  coAgentId?: string
}

export interface RxFillRequestCOBOverride {
  orderInsuranceOptionId: string
  otherPayerId?: StringOverrideCOB
  otherPayerDate?: DateOverrideCOB
  otherPayerAmountPaid?: NumericArrayOverrideCOB
  otherPayerRejectCode?: StringArrayOverrideCOB
  otherPayerPatientResponsibilityAmount?: NumericArrayOverrideCOB
  benefitStageAmount?: NumericArrayOverrideCOB
}

export interface NumericOverrideCode {
  value: number
  applyTo: string
}

export interface NumericArrayOverrideCode {
  value: number[]
  applyTo: string
}

export interface AmountOverrideCOB {
  qualifier: string
  amount: number
}

export interface NumericArrayOverrideCOB {
  applyTo?: string
  value?: AmountOverrideCOB[]
  send?: boolean
}

export interface StringOverrideCOB {
  qualifier: string
  applyTo: string
  value: string
  send?: boolean
}

export interface StringArrayOverrideCOB {
  applyTo: string
  value: string[]
  send?: boolean
}

export interface DateOverrideCOB {
  applyTo: string
  value: Date
  send?: boolean
}

export interface StringOverrideCode {
  value: string
  applyTo: string
  send?: boolean
}

export interface RxFillRequestOverrideCodes {
  orderInsuranceOptionId: string
  priorAuthorizationTypeCode?: NumericOverrideCode
  levelOfService?: NumericOverrideCode
  productIdQualifier?: StringOverrideCode
  submissionClarification?: NumericArrayOverrideCode
  otherCoverageCode?: NumericOverrideCode
  priorAuthorizationNumberSubmitted?: string
}

export enum RxFillRequestOverridesKeys {
  Header = 'header',
  Pricing = 'pricing',
  Insurance = 'insurance',
  Patient = 'patient',
  Claim = 'claim',
  PharmacyProvider = 'pharmacyProvider',
  Prescriber = 'prescriber',
  Facility = 'facility',
  Clinical = 'clinical',
  AdditionalDocumentation = 'additionalDocumentation',
  Narrative = 'narrative',
}

export enum RxFillRequestReversalOverridesKeys {
  Header = 'header',
  Pricing = 'pricing',
  Insurance = 'insurance',
  Claim = 'claim',
}

export interface EdiOverridesHeader {
  dateOfService?: string
  softwareVendorId?: string
  serviceProviderId?: string
}

export interface EdiOverridesPricing {
  ingredientCostSubmitted?: number
  dispensingFeeSubmitted?: number
  usualAndCustomaryCharge?: number
  grossAmountDue?: number
  basisOfCostDetermination?: string
  flatSalesTax?: number
  patientPayAmount?: number
  percentageSalesTaxAmount?: number
  percentageSalesTaxRate?: number
  percentageSalesTaxBasis?: string
}

export interface EdiOverridesInsurance {
  firstName?: string
  lastName?: string
  homePlan?: string
  planId?: string
  eligibilityClarification?: string
}

export interface EdiOverridesPatient {
  residence?: string
  id?: string
}

export interface EdiOverridesClaim {
  scheduledPrescriptionIdNumber?: string
  intermediaryAuthorizationTypeId?: string
  intermediaryAuthorizationId?: string
  pharmacyServiceType?: string
  daysSupply?: number
}

export interface EdiOverridesPharmacyProvider {
  medicaidId?: string
}

export interface EdiOverridesPrescriber {
  primaryCareProviderIdQualifier?: string
  primaryCareProviderId?: string
  primaryCareProviderLastName?: string
}

export interface EdiOverridesFacility {
  facilityId?: string
  facilityName?: string
  facilityStreetAddress?: string
  facilityCityAddress?: string
  facilityStateProvinceAddress?: string
  facilityZipPostalCode?: string
}

export interface EdiOverridesClinical {
  diagnosisCode?: string
  diagnosisCodeQualifier?: string
  clinicalInformationCounter?: number
  measurementDate?: string
  measurementTime?: string
  measurementDimension?: string
  measurementUnit?: string
  measurementValue?: string
}

export interface EdiOverridesAdditionalDocumentation {
  additionalDocumentationTypeID?: string
  requestPeriodBeginDate?: string
  requestStatus?: string
  lengthOfNeedQualifier?: string
  lengthOfNeed?: string
  prescriberDateSigned?: string
  requestPeriodRecertificationDate?: string
  supportingDocumentation?: string
}

export interface EdiOverridesNarrative {
  narrativeMessage?: string
}

export interface EdiOverridesCOB {
  internalControlNumber?: string
  otherPayerCoverageType?: string
  otherPaymentsCount?: number
}

export interface EdiOverrides {
  orderInsuranceOptionId?: string
  header?: EdiOverridesHeader
  pricing?: EdiOverridesPricing
  insurance?: EdiOverridesInsurance
  patient?: EdiOverridesPatient
  claim?: EdiOverridesClaim
  pharmacyProvider?: EdiOverridesPharmacyProvider
  prescriber?: EdiOverridesPrescriber
  facility?: EdiOverridesFacility
  clinical?: EdiOverridesClinical
  additionalDocumentation?: EdiOverridesAdditionalDocumentation
  narrative?: EdiOverridesNarrative
  coordinationOfBenefits?: EdiOverridesCOB
}

export interface ToggleableValue<T = string> {
  value: T
  send: boolean
}

export interface EdiReversalOverridesHeader {
  serviceProviderId?: ToggleableValue
  bin?: ToggleableValue
  processorControlNumber?: ToggleableValue
  dateOfService?: ToggleableValue
  softwareVendorId?: ToggleableValue
}

export interface EdiReversalOverridesInsurance {
  cardholderId?: ToggleableValue
  groupId?: ToggleableValue
  medigapId?: ToggleableValue
}

export interface EdiReversalOverridesClaim {
  prescriptionRefNumber?: ToggleableValue
  productId?: ToggleableValue
  fillNumber?: ToggleableValue<number>
  otherCoverageCode?: ToggleableValue<number>
  pharmacyServiceType?: ToggleableValue
}

export interface EdiReversalOverridesPricing {
  incentiveAmountSubmitted?: ToggleableValue<number>
  grossAmountDue?: ToggleableValue<number>
}

export interface EdiReversalOverridesCOB {
  otherPayerCoverageType?: ToggleableValue
}

export interface EdiReversalOverrides {
  orderInsuranceOptionId?: string
  header?: EdiReversalOverridesHeader
  insurance?: EdiReversalOverridesInsurance
  claim?: EdiReversalOverridesClaim
  pricing?: EdiReversalOverridesPricing
  coordinationOfBenefits?: EdiReversalOverridesCOB
}

export interface RxFillRequestAdjudication {
  lastClaimRejectCodes: string[]
  runSuccessful: boolean
  claimJumperErrors: string[]
  claimRunDate: Date
}

export interface RXFillRequest {
  _id: string
  prescriptionId: string
  prescription: Prescription
  copayPayment: CopayPayments
  fillId: string
  fill: Fill
  patientId: string
  patient: Patient
  status: RxFillRequestStatus
  rejectionDetails?: RejectionDetails
  requestedMedicationName?: string
  requestedMedication?: RequestedMedication
  quantity: number
  paymentType: RxFillRequestPaymentType
  coverMyMedsKey: string
  durs: RxFillRequestDur[]
  overrideCodes: RxFillRequestOverrideCodes[]
  ediOverrides: EdiOverrides[]
  ediReversalOverrides: EdiReversalOverrides[]
  cobOverrides: RxFillRequestCOBOverride[]
  machine: MachineOptions
  adjudication?: RxFillRequestAdjudication
  isReAttempt?: IsReAttemptType
  specialHandlingTags?: RxFillRequestSpecialHandlingTag[]
  otcProductId?: string
  activePriorAuthorization?: ActivePriorAuthorization
}

export interface IsReAttemptType {
  previousStatus: string
  reason: string
  message: string
}

export interface transmissionSendHeader {
  bin: string
  version: string
  transactionCode: string
  processorControlNumber: string
  transactionCount: number
  serviceProviderIdQualifier: string
  serviceProviderId: string
  dateOfService: Date
  softwareVendorId: string
}

export enum FieldCodesOtherAmount {
  Delivery = '01',
  Shipping = '02',
  Postage = '03',
  Administrative = '04',
  CompoundPreparation = '09',
  MedicationAdministration = '11',
}

export enum FieldCodesBasisOfCostDetermination {
  Default = '00',
  AverageWholesalePrice = '01',
  LocalWholesaler = '02',
  Direct = '03', // Manufacturer's Published Non Wholesale Price
  EstimatedAquisitionCost = '04',
  Aquisition = '05',
  MaximumAllowableCost = '06',
  UsualAndCustomary = '07',
  _340B = '08',
  Other = '09',
  AverageSalesPrice = '10',
  AverageManufacturerPrice = '11',
  WholesaleAquisitionCost = '12',
  SpecialPatientPricing = '13',
  UnreportableQuantities = '14',
  FreeProduct = '15',
}

export interface PricingSpec {
  ingredientCostSubmitted: number
  dispensingFeeSubmitted?: number
  patientPayAmountSubmitted?: number
  incentiveAmountSubmitted?: number
  otherAmountClaims?: {
    otherAmountClaimSubmittedQualifier: FieldCodesOtherAmount
    otherAmountClaimSubmitted: number
  }[]
  usualAndCustomaryCharge?: number
  grossAmountDue: number
  basisOfCostDetermination?: FieldCodesBasisOfCostDetermination
  flatSalesTaxSubmitted?: number
}

export enum MessageType {
  SENT,
  RECEIVED,
}

export interface ClaimsLog {
  _id: string
  event: string
  createdAt: string
  message?: ClaimsSent | ClaimsReceived | string
  log?: ClaimsSent | ClaimsReceived
}

export interface ClaimsRequest {
  orderId: string
  fillId: string
  originalClaimsRequest?: EdiOverrides
  overrides?: EdiOverrides
  orderInsurances?: OrderInsurance[]
  patientInsurances?: Insurance[]
  claimsSubmissionResponse?: ClaimsSubmissionResponse
  claimsSubmitting?: boolean
}

export interface ClaimsSubmissionResponse {
  rejectionMessages: ClaimEdiReceivedRejectCode[]
  additionalInfo: string
  nextAvailableFillDate?: string
  successfulClaim: boolean
  paymentResponse?: ClaimsGroupPricingResponse
}

export interface ClaimsGroupClaim {
  prescriptionRefNumberQualifier: string
  prescriptionRefNumber: string
  productIdQualifier: string
  productId: string
  quantityDispensed: number
  fillNumber: number
  daysSupply: number
  compoundCode: number
  dawCode: number
  datePrescriptionWritten: Date
  numberOfRefillsAuthorized: number
  prescriptionOriginCode: string
  otherCoverageCode: string
  specialPackagingIndicator: string
  pharmacyServiceType: string
}

export interface ClaimsGroupPricing {
  ingredientCostSubmitted: number
  dispensingFeeSubmitted: number
  patientPayAmountSubmitted: number
  usualAndCustomaryCharge: number
  grossAmountDue: number
  basisOfCostDetermination: string
}

export interface ClaimsGroupPricingResponse {
  ingredientCostPaid?: number
  dispensingFeePaid?: number
  patientPayAmount?: number
  basisOfReimbursementDetermination?: string
  totalAmountPaid?: number
}

export interface ClaimsGroupPrescriber {
  prescriberIdQualifier: string
  prescriberId: string
  lastName: string
  state: string
}

export interface ClaimsGroup {
  claim: ClaimsGroupClaim
  pricing: ClaimsGroupPricing
  prescriber: ClaimsGroupPrescriber
}

export interface ClaimsSent extends TypeName {
  edi: string
  header: {
    bin: string
    version: string
    transactionCode: string
    processorControlNumber: string
    transactionCount: number
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
    softwareVendorId: string
  }
  metadata: {
    insurance: {
      cardholderId: string
      eligibilityClarificationCode: string
      groupId: string
      personCode: string
      patientRelationshipCode: string
    }
    patient: {
      dateOfBirth: string
      genderCode: string
      firstName: string
      lastName: string
      streetAddress: string
      city: string
      state: string
      zip: string
      placeOfService: string
      pregnancyIndicator: string
      residence: string
    }
  }
  groups: ClaimsGroup[]
}

interface TypeName {
  __typename: string
}

export interface ClaimsReceived extends TypeName {
  edi: string
  header: {
    bin: string
    version: string
    transactionCode: string
    processorControlNumber: string
    transactionCount: number
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
    softwareVendorId: string
  }
  metadata: {
    responseMessage: {
      message: string
    }
    patientResponse: {
      dateOfBirth: Date
    }
    insuranceResponse: {
      groupId: string
      networkReimbustmentId: string
    }
  }
  groups: [
    {
      claimResponse: {
        prescriptionRefNumberQualifier: string
        prescriptionRefNumber: string
      }
      pricingResponse: {
        patientPayAmount: number
        ingredientCostPaid: number
        dispensingFeePaid: number
        totalAmountPaid: number
        accumulatedDeductibleAmount: number
        basisOfReimbusementDetermination: string
      }
      responseStatus: {
        statusText: string
      }
      response: {
        transactionStatus: string
        authorizationNumber: string
        rejectCodes: ClaimEdiReceivedRejectCode[]
        additionalMessageInformation: string
      }
    },
  ]
}

interface EligibilityCheckReceivedGroupResponseRejectCode {
  rejectCode: string
  rejectCodeTranslated: string
}

export interface EligibilityCheckCoordinationOfBenefitsResponse {
  otherPayerIdCount?: string
  otherPayerCoverageType?: string
  otherPayerIdQualifier?: string
  otherPayerId?: string
  otherPayerProcessorControlNumber?: string
  otherPayerCardholderId?: string
  otherPayerGroupId?: string
  otherPayerPersonCode?: string
  otherPayerPatientRelationshipCode?: string
  otherPayerHelpDeskPhoneNumber?: string
  otherPayerBenefitEffectiveDate?: string
  otherPayerBenefitTerminationDate?: string
}

export interface EligibilityCheckReceived {
  edi: string
  header: {
    version: string
    transactionCode: string
    transactionCount: number
    responseStatus: string
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
  }
  metadata: {
    responseMessage: {
      message: string
    }
  }
  groups: {
    response: {
      transactionStatus: string
      authorizationNumber?: string
      rejectCodes?: EligibilityCheckReceivedGroupResponseRejectCode
      additionalMessageInformation?: string
      url?: string
    }
    coordinationOfBenefitsResponse: EligibilityCheckCoordinationOfBenefitsResponse
  }[]
}

export interface EligibilityCheckSent {
  edi: string
  header: {
    bin: string
    version: string
    transactionCode: string
    processorControlNumber: string
    transactionCount: number
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
    softwareVendorId: string
  }
  metadata: {
    insurance: {
      cardholderId: string
    }
  }
}

export interface FillEdiPricing {
  ingredientCostSubmitted: number
  dispensingFeeSubmitted?: number
  patientPayAmountSubmitted?: number
  incentiveAmountSubmitted?: number
  otherAmountClaims?: {
    otherAmountClaimSubmittedQualifier: string
    otherAmountClaimSubmitted: number
  }[]
  usualAndCustomaryCharge?: number
  grossAmountDue: number
  basisOfCostDetermination?: string
  flatSalesTaxSubmitted?: number
}

export interface Verification {
  pharmacistId: string
  verifiedAt?: Date
}

export interface Verifications {
  pv1?: Verification & { skipped?: boolean }
  transferOut?: Verification
}

export type DUR = Medispan.DUR
export type DURSubject = Medispan.DURSubject
export type DURRecord = FillDUR

export interface Claim extends Omit<ClaimData, '_id'> {
  _id: string
}

export interface Fill extends Omit<FillData, '_id' | 'locationId' | 'dispensed'> {
  _id: string
  alternateLabelName?: string
  claims: Claim[]
  dispensed: Dispensed & { inventoryGroup?: InventoryGroup; alternateLabelName?: string }
  dispensedAt: Date
  ediPricing: FillEdiPricing
  handlingTags: HandlingTags
  location: TPOSLocation
  locationId: string
  order?: Order
  transferImage?: TPOSDocument
  triages: Triage[]
  genericFor?: string
}

export interface FillDirectionsDetails {
  tooLong: boolean
  maxLength: number
  cleanedText: string
}

export interface Triage {
  userPlaced: User
  userRemoved: User
  reason: string
  inTriageUserMessage: string
  removeFromTriageUserMessage: string
  startDate: string
  endDate: string
}

export interface Vials {
  image: string
  number: number
}

export interface MachineOptions {
  deviceName: string
  error: string
  lastActivity: string
  rxId: string
  status: MachineStatus
  updatedAt: Date
  vials: Vials[]
  isDeferred: boolean
}

export enum MachineStatus {
  waitingToBeSent = 'waitingToBeSent',
  Error = 'Error',
  Completed = 'Completed',
  Started = 'Started',
  Queued = 'Queued',
  ScannedOut = 'ScannedOut',
  Canceled = 'Canceled',
  NotFound = 'NotFound',
}

export interface FillLabelPrinted {
  userId: string
  date: string
  isReprint: boolean
  reason?: string
}

export enum InsuranceDesignationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Coupon = 'Coupon',
  Tertiary = 'Tertiary',
}

export interface InsuranceDur {
  orderInsuranceOptionId: string
  reasonForService?: string
  professionalService?: string
  resultOfService?: string
  levelOfEffort?: string
  coAgentQualifier?: string
  coAgentId?: string
}

export interface InsuranceOverrideCodes {
  orderInsuranceOptionId: string
  priorAuthorizationTypeCode?: NumericOverrideCode
  levelOfService?: NumericOverrideCode
  productIdQualifier?: StringOverrideCode
  submissionClarification?: NumericArrayOverrideCode
  otherCoverageCode?: NumericOverrideCode
  priorAuthorizationNumberSubmitted?: string
}

// Check Duplicate type
export interface InsuranceOverrideCob {
  orderInsuranceOptionId?: string
  otherPayerId: StringOverrideCOB
  otherPayerDate: DateOverrideCOB
  otherPayerAmountPaid: NumericArrayOverrideCOB
  otherPayerRejectCode: StringArrayOverrideCOB
  otherPayerPatientResponsibilityAmount?: NumericArrayOverrideCOB
  benefitStageAmount?: NumericArrayOverrideCOB
}

export interface InsuranceImages {
  front: string
  back: string
}

export interface InsurancePayer {
  bin?: string
  pcn?: string
  group?: string
  cardholderId: string
  name?: string
  helpdeskNumber?: string
  personCode?: string
  relationship?: string
  durs?: InsuranceDur[]
  cardType?: InsuranceCardType
  orderInsuranceOptionId?: string
  coreInsuranceToken?: string
}

export interface InsuranceValues {
  bin?: string
  pcn?: string
  group?: string
  cardholderId: string
  name?: string
  helpdeskNumber?: string
  personCode?: string
  relationship?: string
  designation?: InsuranceDesignationType
  overrideCodes?: RxFillRequestOverrideCodes
  durs?: InsuranceDur[]
  governmentSponsoredPlan?: boolean
  images?: InsuranceImages
  cobOverrides?: RxFillRequestCOBOverride
  cardType?: InsuranceCardType
  coreInsuranceToken?: string
}

export interface Insurance extends InsuranceValues {
  _id: string
}

export interface OrderInsurance extends Insurance {
  orderInsuranceOptionId: string
}

export interface InsuranceInput extends InsuranceValues {
  _id?: string
}

export interface Escript {
  _id: string
  ndc: string
  medicationPrescribed: EscriptMedicationPrescribed
  patient: EscriptPatient
  prescriber: EscriptPrescriber
  supervisor: EscriptSupervisor
  clinicName: string
  raw: string
  createdAt: Date
  customerId?: string
  externalId?: string
  rerouted?: boolean
  inTriage?: boolean
  prescriberOrderNumber?: string
  prescriptionId?: string
  prescription?: Prescription
  triageReason: EscriptTriageReasons
  validationIssues?: EscriptValidationIssue[]
  status?: string
  sender?: string
}

export interface EscriptPatient {
  firstName: string
  lastName: string
  dob: Date
  gender: string
  address: Address
  communicationNumbers: CommunicationNumbers
}

export interface EscriptMedicationPrescribedOtherMedicationDate {
  otherMedicationDate: {
    dateTime: Date
  }
  otherMedicationDateQualifier: string
}

export interface EscriptMedicationPrescribed {
  drugDescription: string
  quantity: {
    value: number
    quantityUnitOfMeasure: {
      code: string
    }
  }
  sig: string
  diagnosis: {
    primary: {
      description: string
    }
  }
  drugCoded: {
    productCode: {
      code: string
    }
  }
  writtenDate: Date
  substitutions: string
  daysSupply: number
  numberOfRefills: number
  note: string
  otherMedicationDate: EscriptMedicationPrescribedOtherMedicationDate[]
}

export interface EscriptSupervisor {
  firstName: string
  lastName: string
  suffix?: string
}

export interface EscriptPrescriber {
  _id: string
  firstName: string
  lastName: string
  suffix: string
  stateLicenseNumber: string
  npi: string
  dob: Date
  deaNumber: string
  gender: string
  address: Address
  communicationNumbers: CommunicationNumbers
}

export interface EscriptValidationIssue {
  validationIssueTypeId?: string
  resolutionLogId?: string
  identifier: string
}

export interface EscriptValidationIssueType {
  _id: string
  identifier: string
  priority: number
  name: string
  description: string
  type: string
}

interface CommunicationNumbersFax {
  number: string
  createdAt?: Date
  updatedAt?: Date
}

interface CommunicationNumbers {
  phone: {
    number: string
  }
  fax?: CommunicationNumbersFax[]
  email?: string
}

export enum TransferType {
  TRANSFER_OUT = 'out',
  TRANSFER_IN = 'in',
}

export interface Transfer {
  transferredByUser: User
  date: string
  pharmacy: string
  address: Address
  phone: string
  fax: string
  dea: string
  pharmacistName: string
  pharmacistLastName?: string
  transferringPharmacistName?: string
  pharmacyNpi?: string
  faxInfo: TransferFaxInfo
  transferImage: TPOSDocument
  fillId?: string
  transferType: TransferType
  originalRefills?: number
  refillsRemaining?: number
  firstFillDate?: string
  lastFillDate?: string
  originalRxNumber?: number
  quantityRemaining?: number
  priorAuthorization?: PriorAuthorizationV2
}

export interface TransferRequestFormData {
  pharmacy?: Pharmacy
  faxNumber?: string
  prescriberId?: Prescriber['_id']
  transferTarget?: 'pharmacy' | 'prescriber'
  contactMethod?: 'electronic' | 'fax'
  spi?: string
  medication?: string
}

export interface TransferOutFormData {
  faxNumber: string
  pharmacy: Pharmacy
  transferringPharmacistName: string
  pharmacistName: string
  comments?: string
}

export interface TransferToPioneerFormData {
  pharmacyNpi: string
  quantity: SurescriptsNumber
  comments?: string
}

export interface RequestRefillFormData {
  faxNumber?: string
  prescriber?: Prescriber
  contactMethod?: 'electronic' | 'fax'
  spi?: string
  medication?: Medication['ndc']
}

export interface PharmacyFormData {
  pharmacy?: Pharmacy
  faxNumber?: string
  pharmacistName?: string
  transferringPharmacistName?: string
  contactMethod?: 'electronic' | 'fax'
  spi?: string
  comments?: string
}

interface TransferFaxInfo {
  status: string
  sid: string
  error: string
  errorCode: string
}

export interface Icd10 {
  _id: string
  code: string
  text: string
}

export interface RefillRequest {
  rxRenewalRequestId: string
  status: RefillResponseStatus | RefillFaxResponseStatus
  delivered?: boolean
  prescriptionId?: string
  responses?: [
    {
      rxRenewalResponseId?: string
      surescriptsStatusMessageId?: string
      surescriptsVerifyMessageId?: string
      surescriptsErrorMessageId?: string
      message: string
      receivedAt: Date
    },
  ]
  createdAt: Date
  updatedAt: Date
}

export enum RefillResponseStatus {
  Pending = 'Pending', // 'Pending' is internal status for vision, the remaining are NCPDP/Surescripts
  Error = 'Error', // 'Error' is internal status for vision
  Approved = 'Approved',
  ApprovedWithChanges = 'ApprovedWithChanges',
  Replace = 'Replace',
  DeniedNewPrescriptionToFollow = 'DeniedNewPrescriptionToFollow',
  Denied = 'Denied',
}

export enum RefillFaxResponseStatus {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  DELIVERED = 'DELIVERED',
}

export enum PrescriptionOrigin {
  WRITTEN = 'written',
  TELEPHONE = 'telephone',
  ELECTRONIC = 'electronic',
  FAX = 'fax',
  PHARMACY = 'pharmacy',
}

export enum PrescriptionOriginRename {
  PHARMACY = 'transfer',
}

export interface Prescription {
  // TruepillOS
  // Unsure
  _id: string
  annotations?: Log[]
  lastEquivalentDrugDispensedInfo: { nextFillInfo: NextFillInfo; dispensed: Dispensed }
  nextFillInfo: NextFillInfo
  newAnnotations?: Log[]
  logs: Log[]
  customer?: Customer
  customerId: string
  daw: number
  daysSupply: number
  deaSchedule: string
  description: string
  directions: string
  discontinuedDetails: [RxDiscontinuedDetails]
  dosageForm: string
  escript: Escript
  externalId: string
  expirationDate: string
  isExpired: boolean
  effectiveDate?: string
  fills: FillWithGpi[]
  gcn?: string
  gpi?: string
  icd10?: string[]
  icd10s?: Icd10[]
  isControlledSubstance: boolean
  isPioneerOwned: boolean
  location: TPOSLocation
  locationId: string
  ndc: string
  nextFillNumber: number
  note?: string
  numberOfRefills: number
  refillRequests?: [RefillRequest]
  origin: PrescriptionOrigin
  patient: Patient
  patientId: Patient['_id']
  prescriber: Prescriber
  prescriberId: Prescriber['_id']
  supervisingPrescriber: Prescriber
  supervisingPrescriberId: Prescriber['_id']
  prescriberAddressId?: string
  prohibitRenewalRequest?: boolean
  followUpPrescriberId?: Prescriber['_id']
  followUpPrescriber?: Prescriber
  prn?: boolean
  quantity: SurescriptsNumber
  quantityRemaining: number
  refillsRemaining?: number
  rxImage: TPOSDocument
  rxImageId: string
  rxNumber: number
  status: RxStatus
  strength?: MedicationStrength
  totalQuantity: number
  transfers: Transfer[]
  transferredOut?: boolean
  triplicate?: string
  upc?: string
  verifications?: Verifications
  writtenDate: string
  name: string
  createdAt: Date
  handlingTags: HandlingTags
  directTransferId?: string
  directTransfer?: DirectTransfer
  calledInBy?: string
  author?: User
  authorId?: string
  corePrescriptionToken?: string
}

export interface RxDiscontinuedDetails {
  userId: string
  reason: RxDiscontinueReason
  createdAt: Date
  note?: string
}

export interface DrugICD10 {
  code: string
  main: string
  text: string
}

export type DocumentsResponse = {
  _id: string
  createdAt: string
  caption: string
  tag: string
  s3SignedUrl: string
}

export interface TPOSDocument {
  _id: string
  s3: S3Document
  s3SignedUrl: string
  uploadedBy: UploadedBy
  createdAt: Date
  updatedAt: Date
  caption: string
  tag: string
  isArchived: boolean
  fill?: string
  prescription?: string
  patient?: string
  orderId?: string
}

export interface FillWithGpi extends Fill {
  gpi: string
}

export interface PrescriptionPartial extends Prescription {
  fills: FillWithGpi[]
}

export interface S3Document {
  key: string
  bucket: string
}

export interface UploadedBy {
  user: User
  service: string
}

export enum PaymentType {
  cash = 'cash',
  insurance = 'insurance',
  split = 'split',
}

export enum PaymentMethod {
  cash = 'cash',
  copay = 'copay',
}

export interface Log {
  _id: string
  isAnnotation: boolean
  userId: string
  user: UserFirstLastName
  patient?: Patient
  patientId?: string
  prescriber?: Prescriber
  prescriberId?: string
  prescription?: Prescription
  prescriptionId?: string
  payerId?: string
  autobotId?: string
  parataNdcId?: string
  fill?: Fill
  fillId?: string
  order?: Order
  orderId?: string
  escriptId?: string
  escript?: Escript
  payerIndex?: number
  event: string
  change?: LogChange
  createdAt: string
  updatedAt: string
  message: string
  tags: string[]
  isArchived?: boolean
  copayRequestId?: string
  copayRequest?: CopayRequest
  debug?: { origin?: string }
  priorAuthorizationId?: string
  details?: string
}

export interface LogChange {
  field?: string
  newValue: string
  oldValue: string
}

export interface Payment {
  paymentType: PaymentType
  cost: number
  otc: boolean
  shipping: string
  signatureRequired: boolean
}

export interface SecondaryInfo {
  names: [
    {
      firstName: string
      lastName: string
    },
  ]
  phoneNumbers: string[]
  emails: string[]
  addresses: Address[]
}

export interface Patient {
  _id: string
  gender: string
  guardian?: string
  species?: Species
  logs: Log[]
  firstName: string
  lastName: string
  dob: string
  address?: {
    home?: Address
  }
  contacts?: {
    phone?: string
    email?: string
    sms?: string
  }
  createdAt: string
  insurances?: Insurance[]
  medicalHistory?: DURSubjectCategories
  governmentSponsoredPlan: boolean
  secondaryInfo: SecondaryInfo
  preferences?: Preferences
  status?: PatientStatus
  weight?: {
    unit?: string
    value?: number
  }
  truepillPatientToken?: string
}

export interface Contact {
  phone?: string
  email?: string
  sms?: string
}

export interface PrePatient {
  firstName: string
  lastName: string
  dob?: string
  gender?: string
  email?: string
  phone?: string
  conditions?: [string]
  medications?: string
  payers?: [string]
  address?: Address
  allergies?: [string]
}

export interface MatchCandidate {
  patient: Patient
  status: string
  sortOrder: number
}

export interface Addresses {
  home?: Address
  defaultShipping?: Address
}

export interface PatientMatch {
  _id: string
  escriptId: string
  candidatePatients: [MatchCandidate]
  prePatient: PrePatient
  status: string
  customer?: Customer
  contacts?: Contact
  address?: Addresses
  lock?: Lock
  createdAt: Date
}

export interface PharmacyAddress extends Address {
  purpose: string
  fax: string
  phone: string
}

export interface PharmacyTaxonomy {
  code: string
  state: string
  license: string
  isPrimary: boolean
}

export interface Pharmacy {
  _id: string
  npi: string
  organizationName: string
  alternativeOrganizationName: string
  addresses: PharmacyAddress[]
  practiceAddresses: PharmacyAddress[]
  authorizedFirstName: string
  authorizedLastName: string
  authorizedMiddleName: string
  authorizedNamePrefix: string
  authorizedNameSuffix: string
  authorizedCredential: string
}

export interface Address {
  street1: string
  street2?: string
  city: string
  state: string
  zip: string
  firstName?: string
  lastName?: string
  country?: string
}

export interface LocationFormData {
  pharmacistInCharge: User
}

export interface PrescriberFormData {
  faxNumber?: string
  prescriber?: Prescriber
  contactMethod?: 'electronic' | 'fax'
  spi?: string
}

export interface DURFormData {
  subjectIds: {
    [subjectId: string]: DURSubject
  }
  addedAt: { [key: string]: number }
  edited?: boolean
}

export interface DocumentUploadFormState {
  _id?: string
  name?: string
  s3SignedUrl?: string
}

export const DAW = [
  'No product selection indicated (may also have missing values)',
  'Substitution not allowed by prescriber',
  'Patient requested product dispensed',
  'Pharmacist selected product dispensed',
  'Generic Drug Not in Stock',
  'Brand Drug Dispensed as a Generic',
  'Override',
  'Brand Drug Mandated by Law',
  'Generic Drug Not Available in Marketplace',
  'Plan Requests Brand Drug',
]

export type FillInfoType = {
  orderId: Order['_id']
  rxId: Prescription['_id']
  fillId: Fill['_id']
}

export const ShortDAW = [
  'No product selection indicated',
  'Substitution not allowed by prescriber',
  'Patient requested product dispensed',
  'Pharmacist selected product dispensed',
  'Generic Drug Not in Stock',
  'Brand Drug Dispensed as a Generic',
  'Override',
  'Brand Drug Mandated by Law',
  'Generic Drug Not Available in Marketplace',
  'Plan Requests Brand Drug',
]

export type FillStage = RxFillRequestStatus | FillState

export enum OTCOrderIssueReason {
  deliveredButNeverReceived = 'DELIVEREDBUTNEVERRECEIVED',
  lostInTransit = 'LOSTINTRANSIT',
  other = 'OTHER',
}

export enum OTCOrderItemIssueReason {
  itemDamaged = 'ITEMDAMAGED',
  wrongItemShipped = 'WRONGITEMSHIPPED',
  wrongItemPurchased = 'WRONGITEMPURCHASED',
  itemNoLongerNeeded = 'ITEMNOLONGERNEEDED',
}

export enum OTCOrderIssueAction {
  refundToCard = 'REFUNDTOCARD',
  'return' = 'RETURN',
  replace = 'REPLACE',
  returnAndReplace = 'RETURNANDREPLACE',
  cancel = 'CANCEL',
}

export enum OTCShippingCharge {
  waiveCharge = 'WAIVE_CHARGE',
  chargeDifference = 'CHARAGE_DIFFERENCE',
}

export enum OTCShippingAction {
  refund = 'REFUND',
  partialRefund = 'PARTIAL_REFUND',
  doNotRefund = 'DO_NOT_REFUND',
}

export enum OTCShippingPayment {
  REFUND = 'refund',
  PARTIAL_REFUND = 'partialRefund',
  DO_NOT_REFUND = 'doNotRefund',
}

export interface Shipper {
  _id: string
  name: string
}

export interface OrdersStats {
  received?: number
  rejected?: number
  completed?: number
  packing?: number
}

export interface FillsStats {
  manual?: number
  machine?: number
  cash?: number
  insurance?: number
  inTriage?: number
  statuses?: {
    [k in RxFillRequestStatus]: number
  }
}

export enum TriagesStatsAccessors {
  Adjudication = 'Adjudication',
}

export interface TriagesStats {
  Adjudication?: number
}

export interface OtcOrders {
  Packing?: number
}

export interface Stats {
  orders: OrdersStats
  fills: FillsStats
  triages?: TriagesStats
  otcOrders: OtcOrders
}

export enum ShippingMethod {
  Standard = 'standard',
  Priority = 'priority',
  Overnight = 'overnight',
  DhlParcel = 'dhl_ecommerce_parcel_expedited',
  DhlPlus = 'dhl_ecommerce_parcel_plus_expedited',
  DhlMax = 'dhl_ecommerce_parcel_expedited_max',
  Fedex2Day = 'fedex_2_day',
  FedexGround = 'fedex_ground',
  FedexPriorityExpress = 'fedex_priority_express',
  FedexPriorityOvernight = 'fedex_priority_overnight',
  FedexSmartPost = 'fedex_smart_post',
  FedexStandardOvernight = 'fedex_standard_overnight',
  Ups3DaySelect = 'ups_3_day_select',
  UpsGround = 'ups_ground',
  UpsMailInnovationsDomestic = 'ups_mail_innovations_domestic',
  UpsNextDayAir = 'ups_next_day_air',
  UpsNextDayAirEarlyAm = 'ups_next_day_air_early_am',
  UpsNextDayAirSaver = 'ups_next_day_air_saver',
  UpsSecondDayAir = 'ups_second_day_air',
  UpsSecondDayAirAm = 'ups_second_day_air_am',
  UpsSurepostLightweight = 'ups_surepost_lightweight',
  UspsFirst = 'usps_first',
  UspsGroundAdvantage = 'usps_ground_advantage',
  UspsPriority = 'usps_priority',
  UspsPriorityExpress = 'usps_priority_express',
  UspsPriorityMailExpress = 'usps_priority_mail_express',
}

// Remove partial vals
export type Full<T> = {
  [P in keyof T]-?: T[P]
}

export interface MedicationSearchResult {
  _id: string
  ndcs: string[]
}

export interface CopayMedicationSearchResult {
  _id: string
  count: number
}

export interface OrderMedicationSearchResult {
  _id: string
  count: number
}

export interface TriageReasonSearchResult {
  _id: string
}

export interface ClaimsRejectionMessage {
  rejectCode: string
  rejectCodeTranslated: string
}

export interface MedicationSuggestion {
  name: string
  highlighted?: boolean
  count?: number
  ndc?: string
  showNdc?: boolean
}

export interface Error {
  error: string
}

export interface Loading {
  loading: boolean
}

export interface MedicationNoResults {
  noResults: boolean
}

export interface NdcFilter {
  name: string
  ndcPackageCodes: string[]
}

export interface MedicationChoices {
  [key: string]: string[]
}

export type MedicationSuggestionItem = MedicationSuggestion | MedicationNoResults | Error | Loading

export type PatientSuggestionItem = Patient | Error | Loading

export interface DURStore {
  formData: {
    durInputs: DURSubjectCategories
  }
  setClinicalReviewInputs: (mergeData: Partial<DURSubjectCategories>) => void
  saveFormUpdate: () => void
}

export enum RejectExternalTransferReason {
  DosageDoesntMatchRx = 'Dosage doesn’t match Prescription',
  DuplicateTransfer = 'Duplicate transfer',
  FormulationDoesntMatchRx = "Formulation doesn't match prescription",
  IncorrectContactInformation = 'Incorrect pharmacy/prescriber contact information',
  NoRefills = 'No refills remaining',
  Other = 'Other reason',
  PatientCancellation = 'Patient cancellation request',
  PatientInfoMismatch = 'Patient information doesn’t match prescription',
  PharmacyNonResponse = 'Pharmacy did not respond after 2 days',
  RxExpired = 'Prescription has expired',
  InvalidRx = 'Invalid Rx - New Rx needed from MD',
  UnableToLocationPharmacy = 'Unable to locate the receiving pharmacy location',
}

export enum ReinstatePrescriptionReason {
  prescriberRequestedReinstate = 'Prescriber request to reinstate',
  patientRequestedReinstate = 'Patient request to reinstate',
  discontinuedInError = 'Discontinued in error',
}

export interface OrderSearchVariables {
  drugName?: string
  ndcs?: string[]
  patientName?: string
  patientDob?: string
  rxFillCode?: string
  orderNumber?: string
  customerRef?: string
  coreOrderToken?: string
  coreFillRequestToken?: string
  coreCopayToken?: string
  coreCopayRequestFillToken?: string
  isOtcOnly?: boolean
  isRxOtcOnly?: boolean
  lastFourDigits?: string
  transactionId?: string
}

export interface CopaySearchVariables {
  patientName?: string
  coreCopayToken?: string
  coreCopayId?: string
}

export interface PriorAuthorizationSearchVariables {
  status?: string
}

export interface Autobot {
  _id: string
  backPressureLimit?: number
  brand: string
  gcpAddress: string
  ip?: string
  isActive: boolean
  isOnline: boolean
  locationId: string
  modelName: string
  name?: string
  port?: string
  logs?: Log[]
}

export interface AutobotOptions {
  backPressureLimit: number
  isActive: boolean
  name: string
  gcpAddress: string
  locationId?: string
  modelName?: string
  brand?: string
  isOnline?: boolean
}

export interface ParataNdc {
  _id: string
  ndc: string
  isActive?: boolean
  autobotId: string
  locationId: string
  customerId: string
  orderType?: string
  quantity?: number
  logs?: Log[]
}

export interface ParataNdcOptions {
  isActive?: boolean
  locationId?: string
  customerId?: string
  autobotId?: string
  orderType?: string
  quantity?: number
}

export enum TPCSShippingMethod {
  Default = 1,
  Expedited = 2,
  Expedited_AK_HI = 3,
  Standard_Restricted = 4,
  Expedited_Usps_Priority = 5,
  Expedited_AK_HI_PO = 6,
  Expedited_Covid_Items = 7,
  Expedited_AK_HI_PO_Covid_Items = 8,
  Expedited_PO_Military_Covid_Items = 9,
  Expedited_Async_Care = 10,
}

export interface OptumProduct {
  slug: string
  sku: string
  metaDescription: string
  price: number
  brand: string
  image: string
  thumbnail: string
  name: string
  quantity: number
}

export interface OptumOrderDetail {
  products: [OptumProduct]
  cardNumber: string
  createdAt: Date
  discount: string
  shippingMethod: string
  shippingMethodName: string
  shippingPrice: number
  status: string
  taxesPrice: number
  totalPrice: number
  trackingUrl: string
  orderId: string
}

export interface OptumOrderUser {
  userId: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

export interface OptumOrder {
  address: Address
  order: OptumOrderDetail
  user: OptumOrderUser
}

export type AttachmentsOptions = {
  orderId?: string
  fillId?: string
  prescriptionId?: string
  patientId?: string
  hideArchivedNotes?: boolean
}

export type ItemStatus = {
  [k: string]: string
}

export type Labeler = {
  name: string
}
export type PackageInfoInventory = {
  balanceOnHand: string
  locationId: string
  upc: string
  pricing: {
    wac: string
  }
}

export interface PackageInfo {
  itemStatusFlag: string
  packageSize: string
  storageConditionCode: string
  packageDescriptionCode: string
  gpi: string
  teCode: string
  drugImageImprints: {
    image: string
    manufacturer: string
  }
  inventories: PackageInfoInventory[]
  packageSizeUnitOfMeasure: string
  ndc: string
  deaCode?: string
}

export type NDC = {
  ndc: string
  teCode: string
  drugName: string
}

export type ProductInfo = {
  deaSchedule: string
  productType: string
  alternativeNames: string[]
  raw: {
    NONPROPRIETARYNAME: string
    ROUTENAME: string
    PROPRIETARYNAME: string
    ACTIVE_NUMERATOR_STRENGTH: string
    ACTIVE_INGRED_UNIT: string
  }
}

export interface DrugDetails {
  labeler: Labeler
  drugName?: string
  ndc: string
  dosageForm?: keyof typeof Medispan
  description?: string
  routeOfAdministration?: string
  drugDescriptorId?: string
  strength?: string
  strengthUnitOfMeasure?: string
  updatedAt: Date
  therapeuticClass: string
  productInfo?: ProductInfo
  packages?: PackageInfo[]
  equivalentNDCs?: {
    ndcs: NDC[]
  }
  logs: Log[]
  genericFor: string
  unitPrice: string
  gpiName: string
}

export type GetSingleNdc = {
  drugName: string
  ndc: string
  labeler: {
    name: string
  }
  dosageForm: string
  routeOfAdministration: string
  drugDescriptorId?: string
  strength: string
  description: string
  strengthUnitOfMeasure: string
  updatedAt: Date
  therapeuticClass: string
  equivalentNDCs: {
    ndcs: {
      ndc: string
    }
  }

  packages: {
    itemStatusFlag: string
    packageSize: number
    storageConditionCode: string
    packageDescriptionCode: string
    gpi: string
    teCode: string
    drugImageImprints: {
      image: string
      manufacturer: string
    }
    inventories: {
      balanceOnHand: string
      locationId: string
      upc: string
      pricing: {
        wac: string
      }
    }
  }
}

export type GetDrugDetailsQuery = {
  getSingleNdc: DrugDetails | null
}

export enum DrugStatusEnum {
  I = 'Inactive',
  A = 'Active',
}

export type ClaimResponseInfo = {
  ndc: string
  quantity?: number
  directions?: string
  name?: string
  daysSupply?: number
}

export enum ToastType {
  'error' = 'ERROR',
  'info' = 'INFO',
  'success' = 'SUCCESS',
}

export enum ToastPosition {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
  TopCenter = 'top-center',
  BottomCenter = 'bottom-center',
}

export interface ToastOptionalArgs {
  timeToAutoDismiss?: number
  position?: ToastPosition
}

export interface Toast {
  message: string
  id: string
  type: ToastType
  timeToAutoDismiss?: number
  position?: ToastPosition
}

export type ChildProps = { children: ReactNode }
export type OptChildProps = { children?: ReactNode }

export const changePaymentReasonOptions = [
  'Pharmacy not contracted',
  'Missing/invalid insurance info',
  'Primary Insurance Required',
  'Prior authorization required',
  'Medication not covered',
  'Refills not covered by insurance',
  'Patient must use preferred pharmacy',
  'Coverage terminated',
  'Qty limited by insurance',
  'Other',
]

export const NOTE_TAGS = ['Informational', 'QRE', 'Typing', 'Adjudication', 'Complaint', 'Inquiry', 'Clinical', 'DUR']

export const REJECT_REASONS = [
  'Name mismatch',
  'DOB mismatch',
  'Bad address details',
  'Refill too soon',
  'No refills/quantity remaining',
  'Mail order fills exceeded',
  'Duplicate Order',
  'No e-script',
  'Cancellation request',
  'High copay',
  'Test order',
  'Transfer out of pharmacy',
  'Bad insurance details',
  'Closed network',
  'Coverage not effective',
  'Out of stock',
  'Unavailable to order',
  'Contraindication (drug, disease, allergy)',
  'Primary Insurance Required',
  'Prior Authorization Required',
  'Non-formulary',
  'Other',
]

export const SHIPPING_METHODS = [
  ' Usps Priority',
  ' Usps Priority Express',
  ' Usps First',
  ' Fedex Priority Overnight',
  ' Fedex Standard Overnight',
  ' Fedex Ground',
  ' Fedex 2 Day',
  ' Ups Surepost Lightweight',
  ' Fedex Priority Express',
  ' Ups Next Day Air',
  ' Ups Mail Innovations Domestic',
  ' Dhl Ecommerce Parcel Plus Expedited Max',
  ' Ups Next Day Air Saver',
  ' Ups Second Day Air',
  ' Ups 3 Day Select',
  ' Ups Ground',
  ' Ups Next Day Air Early Am',
  ' Ups Second Day Air Am',
  ' Fedex Smart Post',
  ' Usps Priority Mail Express',
]

export interface PrescriptionViewConfig {
  rxHasNotBeenFilled?: boolean
  pharmacy?: Partial<Pharmacy>
}

export interface DynamicObject<T> {
  [key: string]: T
}

export interface Weight {
  lb: number
  kg: number
}

export interface LicenseWithImage extends License {
  image?: {
    s3SignedUrl?: string
  }
}

export interface CriticalMedicalNoteHistory {
  note?: string
  updatedAt: Date
  updatedBy: string
}

export enum CriticalMedicalNoteType {
  NDC = 'Ndc',
  GPI = 'Gpi',
}

export interface CriticalMedicalNotes {
  note?: string
  identifier: string
  history: CriticalMedicalNoteHistory[]
  type: CriticalMedicalNoteType
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string
}

export enum ExternalInventorySystemPretty {
  tecsys = 'Tecsys',
  pioneer_inventory = 'Pioneer',
}
