import { FillStatus } from '@truepill/tpos-types'
import type { FillData } from '@truepill/tpos-types'

export const getFirstCompletedFill = (fills: FillData[]): FillData | undefined => {
  for (let i = 0; i < fills.length; i++) {
    if (fills[i].status === FillStatus.Complete) {
      return fills[i]
    }
  }
}
