import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'

type GuardianHeadingInfoProps = { guardian?: string }

const GuardianHeadingInfo = ({ guardian }: GuardianHeadingInfoProps): JSX.Element => {
  return <GuardianName data-private>{`Guardian: ${guardian || 'N/A'}`}</GuardianName>
}

const GuardianName = styled.span`
  color: ${bodyPrimaryColor};
`

export default GuardianHeadingInfo
