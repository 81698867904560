import type { Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import {
  OrderStatus,
  UserRoles,
  RxFillRequestStatus,
  LaunchDarkly,
  RxFillRequestSpecialHandlingTag,
  ClaimDataPaidStatus,
} from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as ConfirmIcon } from 'assets/icons/circlecheck.svg'
import { ReactComponent as CycleIcon } from 'assets/icons/cycle.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { ActionButtonContainer, CancelButton } from 'components/PageStructure'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import { CONFIRM_FILL_PV1, UPDATE_DUR_SCREEN, SET_DISPENSED } from 'gql'
import usePatient from 'hooks/navigation/usePatient'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useEditMode from 'hooks/useEditMode'
import { useFormData } from 'hooks/useFormData'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextPV1 from 'hooks/useGetNextPV1'
import useGetNextTriage from 'hooks/useGetNextTriage'
import useOrderLock from 'hooks/useOrderLock'
import useUpdatePrescribed from 'hooks/useUpdatePrescribed'
import ChangeDispensed from 'modals/ChangeDispensed'
import WrappedClaimsRejectedModal from 'modals/ClaimsRejectedModal'
import WrappedClaimsSuccessModal from 'modals/ClaimsSuccessModal'
import PrintModal from 'modals/PrintModal'
import PV1ConfirmationModal from 'modals/PV1ConfirmationModal'
import RejectModal from 'modals/RejectModal'
import RequestClarificationModal from 'modals/RequestClarificationModal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-use'
import { bodyPrimaryColor } from 'styles/styleVariables'
import { ToastType } from 'types'
import type { DURSubject, Fill, Order, Prescription, RXFillRequest } from 'types'
import { continueOrderWorkflow, getClaimsFromLastRun, isInsuranceFill } from 'utils'

const useConfirmFill = (
  order: Order,
  fill: Fill,
  status: RxFillRequestStatus,
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>,
) => {
  const { getNextPV1 } = useGetNextPV1()
  const { showToast } = useToastContext()
  const [confirmFillPV1] = useMutation(CONFIRM_FILL_PV1, { refetchQueries: ['getBasicOrder'] })

  const showErrorToast = useErrorToast()
  return async (data?: {
    prescriberStateLicense?: string
    prescriberDeaNumber?: string
    pharmacistAttestation?: boolean
  }) => {
    try {
      if (order?.inTriage) {
        throw new Error('unable to confirm a fill while it is in Triage')
      }
      await confirmFillPV1({
        variables: {
          orderId: order._id,
          fillId: fill?._id,
          prescriberStateLicense: data?.prescriberStateLicense,
          prescriberDeaNumber: data?.prescriberDeaNumber,
          pharmacistAttestation: data?.pharmacistAttestation,
        },
      })
      showToast(`Rx: ${fill?._id} - ${status} confirmed`, ToastType.success)
      forceLoadingSpinner?.(true)
      await getNextPV1(order, fill)
      forceLoadingSpinner?.(false)
    } catch (e) {
      showErrorToast('Failed to confirm PV1: ' + (e as Error)?.message)
      throw e
    }
  }
}

const useRejectFill = (order: Order, fill: Fill) => {
  const { showToast } = useToastContext()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const history = useHistory()
  const { search } = useLocation()
  const { getNextOrder: getNextTriage } = useGetNextTriage()
  return async () => {
    showToast(`Rx: ${fill._id} - rejected`, ToastType.success)

    // TODO - remove this once PV1 has start work implemented consistent with adjudication/triage
    if (order.inTriage) {
      await continueOrderWorkflow(search, queryMap, history, getNextTriage, FulfillmentQueueName.Triage)
    }
  }
}

const requiresConfirmation = (fill: Fill, rxFillRequest: RXFillRequest) => {
  if (rxFillRequest.specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CII)) {
    return true
  }

  if (!fill.durScreenings) {
    return false
  }

  for (const durRecord of fill.durScreenings) {
    if (durRecord.results && durRecord.results.length) {
      return true
    }
  }
  return false
}

const useTryConfirmFill = (
  order: Order,
  fill: Fill,
  status: RxFillRequestStatus,
  rxFillRequest: RXFillRequest,
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>,
  callback?: () => void,
) => {
  const { showModal, dismissModal } = useModalContext()
  const confirmFill = useConfirmFill(order, fill, status, forceLoadingSpinner)

  return useCallback(() => {
    if (requiresConfirmation(fill, rxFillRequest)) {
      showModal(() => (
        <PV1ConfirmationModal
          rxFillRequest={rxFillRequest}
          isControlledSubstance={rxFillRequest.specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CII)}
          fill={fill}
          confirmationCallback={({ prescriberStateLicense, prescriberDeaNumber, pharmacistAttestation }) => {
            confirmFill({
              prescriberStateLicense,
              prescriberDeaNumber,
              pharmacistAttestation,
            })
              .then(() => {
                callback && callback()
              })
              .catch(e => {
                console.error('failed to confirm PV1 fill', e)
              })
              .finally(() => {
                dismissModal()
              })
          }}
        />
      ))
    } else {
      confirmFill()
        .then(() => {
          callback && callback()
        })
        .catch(e => {
          console.error('failed to confirm PV1 fill', e)
        })
    }
  }, [fill, rxFillRequest, showModal, confirmFill, callback, dismissModal])
}

type ActionButtonsProps = {
  canConfirm?: boolean
  order: Order
  fill: Fill
  prescription: Prescription
  rxFillRequest: RXFillRequest
  disableRphButtons?: boolean
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>
  onPrescribedNdcUpdated?: () => void
}

const ActionButtons = ({
  canConfirm,
  order,
  fill,
  prescription,
  rxFillRequest,
  disableRphButtons,
  forceLoadingSpinner,
  onPrescribedNdcUpdated,
}: ActionButtonsProps): JSX.Element => {
  const [editMode, setEditMode] = useEditMode()
  const { showModal, dismissModal } = useModalContext()
  const { orderEditable } = useOrderLock(order._id)
  const confirmFill = useConfirmFill(order, fill, rxFillRequest?.status as RxFillRequestStatus, forceLoadingSpinner)
  const rejectFill = useRejectFill(order, fill)
  const showErrorToast = useErrorToast(true)
  const showSuccessToast = useSuccessToast(true)

  const { guestPatient } = order
  const { patient } = rxFillRequest
  const { savePatientChanges } = usePatient({ patient, guestPatient })

  const { updatePrescribed, loading: updatePrescribedIsLoading } = useUpdatePrescribed({
    prescription,
    onCancelPrompt: () => {
      setEditMode(false)
    },
    onError: () => {
      showErrorToast('An error ocurred while updating the prescribed medication.')
    },
    onCompleted: async (res, changedFields) => {
      try {
        await saveOrderChanges()
        await savePatientChanges()

        // If we changed the directions on the prescription we need to update those on the fill as well
        const prescriptionChanges = getPrescriptionChanges(prescription) as Partial<Prescription>
        const updatedNDCFromNonZeroDaw =
          prescriptionChanges.daw && prescriptionChanges.daw > 0 && prescription.ndc !== fill.dispensed.ndc
        const updatedNDC = prescriptionChanges.ndc || updatedNDCFromNonZeroDaw

        if (updatedNDC) {
          // notify that the pescribed ndc was updated
          onPrescribedNdcUpdated?.()
        }

        if (isInsurance && !isInAdjudication && updatedNDC) {
          dismissModal()
          const newFills = res?.updatePrescribed?.fills
          const newFill = newFills[newFills.length - 1]
          const claims = newFill ? getClaimsFromLastRun(newFill.claims, newFill.claimSummary?.runId) : []
          const lastClaim = claims[claims.length - 1]

          if (ClaimDataPaidStatus.includes(lastClaim?.status ?? '')) {
            showModal(() => <WrappedClaimsSuccessModal item={order} fill={newFill} itemFill={rxFillRequest} />)
          } else {
            showErrorToast('Successfully updated dispensed medication but failed to rerun claim, try manually')
            showModal(() => <WrappedClaimsRejectedModal orderId={order['_id']} fill={newFill} />)
          }
        }

        if (changedFields?.directions) {
          await updateDispensed({
            variables: {
              fillId: fill._id,
              itemId: order._id,
              directions: changedFields?.directions,
            },
          })
        }

        if (prescriptionChanges) showSuccessToast('Succesfuly updated the prescribed medication.')
      } catch (e) {
        showErrorToast('Failed to update: ' + (e as Error))
      }
      setEditMode(false)
    },
  })

  const handleUpdateClick = useCallback(() => {
    updatePrescribed()
  }, [updatePrescribed])

  const {
    state: { formData },
    changes: { getOrderChanges, getPrescriptionChanges },
    actions: { updateFormData },
  } = useFormData()
  const showInfoToast = useInfoToast()
  const isInsurance = isInsuranceFill(rxFillRequest)
  const isInAdjudication = rxFillRequest.status === RxFillRequestStatus.Adjudication

  const [updateDUR] = useMutation(UPDATE_DUR_SCREEN)
  const [updateDispensed] = useMutation(SET_DISPENSED)

  useTryConfirmFill(order, fill, rxFillRequest?.status as RxFillRequestStatus, rxFillRequest, forceLoadingSpinner)

  const saveOrderChanges = useCallback(async () => {
    const changes = getOrderChanges(order) as Partial<Order>

    // Since updateDUR doesn't take a patch we need to build a
    // custom payload
    if (changes.durInputs && formData.order.durInputs) {
      // TODO prob need a better way to keep __typename out of the form data
      const sanitizedDUR = Object.entries(formData.order.durInputs).reduce(
        (acc: Record<string, DURSubject>, [key, value]) => {
          if (Array.isArray(value)) {
            ;(acc as any)[key] = value.map(({ __typename, ...rest }: any) => rest)
          }
          return acc
        },
        {},
      )

      showInfoToast('DUR screen in progress')
      await updateDUR({
        variables: {
          orderId: order._id,
          ...sanitizedDUR,
        },
      })
    }

    setEditMode(false)
  }, [order, getOrderChanges, setEditMode, formData.order.durInputs, showInfoToast, updateDUR])

  const shouldShowButtons = order.status !== OrderStatus.Reverted
  const shouldShowRequestClarificationModalButton = useFlag(
    LaunchDarkly.FeatureFlags.ENABLE_REQUEST_CLARIFICATION_BUTTON,
  )

  if (!shouldShowButtons) {
    return <></>
  }

  const disableConfirmButton =
    disableRphButtons ||
    canConfirm === false ||
    !!order.inTriage ||
    !orderEditable ||
    order.status !== OrderStatus.PV1 ||
    fill.dispensed.daysSupply === 0 ||
    fill.dispensed.directions === '' ||
    // We can't confirm PV1 if this is a non-replacement order and
    // there's a fill with < 1 refills remaining or a prescription
    // quantity remaining less than the amount already dispensed.
    (!order.isReplacement &&
      order.rxFillRequests.some(
        ({ fill, prescription, status }) =>
          status === RxFillRequestStatus.PV1 && prescription.quantityRemaining < fill.dispensed.quantity,
      ))

  if (editMode) {
    return (
      <ActionButtonContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            updateFormData({ prescription: { $set: prescription } })
            setEditMode(false)
          }}
        />
        <SaveButton
          label={`Updat${updatePrescribedIsLoading ? 'ing' : 'e'}`}
          disabled={updatePrescribedIsLoading}
          onClick={handleUpdateClick}
        />
      </ActionButtonContainer>
    )
  }

  return (
    <ActionButtonContainer>
      <AuthLimited
        roles={[
          UserRoles.Pharmacist,
          UserRoles.LeadPharmacist,
          UserRoles.Technician,
          UserRoles.CustomerSupport,
          UserRoles.LeadCustomerSupport,
          UserRoles.Admin,
        ]}
      >
        <ActionButton
          data-testid="change-dispensed"
          disabled={!orderEditable}
          icon={
            <IconWrapper>
              <CycleIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label="Change dispensed"
          onClick={() => {
            showModal(() => <ChangeDispensed item={order} fill={fill} prescription={prescription} />)
          }}
          hotKey="C"
          hotKeyMeta={{ ctrl: true, shift: true }}
          hotKeyLabel="CTRL-SHIFT-C"
        />
      </AuthLimited>
      {shouldShowRequestClarificationModalButton && (
        <AuthLimited
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
            UserRoles.Admin,
          ]}
        >
          <ActionButton
            data-testid="request-clarification"
            disabled={!orderEditable}
            icon={
              <IconWrapper>
                <DangerIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label="Request Clarification"
            onClick={() => {
              showModal(() => <RequestClarificationModal prescription={prescription} order={order} />)
            }}
          />
        </AuthLimited>
      )}
      <AuthLimited
        roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.CustomerSupport, UserRoles.LeadCustomerSupport]}
      >
        <ActionButton
          data-testid="reject"
          disabled={!orderEditable}
          icon={
            <IconWrapper>
              <CancelIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label="Reject"
          onClick={() =>
            showModal(() => (
              <RejectModal
                itemId={order._id}
                fillId={fill._id}
                confirmationCallback={rejectFill}
                durs={fill.durScreenings?.slice(-1)[0]?.results ?? []}
                isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
              />
            ))
          }
          hotKey="R"
          hotKeyMeta={{ shift: true }}
          hotKeyLabel="SHIFT-R"
        />
      </AuthLimited>
      <TriageOrResolveTriageButton item={order} fillId={fill._id} forceShowTriage />
      <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}>
        <ActionButton
          data-testid="confirm"
          disabled={disableConfirmButton}
          icon={
            <IconWrapper>
              <ConfirmIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label="Confirm"
          hotKeyLabel="CTRL-Enter"
          hotKey="Enter"
          hotKeyMeta={{ ctrl: true }}
          onClick={async () => {
            if (requiresConfirmation(fill, rxFillRequest)) {
              showModal(() => (
                <PV1ConfirmationModal
                  rxFillRequest={rxFillRequest}
                  isControlledSubstance={rxFillRequest.specialHandlingTags?.includes(
                    RxFillRequestSpecialHandlingTag.CII,
                  )}
                  fill={fill}
                  confirmationCallback={async ({
                    prescriberStateLicense,
                    prescriberDeaNumber,
                    pharmacistAttestation,
                  }) => {
                    try {
                      await confirmFill({ prescriberStateLicense, prescriberDeaNumber, pharmacistAttestation })
                    } catch (e) {
                      console.error('failed to confirm PV1 fill', e as Error)
                    } finally {
                      dismissModal()
                    }
                  }}
                />
              ))
              return
            }

            try {
              await confirmFill()
            } catch (e) {
              console.error('failed to confirm PV1 fill', e as Error)
            }
          }}
        />
      </AuthLimited>
      <AuthLimited roles={[UserRoles.LeadPharmacist]}>
        {rxFillRequest?.status === RxFillRequestStatus.Complete && (
          <ActionButton
            data-testid="reprint"
            disabled={!orderEditable}
            icon={
              <IconWrapper>
                <PrintIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            hotKey="p"
            label="Reprint Label"
            onClick={() => {
              showModal(() => (
                <PrintModal
                  isReprint
                  customerId={order.rxFillRequests[0]?.prescription?.customer?.legacyId}
                  fills={[{ fillId: fill._id, orderId: order._id }]}
                  title="Reprint label"
                />
              ))
            }}
          />
        )}
      </AuthLimited>
    </ActionButtonContainer>
  )
}

export default ActionButtons
