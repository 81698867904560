import type { FC } from 'react'
import { Box } from '@truepill/react-capsule'
import { Link, useLocation } from '@truepill/tpos-react-router'
import { LaunchDarkly, OrderType } from '@truepill/tpos-types'
import { ReactComponent as MultipetIcon } from 'assets/icons/multipet.svg'
import ItemIdDisplay from 'components/ItemIdDisplay'
import { VerticalStackGroupListStyle } from 'components/RXTable'
import { CheckBox } from 'grommet'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { useFlag } from 'providers/LaunchDarklyProvider'
import type { Selectable } from 'providers/SelectionProvider'
import { useSelectionContext } from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToFulfillmentOrder } from 'routes'
import styled, { css } from 'styled-components'
import { borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Order, RXFillRequest, OTCProduct } from 'types'
import OtcAdjudicationFills from './OtcAdjudicationFills'
import { FillRow, OtcRow } from './Rows'

interface BaseOrderProps {
  inline?: boolean
  hideOrderDetails?: boolean
  editableEntries?: boolean
  showFillsOnly?: boolean
}

interface OrderProps extends BaseOrderProps {
  order: Order
  groupRxAndOTC?: boolean
  selectionLive?: boolean
  noIndividualFillSelection?: boolean
  disabled?: boolean
  disabledCheckbox?: boolean
  filterOnQueue?: boolean
  isRxComingFromOrderPage?: boolean
  onClick?: () => void
}

interface OTCProductAndRx extends OTCProduct {
  rxNumber?: string
}

const groupOTCAndRXAdjudication = (otcProducts: OTCProduct[], rxFillRequests: RXFillRequest[], groupBase: string) => {
  return groupBase === 'otcProducts'
    ? otcProducts.map(otcProduct => {
        const { rxFillRequestId } = otcProduct
        const fillRequest = rxFillRequests.find(({ _id }) => _id === rxFillRequestId)
        return { ...otcProduct, rxNumber: fillRequest?.prescription?.rxNumber ?? '' }
      })
    : rxFillRequests
}

export const OrderEntry: FC<OrderProps> = props => {
  const {
    order,
    inline,
    hideOrderDetails,
    selectionLive,
    noIndividualFillSelection,
    editableEntries = false,
    disabled = false,
    disabledCheckbox = false,
    filterOnQueue = false,
    showFillsOnly = false, // If set to true, otc orders are not rendered
    onClick,
    isRxComingFromOrderPage = false,
    groupRxAndOTC = false,
  } = props
  const { search } = useLocation()
  const fulfillmentQueue = useFulfillmentQueue()
  const { rxFillRequests, otcProducts } = order
  const shouldShowOtcAdjudicationAltUI = useFlag(LaunchDarkly.FeatureFlags.TEMP_OTC_ADJUDICATION_HIDE_RX_COMPONENT)
  const shouldAddOTCRxLabel = useFlag(LaunchDarkly.FeatureFlags.TEMP_ADD_OTC_RX_LABEL_TO_QUEUES)

  const isOtcAdjudicationOrder = order?.rxFillRequests.some(r => !!r.otcProductId)
  const showOtcAdjAltUI = isOtcAdjudicationOrder && shouldShowOtcAdjudicationAltUI && !groupRxAndOTC
  const showAddOTCRxLabelUI = isOtcAdjudicationOrder && shouldAddOTCRxLabel && groupRxAndOTC

  /**
   * We are only going to parse the order as an OTC one if it only contains
   * otc products
   */
  const isOtcOnlyOrder = otcProducts.length > 0 && !rxFillRequests.length
  const isBatchOrder = rxFillRequests.length > 1
  const totalOtcPlusFills = otcProducts.length + rxFillRequests.length

  const isPackingQueue = fulfillmentQueue?.name === FulfillmentQueueName.Packing

  const filteredRxFillRequests = filterOnQueue
    ? rxFillRequests.filter(rxFillRequest => {
        return (
          fulfillmentQueue?.fillStages?.includes(rxFillRequest.status) ||
          rxFillRequest.status.toString() === fulfillmentQueue?.name
        )
      })
    : rxFillRequests

  const showFills = showFillsOnly || !isOtcOnlyOrder || (isOtcOnlyOrder && isPackingQueue)

  const prescriptions = filteredRxFillRequests.map(rxFillRequest => rxFillRequest.prescription)

  const rxGroup: RXFillRequest[] = []
  const otcAndRxGroup: OTCProductAndRx[] = []

  if (showAddOTCRxLabelUI) {
    const groupBase = isPackingQueue ? 'otcProducts' : 'rxFillRequests'
    const grouped = groupOTCAndRXAdjudication(otcProducts, filteredRxFillRequests, groupBase)
    if (groupBase === 'rxFillRequests') {
      rxGroup.push(...(grouped as RXFillRequest[]))
    } else {
      otcAndRxGroup.push(...(grouped as OTCProductAndRx[]))
    }
  }

  const adjudicationGroupLength = rxGroup.length + otcAndRxGroup.length

  const showAsBatchOrder =
    (((isBatchOrder && !isOtcOnlyOrder) || totalOtcPlusFills > 1) && !showAddOTCRxLabelUI) ||
    (showAddOTCRxLabelUI && adjudicationGroupLength > 1)

  const isMultiPetOrder = order.orderType === OrderType.MultiPet

  return (
    <OrderGroup
      id={`Order-${order._id}`}
      inline={inline}
      isMultiOrder={showAsBatchOrder}
      data-testid="Order"
      data-order-id={order._id}
    >
      {showAsBatchOrder && !hideOrderDetails && (
        <OrderTitleBar
          data-testid="order-titlebar"
          to={goToFulfillmentOrder({
            fulfillmentQueueName: fulfillmentQueue?.name ?? 'all',
            orderId: order._id,
            search,
          })}
        >
          {selectionLive &&
            !disabledCheckbox &&
            (isPackingQueue ? (
              <PackingSelectedCheckbox order={order} disabled={disabled} />
            ) : (
              <OrderSelectedCheckbox order={order} rxFillRequests={filteredRxFillRequests} disabled={disabled} />
            ))}
          <OrderTitle>
            <p>
              Order: <ItemIdDisplay id={order.coreOrderId} customerRef={order.customerRef} />
            </p>
            {isMultiPetOrder && <MultipetIcon fill={primaryBackgroundColor} />}
          </OrderTitle>
          {showAddOTCRxLabelUI ? (
            <p>
              {adjudicationGroupLength} of {adjudicationGroupLength}
            </p>
          ) : otcProducts.length > 0 ? (
            <p>
              {otcProducts.length + prescriptions.length} of {otcProducts.length + rxFillRequests.length}
            </p>
          ) : (
            <p>
              {prescriptions.length} of {rxFillRequests.length}
            </p>
          )}
        </OrderTitleBar>
      )}
      {showFills && showOtcAdjAltUI && (
        <OtcAdjudicationFills
          rxFillRequests={filteredRxFillRequests}
          order={order}
          onClick={onClick}
          noIndividualFillSelection={noIndividualFillSelection}
          disabled={disabled}
          disabledCheckbox={disabledCheckbox}
          inline={inline}
          selectionLive={selectionLive}
          editableEntries={editableEntries}
        />
      )}
      {showFills &&
        showAddOTCRxLabelUI &&
        !isPackingQueue &&
        rxGroup.map(request => {
          return (
            <FillRow
              key={request._id}
              fill={request}
              order={order}
              onClick={onClick}
              noIndividualFillSelection={noIndividualFillSelection}
              disabled={disabled}
              disabledCheckbox={disabledCheckbox}
              inline={inline}
              selectionLive={selectionLive}
              editableEntries={editableEntries}
              showAsBatchOrder={showAsBatchOrder}
              showOTCTag={!!request.otcProductId}
            />
          )
        })}
      {showFills &&
        ((!showOtcAdjAltUI && !groupRxAndOTC) || (!showAddOTCRxLabelUI && groupRxAndOTC)) &&
        filteredRxFillRequests.map(request => {
          return (
            <FillRow
              key={request._id}
              fill={request}
              order={order}
              onClick={onClick}
              noIndividualFillSelection={noIndividualFillSelection}
              disabled={disabled}
              disabledCheckbox={disabledCheckbox}
              inline={inline}
              selectionLive={selectionLive}
              editableEntries={editableEntries}
              showAsBatchOrder={showAsBatchOrder}
              isRxComingFromOrderPage={isRxComingFromOrderPage}
            />
          )
        })}
      {!showFillsOnly &&
        !showAddOTCRxLabelUI &&
        otcProducts.map((product, productIndex) => {
          return (
            <OtcRow
              productName={product.name}
              onClick={onClick}
              order={order}
              inline={inline}
              showAsBatch={totalOtcPlusFills > 1}
              productIndex={productIndex}
              withRemoveIcon={editableEntries}
              key={`${order._id}-otc-${productIndex}`}
              selectionLive={selectionLive}
            />
          )
        })}
      {!showFillsOnly &&
        showAddOTCRxLabelUI &&
        isPackingQueue &&
        otcAndRxGroup.map((product, productIndex) => {
          return (
            <OtcRow
              productName={product.name}
              onClick={onClick}
              order={order}
              inline={inline}
              showAsBatch={showAsBatchOrder}
              productIndex={productIndex}
              withRemoveIcon={editableEntries}
              key={`${order._id}-otc-${productIndex}`}
              selectionLive={selectionLive}
              rxNumber={product.rxNumber}
            />
          )
        })}
    </OrderGroup>
  )
}

interface OrderSelectedCheckboxProps {
  order: Order
  rxFillRequests: RXFillRequest[]
  disabled: boolean
}

const OrderSelectedCheckbox = ({ order, rxFillRequests, disabled }: OrderSelectedCheckboxProps): JSX.Element => {
  const { bulkSetSelections, isSelection, removeBulkSelections } = useSelectionContext()
  const {
    tokenContext: { isLeadPharmacist, isPharmacist, isTechnician },
  } = usePlusClient()

  const allChecked = rxFillRequests.every(rxf => isSelection(rxf.fill))
  const someChecked = rxFillRequests.some(rxf => isSelection(rxf.fill))

  return (
    <StyledCheckboxPadding>
      <CheckBox
        checked={allChecked}
        disabled={disabled}
        indeterminate={!allChecked && someChecked}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const selections = rxFillRequests.filter(rxf => isSelection(rxf.fill))
          const selectable = rxFillRequests
            .map(rxf => ({
              _id: rxf.fillId,
              fill: rxf.fill,
              order,
            }))
            .filter(rxf => !rxf.fill.labelsPrinted || isLeadPharmacist() || isPharmacist() || isTechnician())

          if (e.target.checked && selections.length !== selectable.length) {
            bulkSetSelections(selectable as Selectable[])
          } else {
            removeBulkSelections(selectable as Selectable[])
          }
        }}
      />
    </StyledCheckboxPadding>
  )
}

const PackingSelectedCheckbox = ({ order, disabled }: { order: Order; disabled: boolean }): JSX.Element => {
  const { select, deSelect, isSelection } = useSelectionContext()

  return (
    <StyledCheckboxPadding>
      <CheckBox
        disabled={disabled}
        checked={isSelection(order)}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const { checked } = e.target
          if (checked) {
            select({ _id: order._id, order } as Selectable)
          } else {
            deSelect(order)
          }
        }}
      />
    </StyledCheckboxPadding>
  )
}

const OrderTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1;
`

export const OrderTitleBar = styled(Link)`
  cursor: pointer;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2px;
  padding-bottom: 3px;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.4em;
  background: ${borderColor};
  font-weight: bold;
  :visited {
    color: black;
  }
  > label {
    font-size: 1rem;
    margin-left: 1.05rem;
  }
`

export const StyledCheckboxPadding = styled.div`
  padding: 0 0.6rem 0;
`
export const LinkWrapper = styled.div<{
  withRemoveIcon?: boolean
  disabled?: boolean
  withUncancelIcon?: boolean
}>`
  display: grid;
  grid-gap: 0;
  grid-template-columns:
    [rxLink] auto
    ${({ withRemoveIcon }) => withRemoveIcon && `[rxReplace] 25px`}
    ${({ disabled, withRemoveIcon }) => !disabled && withRemoveIcon && `[rejectFill] 25px`}
    ${({ disabled, withUncancelIcon }) => disabled && withUncancelIcon && `[uncancelFill] 25px`};
  align-items: center;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`
export const NoTopBottomBorders = css`
  margin: 0;
  border-top: 0;
  border-bottom: 0;
`

export const MultiFillOrder = css`
  background-color: ${borderColor};
  margin-top: 2px;
  margin-bottom: 2px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  border-color: ${borderColor};
  padding: 3px;
`

export const OrderGroup = styled.li<{ isMultiOrder: boolean; inline?: boolean }>`
  font-family: 'lato', sans-serif;
  ${VerticalStackGroupListStyle}
  ${({ isMultiOrder }) => isMultiOrder && MultiFillOrder}
  ${({ inline, isMultiOrder }) => !isMultiOrder && inline && NoTopBottomBorders}
`

export const StyledLink = styled(Link)`
  grid-column: rxLink;
`

export default OrderEntry
