import { OrderType } from '@truepill/tpos-types'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { ReactComponent as MultipetIcon } from 'assets/icons/multipet.svg'
import DashboardTokenLink from 'components/DashboardTokenLink'
import GuardianHeadingInfo from 'components/GuardianHeadingInfo'
import PatientHeadingInfo from 'components/PatientHeadingInfo'
import Lozenge, { getStatusColor } from 'components/Tiles/Lozenge'
import UserInitialsBadge from 'components/UserInitialsBadge'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import type { FlattenSimpleInterpolation } from 'styled-components'
import {
  accentPink,
  contrastColor,
  primaryBackgroundColor,
  bodySecondaryColor,
  bodyPrimaryColor,
  subduedColor,
  lightOrange,
  darkOrange,
} from 'styles/styleVariables'
import type { OptChildProps, Order } from 'types'
import { removeUnderscore, removeCamelCase, formatCreatedDate, getPatientFromOrder } from 'utils'

type OrderHeadingProps = {
  order: Order
  headingStyle?: FlattenSimpleInterpolation
} & OptChildProps

const OrderHeading = ({ order, children, headingStyle }: OrderHeadingProps): JSX.Element => {
  const { tokenContext } = usePlusClient()
  const { getCustomerNameById, getLocationNameById } = useTPCacheContext()

  if (!order) {
    return <></>
  }

  const { date: createdAtDate } = formatCreatedDate(order.createdAt)
  const isOtcOrder = order.otcProducts?.length > 0
  const isOtcAndRxOrder = order.otcProducts?.length > 0 && order.rxFillRequests.filter(i => !i.otcProductId).length > 0
  const isMultiPetOrder = order.orderType === OrderType.MultiPet

  const patient = !isMultiPetOrder ? getPatientFromOrder(order) : undefined

  return (
    <StyledOrderViewHeading id="OrderHeading">
      <OrderViewHeading>
        {children}
        <StyledHeading data-testid="order-id" styles={headingStyle}>
          Order: <DashboardTokenLink token={order.coreOrderToken} entity="orders" />
        </StyledHeading>
        {order.isReplacement && (
          <Lozenge data-testid="replacement" backgroundColor={subduedColor}>
            RP
          </Lozenge>
        )}
        <Lozenge data-testid="location" backgroundColor={contrastColor}>
          {removeCamelCase(removeUnderscore(getLocationNameById(order.locationId)))}
        </Lozenge>
        <Lozenge data-testid="customer" backgroundColor={accentPink}>
          {getCustomerNameById(order.customerId)}
        </Lozenge>
        {isMultiPetOrder && <MultipetIcon fill={primaryBackgroundColor} />}
        <Lozenge data-testid="status" backgroundColor={getStatusColor(order.status)}>
          {order.status}
        </Lozenge>
        {order.isReservedForInvestigation && (
          <Lozenge data-testid="resrvedForInvestigation" backgroundColor={lightOrange} color={darkOrange}>
            reserved
          </Lozenge>
        )}
        {(isOtcOrder || isOtcAndRxOrder) && (
          <Lozenge data-testid={isOtcAndRxOrder ? 'rx-and-otc' : 'otc-only'} backgroundColor="#F8F1FF" color="#310063">
            {isOtcAndRxOrder ? 'RX + OTC' : 'OTC'}
          </Lozenge>
        )}
        {order.lock &&
          (order.lock.userId === tokenContext.id ? (
            <LockLozenge data-testid="lock" title="This order is locked to your account">
              <LockIcon fill={primaryBackgroundColor} />
            </LockLozenge>
          ) : (
            <StyledUserInitialsBadge user={order.lock.user} />
          ))}
      </OrderViewHeading>
      <OrderInfo>
        {isMultiPetOrder && <GuardianHeadingInfo guardian={order.rxFillRequests[0]?.patient?.guardian} />}
        {patient && <PatientHeadingInfo patientId={patient._id} />}
        {order.guestPatient && (
          <GuestPatientName>
            {order.guestPatient.firstName} {order.guestPatient.lastName},{' '}
          </GuestPatientName>
        )}
        {order.createdAt && (
          <>
            <OrderCreated> Order created:</OrderCreated>
            <DateCreated data-testid="date-created">{createdAtDate}</DateCreated>
          </>
        )}
      </OrderInfo>
    </StyledOrderViewHeading>
  )
}

const OrderViewHeading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-space-between;
  align-items: center;
`

const StyledHeading = styled.h1<{
  styles?: FlattenSimpleInterpolation
}>`
  font-size: 1.3em;
  ${({ styles }) => styles}
`

const StyledOrderViewHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

const StyledUserInitialsBadge = styled(UserInitialsBadge)`
  font-size: 0.65rem;
  margin-left: 0.5rem;
`

const LockLozenge = styled(Lozenge)`
  background-color: ${contrastColor};
  color: ${primaryBackgroundColor};
  font-weight: 700;
  padding-bottom: 0;
  height: 1.25rem;
  margin-left: 0.5rem;
`

const OrderInfo = styled.div`
  margin-top: 0.2rem;
  color: ${bodySecondaryColor};
  font-size: 1rem;
  font-weight: 500;
`

const OrderCreated = styled.span`
  margin-right: 0.2rem;
  margin-left: 0.6rem;
`

const DateCreated = styled.span`
  font-weight: 400;
`

const GuestPatientName = styled.span`
  color: ${bodyPrimaryColor};
`

export default OrderHeading
