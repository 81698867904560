import { useEffect } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_LOGS } from 'gql'
import type { Log } from 'types'
import { updateNotes } from 'utils/subscribeToolkit'

// this is only required as we get duplicate entries from the subscription
export const uniqueLogEntries = (logs: Log[]): Log[] => {
  const logsCopy = [...logs]
  return logsCopy.filter((item, pos, ary) => {
    return !pos || item._id !== ary[pos - 1]._id
  })
}

type UseLogsFilters = {
  copayRequestId?: string
  orderId?: string
  patientId?: string
  patientIds?: string[]
  prescriptionId?: string
  prescriberId?: string
  fillId?: string
  payerId?: string
  userId?: string
  escriptId?: string
  priorAuthorizationId?: string
  updatedUserId?: string
}

type Options = {
  shouldIncludePatientRelatedLogs?: boolean
}

type useLogsType = {
  logs: Log[]
  loading: boolean
  error?: ApolloError
}

export default function useLogs(
  {
    copayRequestId,
    orderId,
    patientId,
    patientIds,
    prescriptionId,
    prescriberId,
    fillId,
    payerId,
    userId,
    updatedUserId,
    escriptId,
    priorAuthorizationId,
  }: UseLogsFilters,
  options?: Options,
): useLogsType {
  const logs = useQuery(GET_LOGS, {
    skip:
      !orderId &&
      !patientId &&
      !patientIds?.length &&
      !prescriptionId &&
      !prescriberId &&
      !fillId &&
      !payerId &&
      !userId &&
      !escriptId &&
      !copayRequestId &&
      !priorAuthorizationId &&
      !updatedUserId,
    variables: {
      copayRequestId,
      orderId,
      patientId,
      patientIds,
      prescriptionId,
      prescriberId,
      fillId,
      payerId,
      userId,
      escriptId,
      priorAuthorizationId,
      updatedUserId,
      shouldIncludePatientRelatedLogs: options?.shouldIncludePatientRelatedLogs,
    },
  })

  const { data, subscribeToMore, loading, error } = logs

  useEffect(() => {
    if (orderId || patientId || patientIds?.length || prescriptionId || fillId || payerId || priorAuthorizationId)
      return updateNotes(subscribeToMore, {
        orderId,
        patientId,
        patientIds,
        prescriptionId,
        fillId,
        payerId,
      })
  }, [subscribeToMore, orderId, patientId, patientIds, prescriptionId, fillId, payerId, priorAuthorizationId])

  let value = { logs: [] as Log[], loading, error }

  if (
    orderId ||
    patientId ||
    patientIds?.length ||
    prescriptionId ||
    prescriberId ||
    fillId ||
    payerId ||
    escriptId ||
    copayRequestId ||
    priorAuthorizationId ||
    updatedUserId
  ) {
    let logs: Log[] = Object.freeze(data?.getAllLogs)

    if (logs?.length > 0) {
      logs = uniqueLogEntries(logs)
    }

    value = Object.assign({}, value, {
      logs: Object.freeze(logs),
    })
  }

  return value
}
