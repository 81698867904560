import { Select } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import type { Pod, PodDrug } from '@truepill/tpos-types'
import { GET_ALL_PODS } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'

type PodSelectProps = {
  onSelect: (podName?: string) => void
  value?: string
  disabled?: boolean
}

const PodSelect = ({ onSelect, value, disabled }: PodSelectProps): JSX.Element => {
  const { currentLocation } = usePlusClient()
  const { data, loading, error } = useQuery(GET_ALL_PODS, {
    variables: { locationId: currentLocation?.queryMap?.locationId },
  })

  if (loading || error) {
    return <></>
  }

  const pods = data?.getAllPods
  // won't show pod filter unless there are pods to filter by
  if (!pods.length) {
    return <></>
  }

  const options = pods.map((p: Pod) => ({ label: p.podName, value: p.podName }))
  const selectedValue = value ? { label: value, value } : undefined

  return (
    <Select
      label="Pod"
      value={selectedValue}
      onChange={option => {
        if (option?.value !== selectedValue?.value && option?.value) {
          currentLocation.queryMap.podName = option.value
          onSelect(option?.value as string)
        } else {
          currentLocation.queryMap.podName = undefined
          onSelect(undefined)
        }
      }}
      options={options}
      placeholder="Select pod"
      selectedKey="value"
      disabled={disabled}
    />
  )
}

export default PodSelect
