import { useMutation } from '@truepill/tpos-react-router'
import { UserRoles, CopayStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import { ActionButtonContainer } from 'components/PageStructure'
import { CREATE_LOG } from 'gql'
import { useOrderLock, useCopayLock } from 'hooks'
import type { Fill, QueueItem, Patient, Prescription, PriorAuthorization } from 'types'
import { isOrder, getItemFill, isCopayRequest } from 'utils'
import { CancelPriorAuthorizationButton } from './actionButtons/CancelPriorAuthorizationButton'
import { ChangeDispensedActionButton } from './actionButtons/changeDispensedActionButton'
import { CreatePriorAuthorizationButton } from './actionButtons/CreatePriorAuthorizationButton'
import { EditPriorAuthorizationActionButton } from './actionButtons/EditPriorAuthorizationButton'
import { EligibilityCheckActionButton } from './actionButtons/eligibilityCheckActionButton'
import { GetPriorAuthorizationInfoButton } from './actionButtons/GetPriorAuthorizationInfoButton'
import { RejectActionButton } from './actionButtons/rejectActionButton'
import { ReverseActiveCopayClaimsActionButton } from './actionButtons/reverseActiveCopayClaimsActionButton'
import { ReverseClaimsActionButton } from './actionButtons/reverseClaimsActionButton'
import { SendBackActionButton } from './actionButtons/sendBackActionButton'
import { StartPriorAuthorizationButton } from './actionButtons/StartPriorAuthorizationButton'
import { SubmitClaimActionButton } from './actionButtons/submitClaimActionButton'
import { TriageOrResolveTriageActionButton } from './actionButtons/triageOrResolveTriageActionButton'

export interface OrderInsuranceInput {
  _id: string
  orderInsuranceOptionId?: string
  bin?: string
  pcn?: string
  group?: string
  cardholderId?: string
  name?: string
  helpdeskNumber?: string
  personCode?: string
  relationship?: string
  images?: {
    front: string
    back?: string
  }
  cardType?: string
  coreInsuranceToken?: string
}

export interface PatientInsuranceInput {
  cardholderId?: string
  bin: string
  name?: string
  designation?: string
  pcn?: string
  group?: string
  images?: {
    front: string
    back?: string
  }
}

type ActionButtonsProps = {
  fill: Fill
  item: QueueItem
  patient: Patient
  prescription: Prescription
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  isManualPriorAuth?: boolean
  hasRunClaim?: boolean
  setRunningClaim?: (val: boolean) => void
  disabled?: boolean
  isCopayInTriage?: boolean
  onlyAdminCanExecuteAction?: boolean
}

// Action buttons need a Prescription object to function correctly
const WrappedActionButtons = (props: ActionButtonsProps): JSX.Element => {
  return (
    <AuthLimited
      roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.CustomerSupport, UserRoles.LeadCustomerSupport]}
    >
      <ActionButtons {...props} />
    </AuthLimited>
  )
}

const ActionButtons = ({
  fill,
  item,
  patient,
  prescription,
  priorAuthorization,
  isPriorAuthorization = false,
  isManualPriorAuth = false,
  setRunningClaim,
  disabled = false,
  isCopayInTriage = false,
  onlyAdminCanExecuteAction = true,
}: ActionButtonsProps) => {
  const [createLog] = useMutation(CREATE_LOG, {
    refetchQueries: ['getAllLogs'],
  })
  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)
  const { copayEditable } = useCopayLock(item._id, isOrderItem)

  if (!item) {
    return null
  }

  disabled = disabled || (isOrderItem && !orderEditable) || (!isOrderItem && !copayEditable)
  const isRejectedCopay = isCopayRequest(item) && getItemFill(item, fill._id)?.status === CopayStatus.Rejected
  const isRejectedOrder = isOrder(item) && getItemFill(item, fill._id)?.status === RxFillRequestStatus.Cancelled

  if (isRejectedCopay) {
    return <ReverseActiveCopayClaimsActionButton item={item} fill={fill} />
  }

  return (
    <ActionButtonContainer>
      {/** Automated Prior Athorization Buttons Below This Line */}
      {/** Todo: Move Prior Authorization Buttons to its own component */}
      <CreatePriorAuthorizationButton
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        disabled={disabled}
        item={item}
        fill={fill}
        prescription={prescription}
      />
      <StartPriorAuthorizationButton
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        disabled={disabled}
        clickCallback={priorAuthorizationId => {
          createLog({
            variables: {
              priorAuthorizationId,
              event: 'priorAuth',
              message: 'Started Prior Authorization',
            },
          })
        }}
      />

      <GetPriorAuthorizationInfoButton
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        clickCallback={priorAuthorizationId => {
          createLog({
            variables: {
              priorAuthorizationId,
              event: 'priorAuth',
              message: 'Viewed Prior Authorization Info',
            },
          })
        }}
      />

      <CancelPriorAuthorizationButton
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        clickCallback={priorAuthorizationId => {
          createLog({
            variables: {
              priorAuthorizationId,
              event: 'priorAuth',
              message: 'Cancelled Prior Authorization',
            },
          })
        }}
      />

      <EditPriorAuthorizationActionButton
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        disabled={disabled}
        clickCallback={priorAuthorizationId => {
          createLog({
            variables: {
              priorAuthorizationId,
              event: 'priorAuth',
              message: 'Edited Prior Authorization',
            },
          })
        }}
      />

      <RejectActionButton
        item={item}
        fill={fill}
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        disabled={disabled}
        isRejectedOrder={isRejectedOrder}
      />

      <TriageOrResolveTriageActionButton
        item={item}
        fill={fill}
        isCopayInTriage={isCopayInTriage}
        isPriorAuthorization={isPriorAuthorization}
        isRejectedOrder={isRejectedOrder}
      />

      {/* // TODO -Send back to PENDING for copays has been created in sendBackToPending mutation*/}
      <SendBackActionButton
        item={item}
        fill={fill}
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        isManualPriorAuth={isManualPriorAuth}
        isRejectedOrder={isRejectedOrder}
      />

      <EligibilityCheckActionButton
        item={item}
        fill={fill}
        patient={patient}
        isPriorAuthorization={isPriorAuthorization}
        isManualPriorAuth={isManualPriorAuth}
        disabled={disabled}
      />

      <ChangeDispensedActionButton
        item={item}
        fill={fill}
        prescription={prescription}
        isPriorAuthorization={isPriorAuthorization}
      />

      <ReverseClaimsActionButton
        item={item}
        fill={fill}
        isPriorAuthorization={isPriorAuthorization}
        isManualPriorAuth={isManualPriorAuth}
        disabled={disabled}
      />

      <SubmitClaimActionButton
        item={item}
        fill={fill}
        priorAuthorization={priorAuthorization}
        isPriorAuthorization={isPriorAuthorization}
        isRejectedOrder={isRejectedOrder}
        setRunningClaim={setRunningClaim}
        disabled={disabled}
      />
    </ActionButtonContainer>
  )
}

export default WrappedActionButtons
