import type { QueryResult, WatchQueryFetchPolicy } from '@truepill/tpos-react-router'
import { useLazyQuery, useQuery } from '@truepill/tpos-react-router'
import { LIST_FULFILLMENT_COPAYS } from 'gql'
import type { CopayRequest } from 'types'

export interface GetFulfillmentCopayParams {
  customerIds?: string[]
  locationId?: string
  selectedLocationIds?: string[]
  status?: string
  pageNumber?: number
  pageSize?: number
  patientIds?: string[]
  drugName?: string
  medicationNames?: string[]
  isBatch?: boolean
  customerRef?: string
  sort?: number
  coreCopayRequestToken?: string
  coreCopayRequestFillToken?: string
  states?: string[]
  triageReasons?: string[]
}

export interface GetFulfillmentCopaysResult {
  getFulfillmentCopays: {
    currentPage: number
    totalRecords: number
    copayRequests: CopayRequest[]
  }
}

export const useLazyFulfillmentCopays = (fetchPolicy?: WatchQueryFetchPolicy) => {
  return useLazyQuery<GetFulfillmentCopaysResult, GetFulfillmentCopayParams>(LIST_FULFILLMENT_COPAYS, {
    fetchPolicy: fetchPolicy ?? 'network-only',
  })
}

const useFulfillmentCopays = (
  copayQueryVariables: GetFulfillmentCopayParams,
): QueryResult<GetFulfillmentCopaysResult, GetFulfillmentCopayParams> => {
  return useQuery<GetFulfillmentCopaysResult, GetFulfillmentCopayParams>(LIST_FULFILLMENT_COPAYS, {
    variables: copayQueryVariables,
    fetchPolicy: 'network-only',
  })
}

export default useFulfillmentCopays
