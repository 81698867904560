import { useQuery } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { ActionBar, PageContainer } from 'components/PageStructure'
import PrescribersTable from 'components/PrescribersTable'
import { UNIFIED_SEARCH_PRESCRIBERS } from 'gql'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { useHistory, useLocation } from 'react-router'
import { goToAddPrescriber } from 'routes'
import { borderColor } from 'styles/styleVariables'
import {
  LoadingContainer,
  Title,
  SearchControls,
  StyledSearchInput,
  NewPrescriberLocationControls,
  StyledActionButton,
  Content,
} from './StyledComponents'

const PrescribersPage = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()

  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, loading, error } = useQuery(UNIFIED_SEARCH_PRESCRIBERS, { variables: { search: debouncedSearchTerm } })
  const searchTermGiven = searchTerm.length > 0

  const prescribers = data?.unifiedSearchPrescribers || []

  useSetPageTitle('Prescribers')

  const ContentPayload = (): JSX.Element => {
    if (loading) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )
    } else if (error) return <p>Failed to load prescribers list {JSON.stringify(error, null, 2)}</p>
    return <PrescribersTable loading={loading} prescribers={prescribers} searchTermGiven={searchTermGiven} />
  }

  return (
    <PageContainer>
      <BreadCrumb />
      <Title>Prescribers</Title>
      <ActionBar>
        <SearchControls>
          <StyledSearchInput
            iconColor={borderColor}
            placeholder="Search prescriber by name, NPI, or License #"
            onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
          />
        </SearchControls>
        <NewPrescriberLocationControls>
          <AuthLimited roles={[UserRoles.Admin]}>
            <StyledActionButton
              icon={
                <IconWrapper>
                  <PlusIcon fill="black" />
                </IconWrapper>
              }
              hotKey="n"
              label="New prescriber"
              onClick={() => history.push(goToAddPrescriber({ search }))}
            />
          </AuthLimited>
        </NewPrescriberLocationControls>
      </ActionBar>
      <Content>
        <ContentPayload />
      </Content>
    </PageContainer>
  )
}

export default PrescribersPage
