import { useCallback } from 'react'
import { Link, useLocation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import Lozenge, { getCopayStatusColor } from 'components/Tiles/Lozenge'
import { useLazyFulfillmentCopays } from 'hooks/navigation/useFulfillmentCopays'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import usePaginationManager from 'hooks/usePaginationManager'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { goToFulfillmentCopay } from 'routes'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { contrastColor, contrastBackgroundColor, primaryColorLight } from 'styles/styleVariables'
import type { Patient, CopayRequest } from 'types'
import { checkIfProvided, formatCreatedDate } from 'utils'
import Pagination from './Pagination'

type CopayHistoryProps = {
  patientIds: Patient['_id'][]
  searchTerm?: string
  showPagination?: boolean
}

const CopayHistory = (props: CopayHistoryProps): JSX.Element => {
  const { patientIds, searchTerm = '', showPagination = false } = props

  const [getCopays, { data, loading, error }] = useLazyFulfillmentCopays('cache-first')

  const fetchMore = useCallback(
    (page: number, limit: number) => {
      getCopays({
        variables: !showPagination || !!searchTerm ? { patientIds } : { patientIds, pageSize: limit, pageNumber: page },
      })
    },
    [patientIds.toString(), getCopays, searchTerm, showPagination],
  )

  const {
    items: copays,
    currentPage,
    setPage,
    totalRecords,
    totalPages,
    pageSize,
    setLimit,
  } = usePaginationManager(fetchMore, {
    totalRecords: data?.getFulfillmentCopays.totalRecords ?? 0,
    items: data?.getFulfillmentCopays.copayRequests ?? [],
  })

  if (loading) {
    return (
      <MainLoadingContainer>
        <TitleRow />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </MainLoadingContainer>
    )
  }

  if (error) {
    return <p>Failed to load Copay History {JSON.stringify(error)} </p>
  }

  const filteredCopays = (copays ?? []).filter(copay => {
    const coreCopayTokenMatch = copay.coreCopayToken?.includes(searchTerm.toLowerCase())
    const rxNumbers: string[] = copay.copayRequestFills.map(request => request.prescription.rxNumber.toString())
    const rxNumberMatch = rxNumbers.toString().includes(searchTerm)
    return rxNumberMatch || coreCopayTokenMatch
  })

  return (
    <CopayHistoryContainer>
      <TitleRow />
      {filteredCopays.map(copay => (
        <CopayHistoryEntry key={copay._id} copay={copay} />
      ))}
      {copays.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      {showPagination && !searchTerm && (
        <Pagination
          onChangePage={setPage}
          totalRecords={totalRecords}
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={setLimit}
        />
      )}
    </CopayHistoryContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitleCopayHistoryRow>
      <CopayToken>Copay token</CopayToken>
      <Customer>Customer</Customer>
      <CreatedAt>Created At</CreatedAt>
      <RXNumbers>Rx numbers</RXNumbers>
      <Medication>Medication</Medication>
      <Status>Status</Status>
    </TitleCopayHistoryRow>
  )
}

type CopayHistoryEntryProps = { copay: CopayRequest }

const CopayHistoryEntry = ({ copay }: CopayHistoryEntryProps): JSX.Element => {
  const { search } = useLocation()
  const { getCustomerNameById } = useTPCacheContext()
  const fulfillmentQueue = useFulfillmentQueue()
  const { date: createdAtDate } = formatCreatedDate(copay.createdAt)
  const rxNumbers: string[] = copay.copayRequestFills.map(request => request.prescription.rxNumber.toString())
  const medications: string[] = copay.copayRequestFills.map(request => request.dispensed?.name)

  return (
    <StyledLink
      to={goToFulfillmentCopay({
        fulfillmentQueueName: fulfillmentQueue?.name,
        copayRequestId: copay._id,
        search,
      })}
    >
      <CopayHistoryRow>
        <CopayToken>{copay.coreCopayToken}</CopayToken>
        <Customer>{checkIfProvided(getCustomerNameById(copay.customerId))}</Customer>
        <CreatedAt>{createdAtDate}</CreatedAt>
        <RXNumbers>{rxNumbers.length ? rxNumbers.join(', ') : 'N/A'}</RXNumbers>
        <Medication>{medications.length ? medications.join(', ') : 'N/A'}</Medication>
        <Status>
          <Lozenge backgroundColor={getCopayStatusColor(copay.status)}>{copay.status}</Lozenge>
        </Status>
      </CopayHistoryRow>
    </StyledLink>
  )
}

const CopayHistoryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  padding: 0.1rem 0.625rem;
  :nth-of-type(1n) {
    padding-bottom: 0.625rem;
  }
  :nth-of-type(1n - 1) {
    padding-top: 0.625rem;
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const CopayHistoryRow = styled.ul`
  display: grid;
  grid-template-rows: [content] auto [fillHistory] auto;
  grid-template-columns:
    [copayToken] minmax(6rem, 9rem)
    [customer] minmax(6rem, 9rem)
    [createdAt] minmax(8rem, 12em)
    [rxNumbers] minmax(14rem, 20rem)
    [medication] minmax(12rem, 1fr);
`

const TitleCopayHistoryRow = styled(CopayHistoryRow)`
  ${FilledHeadingStyle}
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const HistoryCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const CopayToken = styled(HistoryCell)`
  grid-column: copayToken;
`

const Customer = styled(HistoryCell)`
  grid-column: customer;
`
const CreatedAt = styled(HistoryCell)`
  grid-column: createdAt;
`

const RXNumbers = styled(HistoryCell)`
  grid-column: rxNumbers;
`

const Medication = styled(HistoryCell)`
  grid-column: medication;
`

const Status = styled(HistoryCell)`
  grid-column: status;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Lozenge}:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  ${Lozenge}:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1640px) {
    flex-wrap: nowrap;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

const MainLoadingContainer = styled.div`
  width: 100%;
`

export default CopayHistory
