export enum FeatureFlags {
  ADJUDICATE_CASH_ORDERS = 'adjudicate-cash-orders',
  ALLOW_ERX_REJECTION_FROM_CUSTOMER_UNASSIGNED = 'Allow-Erx-Rejection-From-Customer-Unassigned',
  ALLOW_ERX_REJECTION_FROM_PATIENT_MATCH = 'Allow-Erx-Rejection-From-Patient-Match',
  ALLOW_LINEAR_BARCODE_SCANNING = 'allow-linear-barcode-scanning',
  AUTO_E1_CHECK = 'auto-E1-check',
  AUTO_FIX_QUANTITY = 'auto-fix-quantity',
  AUTO_GENERATE_PRESCRIPTION = 'auto-generate-prescription',
  AUTO_INITIATE_PRIOR_AUTH = 'auto-initiate-prior-auth',
  AUTO_REJECT_BAD_ADDRESS = 'Auto-Reject-Bad-Address',
  AUTO_REJECT_CONTROLLED_SUBSTANCES_LARGE_DAYS_SUPPLY = 'Auto-Reject-Controlled-Substances-Large-Days-Supply',
  AUTO_REJECT_INSUFFICIENT_QUANTITY_WHEN_NO_SWAPPABLE_RX = 'auto-reject-insufficient-quantity-when-no-swappable-rx',
  AUTO_REJECT_INSURANCE_ORDERS_BASED_ON_CLAIM_REJECT = 'auto-reject-insurance-orders-based-on-claim-reject',
  AUTO_REJECT_ORDER_CONTROLLED_SUBSTANCE_BY_STATE = 'auto-reject-order-controlled-substance-by-state',
  AUTO_REVERSE_REBILL_EVOUCHER_CLAIMS = 'auto-reverse-rebill-evoucher-claims',
  AUTO_REVERSE_REBILL_EVOUCHER_CLAIMS_ON_SECONDARY_REJECT = 'auto-reverse-rebill-evoucher-claims-on-secondary-reject',
  AUTO_TRIAGE_COPAY_CHANGE_FOR_UNLINKED_ORDERS = 'auto-triage-copay-change-for-unlinked-orders',
  AUTO_TRIAGE_FOR_OUT_OF_STOCK = 'auto-triage-for-out-of-stock',
  AUTO_TRIAGE_REFILL_PERCENTAGE = 'Auto-Triage-Refill-Percentage',
  AUTO_TRIAGE_REPLACEMENT_ORDERS = 'auto-triage-replacement-orders',
  AUTO_TRIAGE_REQUIRE_SUPERVISING_MD = 'auto-triage-require-supervising-md',
  AUTO_TRIAGE_UNABLE_CONFIRM_COPAY = 'auto-triage-unable-confirm-copay',
  AUTO_UPDATE_DUR_INPUT_MAPPINGS = 'auto-update-durinputmappings',
  BLOCK_SECONDARY_CLAIM_BASED_ON_PRIMARY = 'block-secondary-claim-based-on-primary',
  CHECK_GOODRX_GOLD_TRANSFERS = 'Check-GoodRx-Gold-Transfers',
  CLAIM_JUMPER_ERRORS_FOR_COPAY_AUTO_TRIAGE = 'claim-jumper-errors-for-copay-auto-triage',
  CLAIM_REJECT_CODES_FOR_COPAY_AUTO_TRIAGE = 'claim-reject-codes-for-copay-auto-triage',
  COMPARE_ORDER_VS_CLAIM_COPAY = 'compare-order-vs-claim-copay',
  COMPARE_STRIPE_VS_CLAIM_COPAY = 'compare-stripe-vs-claim-copay',
  COVER_MY_MEDS_PRIOR_AUTH = 'cover-my-meds-prior-auth',
  CUSTOMERS_NOT_REQUIRING_PRECHECK = 'Customers-Not-Requiring-Precheck',
  CUSTOMER_EDI_PRICING = 'customer-edi-pricing',
  DECOUPLE_CRITICAL_NOTES = 'decouple-critical-notes-popup',
  DEFER_BATCH_AUTOMATION = 'defer-batch-automation',
  DISCONTINUE_DUPLICATE_PRESCRIPTIONS = 'discontinue-duplicate-prescriptions',
  DISPLAY_COLLECTED_COPAY_IN_FRONTEND = 'display-collected-copay-in-frontend',
  DISPLAY_NEW_COPAY_CHECK_BUTTON = 'display-new-copay-check-button',
  DUPLICATE_RX_BANNER = 'duplicate-rx-banner-pv1',
  ENABLE_BALANCE_IN_FLIGHT = 'Enable-Balance-In-Flight',
  ENABLE_LOT_ACCURACY_MODAL_GTIN_SCANNING = 'enable-lot-accuracy-modal-gtin-scanning',
  ENABLE_MUTATIONS_BY_ROLE = 'enable-mutations-by-role',
  ENABLE_NEW_PRESCRIPTION_CREATE_V2 = 'Enable-New-Prescription-V2',
  ENABLE_PACKING_ACCURACY_SCANNING = 'Enable-Packing-Accuracy-Scanning',
  ENABLE_PACKING_MATERIALS_ACCURACY_SCANNING = 'Enable-Packing-Materials-Accuracy-Scanning',
  ENABLE_PET_PATIENT_INFO_IN_PARATA_XML = 'enable-pet-patient-info-in-parata-xml',
  ENABLE_REQUEST_CLARIFICATION_BUTTON = 'Enable-Request-Clarification-Button',
  ENABLE_SYNCING_NON_DEFAULT_INVENTORY_GROUPS = 'Enable-Syncing-Non-Default-Inventory-Groups',
  ERX_INTAKE_VALIDATION_DAYS_SUPPLY = 'e-rx-intake-validation-days-supply',
  ERX_INTAKE_VALIDATION_DISCONTINUED_NDC = 'e-rx-intake-validation-discontinued-ndc',
  ERX_INTAKE_VALIDATION_NON_NDC_FORMAT = 'e-rx-intake-validation-non-ndc-format',
  ERX_INTAKE_VALIDATION_UNBREAKABLE_QUANTITY = 'e-rx-intake-validation-unbreakable-quantity',
  ES_INDEX_ALLERGIES = 'Es-Index-Allergies',
  HANDLE_DUPLICATE_COPAY_REQUESTS = 'handle-duplicate-copay-requests',
  IGNORE_DUR_RESULTS = 'ignore-dur-results',
  KEEP_ESCRIPTS_IN_TPOS = 'Keep-Escripts-In-Tpos',
  LOG_GET_SUBS_BY_NDC = 'Log-Get-Substitutions-By-Ndc',
  OTHER_LOCATIONS_CAN_PV1_BROOKLYN = 'Other-Locations-Can-PV1-Brooklyn',
  OVERWRITE_SUBSTITUTION_ON_ESCRIPT = 'Overwrite-Substitution-On-Escript',
  PATIENT_RESPONSIBILITY_AMOUNT_TYPE = 'patient-responsibility-amount-type-on-occ-8',
  PAYMENT_INTENT_EXPIRATION_CHECK = 'payment-intent-expiration-check',
  PAYMENT_INTENT_CAPTURE = 'payment-intent-capture',
  PA_CODE_OVERRIDE = 'pa-code-override',
  PBM_VALIDATION = 'pbm-validation',
  PUBLISH_TO_HASH_EXCHANGE = 'publish-to-hash-exchange',
  PUBLISH_TO_TOPIC_EXCHANGE = 'publish-to-topic-exchange',
  PV1_INTERFACE_CONSISTENCY = 'pv-1-interface-consistency',
  PV2_CLAIM_AGE_THRESHOLD = 'pv-2-claim-age-threshold',
  REMOVE_PRESCRIPTION_AND_HARDCOPY_FROM_FILL_PAGE = 'Remove-Prescription-and-Hardcopy-from-Fill-Page',
  REMOVE_REJECTED_SECONDARY_CLAIM = 'remove-rejected-secondary-claim',
  REQUIRE_USER_TO_OUTBOUND_SCAN_DURING_PACKING = 'require-user-to-outbound-scan-during-packing',
  REQUIRE_USER_TO_SCAN_DATAMATRIX_DURING_FILL = 'require-user-to-scan-datamatrix-during-fill',
  RX_LABEL_CUSTOMER_CONFIGURATION = 'rx-label-customer-configuration',
  RX_LABEL_NEW_VERSION = 'rx-label-new-version',
  RX_RENEWAL_REQUEST_LABEL_CUSTOMER_CONFIGURATION = 'rx-renewal-request-label-customer-configuration',
  SEND_FILLS_TO_PRESCRIPTION_SERVICE = 'send-fills-to-prescription-service',
  SEND_RENEWALS_TO_INTAKE_PHARMACY = 'send-renewals-to-intake-pharmacy',
  SHOULD_ADD_KANGAROO_MAILER_FOR_TEMPERATURE_SENSITIVE_MEDS = 'should-add-kangaroo-mailer-for-temperature-sensitive-meds',
  SHOULD_AUTO_FIX_DAYS_SUPPLY_PRECHECK = 'should-auto-fix-days-supply-precheck',
  SHOULD_AUTO_FIX_QUANTITY_PRECHECK = 'should-auto-fix-quantity-precheck',
  SHOULD_VALIDATE_PRESCRIPTION_ON_ORDER = 'should-validate-prescription-on-order',
  SKIP_CLAIM_JUMPER_COPAYS = 'skip-claim-jumper-copays',
  SKIP_PV1_TEMP_DISREGARD_SCREENING_NDCS = 'skip-pv1-temp-disregard-screening-ndcs',
  SKIP_PV_1 = 'Skip-Pv-1',
  SKIP_PV_1_DISREGARD_WEIGHT_FROM_SURVEY = 'skip-pv1-disregard-weight-from-survey',
  SKIP_PV_1_EVALUATE_THERAP_EQUIVALENT_MEDICATIONS = 'skip-pv1-evaluate-therap-equivalent-medications',
  STREAMLINE_TRIAGE_AND_REJECTION_REASONS = 'streamline-triage-and-rejection-reasons',
  SUBMIT_SECONDARY_OCC_3_IF_PRIMARY_REJECTS = 'submit-secondary-occ-3-if-primary-rejects',
  TEMP_ADD_OTC_RX_LABEL_TO_QUEUES = 'temp-add-otc-rx-label-to-queues',
  TEMP_ALLOW_FAILED_REVERSALS_SEND_BACK_TO_ADJUDICATION = 'temp-allow-failed-reversals-send-back-to-adjudication',
  TEMP_ALLOW_FDB_SUBS = 'Temp-Allow-FDB-Subs',
  TEMP_BLOCK_SECONDARY_CLAIM_USE_RESPONSE_FLOW = 'temp-block-secondary-claim-use-response-flow',
  TEMP_CREATE_FILL_USING_UPDATE_INSTEAD_OF_SAVE = 'temp-create-fill-using-update-instead-of-save',
  TEMP_CREATE_FILL_WITH_NDC_DATA_FROM_PRESCRIBED = 'temp-create-fill-with-ndc-data-from-prescriber',
  TEMP_CREATE_OTC_ADJUDICATION_FILLS = 'temp-create-otc-adjudication-fills',
  TEMP_DISPLAY_PACK_SIZE_WITH_NDC = 'temp-display-pack-size-with-ndc',
  TEMP_DISPLAY_PATIENT_CONSENT_ON_TRIAGE_PAGES = 'temp-display-patient-consent-on-triage-pages',
  TEMP_DUPLICATE_RX_MODAL = 'temp-duplicate-rx-modal',
  TEMP_DUR_SCREENING_FILTER_DUP_NDC_FROM_RXS = 'temp-dur-screening-filter-dup-ndc-from-rxs',
  TEMP_DUR_SCREENING_UPDATE_INSTEAD_OF_SAVE = 'temp-dur-screening-update-instead-of-save',
  TEMP_DUR_TOKEN_MATCHING = 'temp-dur-token-matching',
  TEMP_ENABLE_ADDING_EXTRA_PACKING_MATERIALS = 'temp-enable-adding-extra-packing-materials',
  TEMP_ENABLE_CASH_ORDER_ROUTING = 'temp-enable-cash-order-routing',
  TEMP_ENABLE_COPAY_REJECT_LIMIT = 'temp-enable-copay-reject-limit',
  TEMP_ENHANCE_PATIENT_SEARCH = 'temp-enhance-patient-search',
  TEMP_FILTERS_RESET_PAGINATION = 'temp-filters-reset-pagination',
  TEMP_FILTER_INACTIVE_NDCS_FOR_NDC_SUBSTITUTIONS = 'temp-filter-inactive-ndcs-for-ndc-substitutions',
  TEMP_GET_FULFILLMENT_ORDER_HINT = 'temp-get-fulfillment-order-hint',
  TEMP_GET_FULFILMENT_ORDERS_2 = 'temp-get-fulfilment-orders-2',
  TEMP_HANDLE_DUPLICATE_PA_RESPONSES = 'temp-handle-duplicate-pa-responses',
  TEMP_HIDE_CONSENT_ON_PV1 = 'temp-hide-consent-on-pv1',
  TEMP_HIDE_REFILLS_WRITEEN_FIELD_RX_REVIEW = 'temp-hide-refills-written-field-rx-review',
  TEMP_HIGHLIGHT_PV1_DIFFERENCES = 'temp-highlight-pv1-differences',
  TEMP_HYPHENATE_NDCS = 'temp-hyphenate-ndcs',
  TEMP_LINK_ORDERS_AND_COPAYS_WITH_SAME_RX_TOKEN = 'Temp-link-orders-and-copays-with-same-rx-token',
  TEMP_LOG_FFS_INVENTORY = 'Temp-Log-FFS-Inventory',
  TEMP_MIGRATE_TO_RX_RENEWALS_COLLECTION = 'temp-migrate-to-rx-renewals-collection',
  TEMP_NEW_FIELDS_ON_SENT_REVERSALS_AND_CLAIMS = 'temp-new-fields-on-sent-reversals-and-claims',
  TEMP_OTC_ADJUDICATION_HIDE_RX_COMPONENT = 'temp-otc-adjudication-hide-rx-component',
  TEMP_REJECT_CONFLICTING_DAW_NDC = 'temp-reject-conflicting-daw-ndc',
  TEMP_REJECT_MEDICARE_PAYMENT_PLAN = 'temp-reject-medicare-payment-plan',
  TEMP_REMOVE_MACHINE_AFTER_MOVING_TO_MANUAL_AUTO = 'temp-remove-machine-after-moving-to-manual-auto',
  TEMP_SUPPORT_CSA_NOTES = 'temp-support-csa-notes',
  TEMP_SUPPRESS_OTHER_PAYER_ID = 'temp-suppress-otherPayerId',
  TEMP_TRIAGE_MEDICARE_PAYMENT_PLAN = 'temp-triage-medicare-payment-plan',
  TEMP_UNBREAKABLE_PACK_DEFINITION = 'temp-unbreakable-pack-definition',
  TEMP_UPDATE_OTC_FILTER_BUTTON_LOGIC = 'temp-update-OTC-filter-button-logic',
  TEMP_UPDATE_UNAVAILABLE_TO_ORDER_REJECTION_CODE = 'temp-update-unavailable-to-order-rejection-code',
  TEMP_USE_ANY_AB_SUBS_WHEN_NO_GENERICS_AVAILABLE = 'temp-use-any-AB-subs-when-no-generics-available',
  TEMP_USE_DUR_TOKEN_MAPPINGS = 'temp-use-dur-token-mappings',
  TEMP_USE_GRAPHQL_QUERY_IN_COMPONENT_FILE = 'temp-use-graphql-query-in-component-file',
  TEMP_USE_NEW_SAVE_CLAIM_QUERY = 'temp-use-new-save-claim-query',
  TEMP_USE_REUSABLE_CHECK_COPAY_AMOUNT_FUNCTION = 'temp-use-reusable-check-copay-amount-function',
  TEMP_USE_SESSION_IN_CLAIM_JUMPER = 'temp-use-session-in-claim-jumper',
  TEMP_USE_SUPERVISOR_TEMPLATE = 'temp-use-supervisor-template',
  TEMP_VALIDATE_CLAIM_RESPONSE_EDI = 'temp-validate-claim-response-edi',
  TPOS_DASHBOARD_DESIGN_V2 = 'tpos-dashboard-design-v2',
  TRANSFER_HISTORY_IMAGE_NEW_VERSION = 'transfer-history-image-new-version',
  TRIAGE_COPAY_CHANGE_LINKED_ORDERS = 'triage-copay-change-linked-orders',
  TRIAGE_ORDERS_FOR_DUPLICATE_PRESCRIPTIONS = 'triage-orders-for-duplicate-prescriptions',
  USE_COPAY_PAYMENT_VALIDATION = 'use-copay-payment-validation',
  USE_DIRECT_TRANSFER_PRESCRIBER_DURING_TAKEOVER = 'use-direct-transfer-prescriber-during-takeover',
  USE_EXACT_PATIENT_SEARCH_OR_CREATE = 'Use-Exact-Patient-Search-Create-Core-To-Tpos',
  USE_FFS = 'Use-FFS',
  USE_JANUS_FORMULARY_STATUS = 'Use-Janus-Formulary-Status',
  USE_MONGO_ATLAS_AS_DUR_SEARCH_SOURCE_AUTOMATIC = 'use-mongo-atlas-as-dur-search-source-automatic',
  USE_MONGO_ATLAS_AS_DUR_SEARCH_SOURCE_MANUAL = 'use-mongo-atlas-as-dur-search-source-manual',
  USE_NDCDISPENSER_CLASS = 'Use-NdcDispenser-Class',
  USE_SKU_WAREHOUSE_LOCATION = 'use-sku-warehouse-location',
  USE_TECSYS_INVENTORY = 'Use-Tecsys-Inventory',
  USE_TECSYS_INVENTORY_OTC = 'use-tecsys-inventory-otc',
}

export interface FeatureFlag<T> {
  key: FeatureFlags
  value: T
}
